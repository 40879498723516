import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../store/store';
import { getNewsSources } from './store/NewsSources.actions';
import NewsSources from './NewsSources.view';
import { RouteComponentProps } from 'react-router-dom';
import { NewsSourcesActionTypes } from './store/NewsSourcesActionTypes';
import { ClientNewsSource } from '@DigitaleDoerfer/digitale-doerfer-api';
import { NEWS_SOURCES_CREATE_URL } from './NewsSourcesRouting.container';

interface Props extends RouteComponentProps {
	clientNewsSource: ClientNewsSource[];
	loading: boolean;
	getNewsSources: () => void;
}

class NewsSourcesContainer extends Component<Props> {
	componentDidMount(): void {
		this.props.getNewsSources();
	}

	createNewsSourcesHandler = (): void => {
		this.props.history.push(NEWS_SOURCES_CREATE_URL);
	};

	render(): JSX.Element {
		const { clientNewsSource, loading } = this.props;
		return (
			<NewsSources
				createNewsSourcesHandler={this.createNewsSourcesHandler}
				clientNewsSource={clientNewsSource}
				loading={loading}
			/>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		clientNewsSource: state.newsSource.list.clientNewsSource,
		loading: state.newsSource.list.loading
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<NewsSourcesActionTypes>) => {
	return {
		getNewsSources: (): Promise<void> => dispatch(getNewsSources())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsSourcesContainer);
