import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../store/store';
import { getFlaggedContents } from './store/FlaggedContent.actions';
import FlaggedContentListView from './FlaggedContentList.view';
import FlaggedContentListMobileView from './FlaggedContentListMobile.view';
import { RouteComponentProps } from 'react-router-dom';
import { BaseError } from '../../shared/errors/Errors';
import { FlaggedContentActionTypes } from './store/FlaggedContentActionTypes';
import { flaggedContentService } from '../../ServiceFactory';
import { ListSearchParams } from './store/FlaggedContent.state';
import { ClientUserGeneratedContentFlag } from '@DigitaleDoerfer/digitale-doerfer-api';
import { getFlaggedContentsDetailsUrlWithEntityTypeAndId } from './FlaggedContentRouting.container';

interface Props extends RouteComponentProps {
	flaggedContents: ClientUserGeneratedContentFlag[];
	loading: boolean;
	error: BaseError | null;
	searchParams: ListSearchParams;
	getFlaggedContents: (params: ListSearchParams) => void;
}

interface State {
	isMobile: boolean;
}

class FlaggedContentListContainer extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			isMobile: window.matchMedia('(max-width: 900px)').matches
		};
	}

	componentDidMount() {
		window.addEventListener('resize', this.updateIsMobile);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateIsMobile);
	}

	updateIsMobile = () => {
		this.setState({
			isMobile: window.matchMedia('(max-width: 900px)').matches
		});
	};

	viewFlaggedContentDetailsHandler = (flaggedContentId: string, flaggedContentEntityType: string): void => {
		this.props.history.push(
			getFlaggedContentsDetailsUrlWithEntityTypeAndId(
				flaggedContentService().getShortEntityTypeName(flaggedContentEntityType),
				flaggedContentId
			)
		);
	};

	openNewFlaggedContentDetailsTab = (flaggedContentId: string, flaggedContentEntityType: string): void => {
		window.open(
			getFlaggedContentsDetailsUrlWithEntityTypeAndId(
				flaggedContentService().getShortEntityTypeName(flaggedContentEntityType),
				flaggedContentId
			)
		);
	};

	render(): JSX.Element {
		const { flaggedContents, loading, error, searchParams, getFlaggedContents } = this.props;
		const { isMobile } = this.state;
		return isMobile ? (
			<FlaggedContentListMobileView // Render mobile view if isMobile is true
				flaggedContents={flaggedContents}
				loading={loading}
				error={error}
				searchParams={searchParams}
				getFlaggedContents={getFlaggedContents}
				viewFlaggedContentDetailsHandler={this.viewFlaggedContentDetailsHandler}
				openNewFlaggedContentDetailsTab={this.openNewFlaggedContentDetailsTab}
			/>
		) : (
			<FlaggedContentListView // Render desktop view if isMobile is false
				flaggedContents={flaggedContents}
				loading={loading}
				error={error}
				searchParams={searchParams}
				getFlaggedContents={getFlaggedContents}
				viewFlaggedContentDetailsHandler={this.viewFlaggedContentDetailsHandler}
				openNewFlaggedContentDetailsTab={this.openNewFlaggedContentDetailsTab}
			/>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		flaggedContents: state.flaggedContent.list.flaggedContents,
		loading: state.flaggedContent.list.loading,
		error: state.flaggedContent.list.error,
		searchParams: state.flaggedContent.list.searchParams
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<FlaggedContentActionTypes>) => {
	return {
		getFlaggedContents: (params: ListSearchParams): Promise<void> => dispatch(getFlaggedContents(params))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FlaggedContentListContainer);
