import React from 'react';
import { ImageSizes } from '../../services/Image.service';
import { Grid } from '@mui/material';
import { ClientMediaItem } from '@DigitaleDoerfer/digitale-doerfer-api';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import AvatarView from '../Avatar.view';
import PersonNameView from './PersonName.view';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => {
	return createStyles({
		container: {
			alignSelf: 'center'
		},
		icon: {
			alignSelf: 'center'
		},
		text: {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			paddingLeft: 4
		}
	});
};

interface Props extends WithStyles<typeof styles> {
	person?: { firstName?: string; lastName?: string; deleted?: boolean; profilePicture?: ClientMediaItem };
	width?: number;
	spaceBetweenIconAndText?: number;
	textVariant: 'body1' | 'body2' | 'h6';
	className?: string;
}

class PersonNameWithAvatarPicture extends React.Component<Props> {
	render(): JSX.Element {
		const { person, width, spaceBetweenIconAndText, textVariant, className, classes } = this.props;
		return (
			<Grid
				item
				container
				direction="row"
				wrap="nowrap"
				className={clsx(className, classes.container)}
				{...(!spaceBetweenIconAndText ? { spacing: 1 } : {})}
				style={width !== undefined ? { width } : {}}
			>
				<Grid item className={classes.icon}>
					<AvatarView avatarPicture={person?.profilePicture} deleted={person?.deleted} size={ImageSizes.THUMBNAIL} />
				</Grid>
				<Grid
					item
					className={classes.text}
					style={spaceBetweenIconAndText !== undefined ? { paddingLeft: spaceBetweenIconAndText } : {}}
				>
					<PersonNameView person={person} textVariant={textVariant} />
				</Grid>
			</Grid>
		);
	}
}

export default withStyles(styles)(PersonNameWithAvatarPicture);
