import React, { Component } from 'react';

import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { ClientRoleAssignment, ClientRoleInformation } from '@DigitaleDoerfer/digitale-doerfer-api';
import { roleService } from '../../../ServiceFactory';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
const styles = () => {
	return createStyles({
		roleColumn: {
			paddingLeft: 0,
			paddingRight: 12,
			width: '50%'
		},
		tenantColumn: {
			paddingLeft: 12,
			paddingRight: 0
		},
		tenantColumnIfRoleActionProvider: {
			width: '40%'
		},
		tenantColumnIfNoRoleActionProvider: {
			width: '50%'
		}
	});
};

interface Props extends WithStyles<typeof styles> {
	allAvailableRoles: ClientRoleInformation[];
	userRoles: ClientRoleAssignment[];
	roleActionProvider?: (role: ClientRoleAssignment) => JSX.Element;
}

class RoleAssignmentListView extends Component<Props> {
	render(): JSX.Element {
		const { allAvailableRoles, userRoles, roleActionProvider, classes } = this.props;
		return (
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell align="left" className={classes.roleColumn}>
							Rolle
						</TableCell>
						<TableCell
							align="left"
							className={clsx(
								classes.tenantColumn,
								roleActionProvider
									? classes.tenantColumnIfRoleActionProvider
									: classes.tenantColumnIfNoRoleActionProvider
							)}
						>
							für/von
						</TableCell>
						{roleActionProvider && <TableCell align="center" />}
					</TableRow>
				</TableHead>
				<TableBody>
					{userRoles.map((role: ClientRoleAssignment) => (
						<TableRow key={role.id}>
							<TableCell align="left" className={classes.roleColumn}>
								{role.roleKey ? roleService().getRoleDisplayName(role.roleKey, allAvailableRoles) : 'Unbekannte Rolle'}
							</TableCell>
							<TableCell
								align="left"
								className={clsx(
									classes.tenantColumn,
									roleActionProvider
										? classes.tenantColumnIfRoleActionProvider
										: classes.tenantColumnIfNoRoleActionProvider
								)}
							>
								{roleService().getRelatedEntityNameByRoleAssignment(role, allAvailableRoles) || 'Alle Mandanten'}
							</TableCell>
							{roleActionProvider && (
								<TableCell align="center">{role.id && role.roleKey ? roleActionProvider(role) : ''}</TableCell>
							)}
						</TableRow>
					))}
				</TableBody>
			</Table>
		);
	}
}
export default withStyles(styles)(RoleAssignmentListView);
