import React, { Component } from 'react';
import {
	Grid,
	IconButton,
	Input,
	InputLabel,
	Link,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
	ClientCommunity,
	ClientPersonExtended,
	ClientRoleAssignment,
	ClientRoleInformation
} from '@DigitaleDoerfer/digitale-doerfer-api';
import { roleAssignmentRelatedEntityService, roleService } from '../../../ServiceFactory';
import { RelatedEntity } from '../../../modules/users/store/User.state';
import { withStyles } from '@mui/styles';
import { EntityName } from '../../services/RoleAssignmentRelatedEntity.service';
import BarLoadingIndicator from '../LoadingIndicators/BarLoadingIndicator.view';
import { emptyClientRoleInformation } from '../../services/Role.service';
import ConfirmButton from '../Buttons/ConfirmButton.view';
import CancelButton from '../Buttons/CancelButton.view';
import RoleAssignmentListFormView from './RoleAssignmentListForm.view';

// Return type is hard to define and does not add value here
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = () => ({
	gridRoleDescription: {
		paddingTop: '0 !important',
		paddingBottom: '0 !important'
	}
});
interface Props {
	loadingRoleAssignments: boolean;
	allAvailableRoles: ClientRoleInformation[];
	tenants: ClientCommunity[];
	assignableRoles: ClientRoleInformation[];
	user: ClientPersonExtended;
	canAddNewRoleAssignments: boolean;
	// classes can be anything withing the above styles
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	classes: any;
	handleRemoveRole: (roleAssignmentId: string) => void;
	handleSaveRole: (roleKey: string, relatedEntityId?: string) => void;
	showSnackbarError: (error: unknown) => void;
}

interface State {
	entities: RelatedEntity[];
	selectedEntity: string | undefined;
	selectedRole: string;
	entityNameOfSelectedRole: string;
	selectedRoleDescription: string;
	expandNewRoleAssignment: boolean;
	secondStepEntities: RelatedEntity[];
	selectedGroup: string | undefined;
	isTenantBased: boolean;
	isNewsSourceBased: boolean;
	isGroupBased: boolean;
}

const initialState: State = {
	entities: [],
	selectedEntity: '',
	selectedRole: '',
	entityNameOfSelectedRole: '',
	selectedRoleDescription: '',
	expandNewRoleAssignment: false,
	secondStepEntities: [],
	selectedGroup: '',
	isTenantBased: false,
	isNewsSourceBased: false,
	isGroupBased: false
};

class RoleAssignmentFormView extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = initialState;
		this.handleRoleSelectionChange = this.handleRoleSelectionChange.bind(this);
		this.handleEntitySelectionChange = this.handleEntitySelectionChange.bind(this);
		this.handleGroupChange = this.handleGroupChange.bind(this);
		this.onSaveRole = this.onSaveRole.bind(this);
		this.onExpandNewRoleAssignment = this.onExpandNewRoleAssignment.bind(this);
	}

	handleRoleSelectionChange(event: SelectChangeEvent<string>): void {
		const roleKey = `${event.target.value || ''}`;
		this.setRole(roleKey);
	}

	handleEntitySelectionChange(event: SelectChangeEvent<string>): void {
		const entityId = `${event.target.value || ''}`;
		this.setSelectedEntity(entityId);
	}

	handleGroupChange(event: SelectChangeEvent<string>): void {
		const value = `${event.target.value || ''}`;
		this.setState({ selectedGroup: value });
	}

	async setRole(roleKey: string): Promise<void> {
		const { allAvailableRoles, user, showSnackbarError } = this.props;
		const roleObject: ClientRoleInformation =
			allAvailableRoles.find(role => role.key === roleKey) || emptyClientRoleInformation;
		const relatedEntity = roleObject.relatedEntity ? roleObject.relatedEntity : '';

		//Returns True for Tenants.
		const isTenantBased = roleAssignmentRelatedEntityService().isTenantBased(relatedEntity);
		//Returns true for Only Groups.
		const isGroupBased = roleAssignmentRelatedEntityService().isGroupBased(relatedEntity);
		//Returns true for NewsSouce
		const isNewsSourceBased = roleAssignmentRelatedEntityService().isNewsSourceBased(relatedEntity);
		let relatedEntities;
		try {
			relatedEntities =
				isTenantBased || isGroupBased
					? await roleAssignmentRelatedEntityService().getRelatedEntities(EntityName.COMMUNITY)
					: await roleAssignmentRelatedEntityService().getRelatedEntities(relatedEntity);
		} catch (e) {
			showSnackbarError(e);
		}
		this.setState({
			entities: relatedEntities || [],
			selectedRole: roleKey,
			selectedRoleDescription: roleObject.description || '',
			entityNameOfSelectedRole: relatedEntity,
			isTenantBased,
			isNewsSourceBased,
			isGroupBased
		});
		if (user.homeCommunityId) {
			// Select user´s homeCommunityId as selectedEntity
			if (isTenantBased || isGroupBased) {
				this.setSelectedEntity(user.homeCommunityId);
			}
			if (isNewsSourceBased) {
				this.setSelectedEntity('');
			}
		} else {
			// Entity not selected
			this.setSelectedEntity('');
		}
	}

	setSelectedEntity(entityId: string): void {
		const { showSnackbarError } = this.props;
		const { entityNameOfSelectedRole } = this.state;
		this.setState({ selectedEntity: entityId, selectedGroup: '' }, async () => {
			const { selectedEntity } = this.state;
			if (selectedEntity) {
				let relatedEntities;
				try {
					relatedEntities = await roleAssignmentRelatedEntityService().getRelatedEntities(
						entityNameOfSelectedRole,
						selectedEntity
					);
				} catch (error) {
					showSnackbarError(error);
				}
				this.setState({ secondStepEntities: relatedEntities || [] });
			}
		});
	}

	shouldDisableCreateRoleButton(): [boolean, string?] {
		const {
			selectedEntity,
			selectedRole,
			selectedGroup,
			secondStepEntities,
			isGroupBased,
			isTenantBased,
			isNewsSourceBased
		} = this.state;
		const { loadingRoleAssignments } = this.props;
		const roleAssignments: ClientRoleAssignment[] = this.props.user.roleAssignments || [];

		if (
			!selectedRole ||
			((isTenantBased || isNewsSourceBased) && !selectedEntity) ||
			(isGroupBased && secondStepEntities.length > 0 && selectedGroup === '') ||
			loadingRoleAssignments
		) {
			return [true];
		}
		//Check if only role is needed and no entity information is required. Then see if it matches with already existing role.
		if (
			roleAssignments.some(
				role => role.roleKey === selectedRole && !(isTenantBased || isNewsSourceBased) && !isGroupBased
			)
		) {
			return [true, 'Diese Rolle ist bereits vergeben.'];
		}
		//Check if only role and entity are needed and groups are 0. Then see if they matches with already existing ones.
		if (
			roleAssignments.some(
				role => role.roleKey === selectedRole && role.relatedEntityTenantId === selectedEntity && isTenantBased
			)
		) {
			return [true, 'Diese Rolle ist bereits vergeben.'];
		}
		if (
			roleAssignments.some(
				role => role.roleKey === selectedRole && role.relatedEntityId === selectedEntity && isNewsSourceBased
			)
		) {
			return [true, 'Diese Rolle ist bereits vergeben.'];
		}
		//Check if role, entity and groups all are needed. Then see if they matches with already existing ones.
		if (
			roleAssignments.some(
				role =>
					role.roleKey === selectedRole &&
					role.relatedEntityTenantId === selectedEntity &&
					role.relatedEntityId === selectedGroup &&
					isGroupBased
			)
		) {
			return [true, 'Diese Rolle ist bereits vergeben.'];
		}
		if (selectedRole && isGroupBased && selectedEntity && secondStepEntities.length === 0) {
			return [true, 'Dieser Mandant hat keine Gruppe'];
		}

		return [false];
	}

	onSaveRole(): void {
		const { handleSaveRole } = this.props;
		const { selectedEntity, selectedRole, selectedGroup, isGroupBased, isTenantBased } = this.state;

		if (isGroupBased || isTenantBased) {
			if (selectedGroup !== undefined && !(selectedGroup.length > 0)) {
				handleSaveRole(selectedRole, selectedEntity);
			} else {
				handleSaveRole(selectedRole, selectedGroup);
			}
		} else {
			handleSaveRole(selectedRole, selectedEntity);
		}
		this.setState({ ...initialState, expandNewRoleAssignment: true });
	}

	onExpandNewRoleAssignment(): void {
		this.setState({ expandNewRoleAssignment: true });
	}

	onCancelRoleCreation(): void {
		this.setState(initialState);
	}

	renderRemoveRoleIcon(role: ClientRoleAssignment): JSX.Element {
		const { roleKey, id: roleId } = role;
		const { assignableRoles, handleRemoveRole } = this.props;
		const roleKeyEnum = roleService().convertStringtoRoleEnum(roleKey);
		if (roleId && roleService().hasClientRoleInformationSomeValidRole(assignableRoles, [roleKeyEnum])) {
			return (
				<IconButton aria-label="delete" size="small" onClick={(): void => handleRemoveRole(roleId)}>
					<DeleteIcon fontSize="small" />
				</IconButton>
			);
		}
		return <></>;
	}

	renderEntitiesSelect(): JSX.Element {
		const { entities, selectedEntity, selectedRole, secondStepEntities, selectedGroup, isGroupBased } = this.state;
		if (entities && entities.length > 0) {
			return (
				<>
					<Grid item xs={12} md={4}>
						<InputLabel htmlFor="selectedEntity">in</InputLabel>
						<Select
							variant="standard"
							name="selectedEntity"
							value={selectedEntity ? selectedEntity : ''}
							onChange={this.handleEntitySelectionChange}
							input={<Input id="selectedEntity" />}
							placeholder="selectedEntity"
							disabled={selectedRole ? false : true}
							fullWidth
						>
							{entities.map(entity => {
								return (
									<MenuItem key={entity.id} value={entity.id}>
										{entity.name}
									</MenuItem>
								);
							})}
						</Select>
					</Grid>
					{isGroupBased && selectedEntity && secondStepEntities.length >= 0 ? (
						<Grid item xs={12} md={4}>
							<InputLabel htmlFor="selectedGroup">für/von</InputLabel>
							<Select
								variant="standard"
								name="selectedGroup"
								value={selectedGroup ? selectedGroup : ''}
								onChange={this.handleGroupChange}
								input={<Input id="selectedGroup" />}
								placeholder="selectedGroup"
								disabled={selectedRole ? false : true}
								fullWidth
							>
								{secondStepEntities.length > 0 ? (
									secondStepEntities.map(entity => {
										return (
											<MenuItem key={entity.id} value={entity.id}>
												{entity.name}
											</MenuItem>
										);
									})
								) : (
									<MenuItem value="">
										<em>Keine</em>
									</MenuItem>
								)}
							</Select>
						</Grid>
					) : (
						<></>
					)}
				</>
			);
		}
		return <Grid item xs={12} md={4} />;
	}

	renderCreateRoleButtonErrorMessage(message: string): JSX.Element {
		return (
			<>
				{message && (
					<Grid item xs={12}>
						<Typography color="error">{message}</Typography>
					</Grid>
				)}
			</>
		);
	}

	renderCreateRoleButton(disabled: boolean): JSX.Element {
		return (
			<Grid item xs={12}>
				<ConfirmButton disabled={disabled} onClick={(): void => this.onSaveRole()}>
					Rolle hinzufügen
				</ConfirmButton>
			</Grid>
		);
	}

	renderCancelRoleCreationButton(): JSX.Element {
		return <CancelButton onClick={(): void => this.onCancelRoleCreation()}>Abbrechen</CancelButton>;
	}

	renderCreateRoleForm(): JSX.Element {
		const { assignableRoles, classes } = this.props;
		const { selectedRole, selectedRoleDescription, expandNewRoleAssignment } = this.state;

		if (expandNewRoleAssignment) {
			const [disableButton, disableButtonReason = ''] = this.shouldDisableCreateRoleButton();

			return (
				<>
					<Grid item xs={12} md={4}>
						<InputLabel required htmlFor="role">
							Rolle auswählen
						</InputLabel>
						<Select
							variant="standard"
							name="role"
							value={selectedRole}
							onChange={this.handleRoleSelectionChange}
							input={<Input id="role" />}
							placeholder="Role"
							required
							fullWidth
						>
							{assignableRoles.map((role: ClientRoleInformation) => {
								return (
									<MenuItem key={role.key} value={role.key}>
										{role.displayName}
									</MenuItem>
								);
							})}
						</Select>
					</Grid>
					{this.renderEntitiesSelect()}
					<Grid item xs={12} className={classes.gridRoleDescription}>
						<Typography variant="subtitle2">{selectedRoleDescription}</Typography>
					</Grid>
					{this.renderCreateRoleButtonErrorMessage(disableButtonReason)}
					<Grid item xs={12} container spacing={2}>
						<Grid item>{this.renderCreateRoleButton(disableButton)}</Grid>
						<Grid item>{this.renderCancelRoleCreationButton()}</Grid>
					</Grid>
				</>
			);
		} else {
			return (
				<Grid item xs={12}>
					<Link
						href="#"
						onClick={(event: React.MouseEvent): void => {
							event.preventDefault();
							this.onExpandNewRoleAssignment();
						}}
					>
						Rolle hinzufügen
					</Link>
				</Grid>
			);
		}
	}

	render(): JSX.Element {
		const { user, canAddNewRoleAssignments, loadingRoleAssignments, allAvailableRoles } = this.props;
		const selectedUserRoleAssignments = user.roleAssignments || [];
		return (
			<Grid container spacing={3}>
				{selectedUserRoleAssignments.length > 0 && (
					<>
						<Grid item xs={12}>
							<RoleAssignmentListFormView
								allAvailableRoles={allAvailableRoles}
								userRoles={selectedUserRoleAssignments}
								roleActionProvider={(role: ClientRoleAssignment): JSX.Element => {
									return this.renderRemoveRoleIcon(role);
								}}
							/>
						</Grid>
						<BarLoadingIndicator loading={loadingRoleAssignments} />
					</>
				)}
				{canAddNewRoleAssignments && this.renderCreateRoleForm()}
			</Grid>
		);
	}
}
export default withStyles(styles)(RoleAssignmentFormView);
