import React, { Component } from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';
import {
	ClientOauthAccountBlockReasonEnum,
	ClientPersonExtended,
	ClientPersonExtendedStatusesEnum,
	ClientPersonExtendedVerificationStatusesEnum
} from '@DigitaleDoerfer/digitale-doerfer-api';
import { timeService } from '../../../ServiceFactory';
import { TimestampFormat } from '../../../shared/services/Time.service';
import ExpandablePersonDetailFormView from '../../../shared/views/PersonDetail/ExpandablePersonDetailForm.view';
import EmailTextFieldView from '../../../shared/views/PersonDetail/EmailTextField.view';
import { ReadOnlyTextFieldView } from '../../../shared/views/ReadOnlyTextField.view';
import ReadOnlyTextFieldWithClipboardView from '../../../shared/views/ReadOnlyTextFieldWithClipboard.view';
import PersonNameWithAvatarPictureFormView from '../../../shared/views/PersonDetail/PersonNameWithAvatarPictureForm.view';
import DevicesListView from '../../../shared/views/PersonDetail/DevicesList.view';

interface Props {
	selectedUser: ClientPersonExtended;
	confirmChangeEmail: (userId: string) => void;
	cancelChangeEmail: (userId: string) => void;
}

interface State {
	showMoreDetails: boolean;
}

class UserDetailsView extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { showMoreDetails: false };
		this.handleMoreDetailsLink = this.handleMoreDetailsLink.bind(this);
	}

	handleMoreDetailsLink(): void {
		const { showMoreDetails } = this.state;
		this.setState({ showMoreDetails: !showMoreDetails });
	}

	getUserName(selectedUser: ClientPersonExtended): string {
		return selectedUser.firstName ? selectedUser.firstName : 'Der Benutzer';
	}

	getPendingEmailChangeElement(selectedUser: ClientPersonExtended): React.ReactNode | null {
		if (!selectedUser.pendingNewEmail) {
			return null;
		}

		const userName = this.getUserName(selectedUser);

		return (
			<Box pl={0} mt={1}>
				<Typography variant="body2">
					{userName} hat eine Änderung zu <strong>{selectedUser.pendingNewEmail}</strong> veranlasst und muss seine neue
					Adresse noch bestätigen.
					<br />
					Du kannst die Änderung{' '}
					<Link
						href="#"
						onClick={(event: React.MouseEvent): void => {
							event.preventDefault();
							this.props.confirmChangeEmail(this.props.selectedUser.id);
						}}
					>
						akzeptieren
					</Link>
					{' oder '}
					<Link
						href="#"
						onClick={(event: React.MouseEvent): void => {
							event.preventDefault();
							this.props.cancelChangeEmail(this.props.selectedUser.id);
						}}
					>
						ablehnen
					</Link>
					.
				</Typography>
			</Box>
		);
	}

	getInformativeText(selectedUser: ClientPersonExtended): string {
		const userName = this.getUserName(selectedUser);

		// This value is always there for a backend person, so it the default 0 will never be used
		const createdTimeString = timeService().parseTimestamp(
			selectedUser.created || 0,
			TimestampFormat.DD_MM_YYYY_AT_HH_MM_O_CLOCK
		);

		// Handle case of deleted user.
		if (selectedUser.deleted) {
			if (selectedUser.created === -1) {
				// For some older deleted users, the created time has been reset to -1, which then is no actual timestamp
				return 'Der Account wurde gelöscht.';
			}
			return `Der Benutzer hat sich am ${createdTimeString} seinen Account erstellt. Der Account wurde gelöscht.`;
		}

		let informativeText = '';

		if (selectedUser.lastLoggedIn === undefined) {
			informativeText = `${userName} ist registriert seit ${createdTimeString}.`;
		} else if (selectedUser.created === selectedUser.lastLoggedIn) {
			informativeText = `Der Benutzeraccount von ${userName} wurde am ${createdTimeString} angelegt.`;
		} else {
			const lastLoggedInString = timeService().parseTimestamp(
				selectedUser.lastLoggedIn || 0,
				TimestampFormat.DD_MM_YYYY_AT_HH_MM_O_CLOCK
			);
			informativeText = `${userName} ist registriert seit ${createdTimeString} und war zum letzten Mal am ${lastLoggedInString} aktiv.`;
		}

		if (selectedUser.oauthAccount && selectedUser.oauthAccount.loginCount !== undefined) {
			const loginCount = selectedUser.oauthAccount.loginCount;
			if (loginCount === 1) {
				informativeText += ' Bei Keycloak gab es einen erfolgreichen Login-Vorgang.';
			} else {
				informativeText += ` Bei Keycloak gab es ${loginCount} erfolgreiche Login-Vorgänge.`;
			}
		}

		return informativeText;
	}

	renderInformativeText(selectedUser: ClientPersonExtended): JSX.Element {
		return <Typography variant="h5">{this.getInformativeText(selectedUser)}</Typography>;
	}

	renderDevicesTable(selectedUser: ClientPersonExtended): JSX.Element {
		return (
			<DevicesListView
				devices={selectedUser.oauthAccount?.devices ? selectedUser.oauthAccount?.devices : []}
			></DevicesListView>
		);
	}

	statusToStatusMessage(status: ClientPersonExtendedStatusesEnum | string): string {
		switch (status) {
			case ClientPersonExtendedStatusesEnum.LoginBlocked: {
				return 'Authentifizierung blockiert.';
			}
			case ClientPersonExtendedStatusesEnum.PendingDeletion: {
				return 'Ausstehende Löschung wegen Inaktivität.';
			}
			case ClientPersonExtendedStatusesEnum.ParallelWorldInhabitant: {
				return 'Befindet sich in der Parallelwelt.';
			}
		}

		return status;
	}

	displayableStatuses: ClientPersonExtendedStatusesEnum[] = [
		ClientPersonExtendedStatusesEnum.PendingDeletion,
		ClientPersonExtendedStatusesEnum.ParallelWorldInhabitant
	];

	render(): JSX.Element | null {
		const { selectedUser } = this.props;
		if (!selectedUser.id) {
			return null;
		}
		const {
			deleted,
			email,
			oauthAccount,
			homeCommunityName,
			homeCommunityId,
			homeAreaName,
			homeAreaId,
			homeAreaTenantId,
			homeAreaTenantName,
			verificationStatuses
		} = selectedUser;
		// Process form data
		const homeCommunityAndAreaTenantAreDifferent =
			(homeCommunityId && homeAreaTenantId && homeCommunityId !== homeAreaTenantId) || false;
		const statusMessages: string[] = [];
		const { showMoreDetails } = this.state;

		let { emailVerified, oauthId, oauthFailedReason } = {
			emailVerified: false,
			oauthId: 'Keine OAuth-Information vorhanden',
			oauthFailedReason: ''
		};

		if (oauthAccount) {
			if (oauthAccount.blockReason !== null && oauthAccount.blockReason !== undefined) {
				if (oauthAccount.blockReason === ClientOauthAccountBlockReasonEnum.ManuallyBlocked) {
					statusMessages.push('Person gesperrt (vom Support-Team).');
				} else if (oauthAccount.blockReason === ClientOauthAccountBlockReasonEnum.TooManyLoginAttempts) {
					statusMessages.push('Person gesperrt (zu viele Login-Versuche).');
				}
			}
			if (
				oauthAccount.retrievalFailed !== null &&
				oauthAccount.retrievalFailed !== undefined &&
				oauthAccount.retrievalFailed === false
			) {
				oauthId = oauthAccount.oauthId ?? '';
			} else {
				oauthFailedReason = oauthAccount.failedReason ?? '';
			}
			if (verificationStatuses) {
				emailVerified =
					!deleted && verificationStatuses.includes(ClientPersonExtendedVerificationStatusesEnum.EmailVerified);
			}
		}

		// These are the statuses that we currently consider to display
		const statuses = deleted
			? undefined
			: selectedUser.statuses?.filter(status => this.displayableStatuses.includes(status));

		statuses?.forEach(status => {
			statusMessages.push(this.statusToStatusMessage(status));
		});

		return (
			<Grid container spacing={3}>
				<Grid item xs={12} container justifyContent="flex-start" alignItems="flex-end">
					<Grid container direction="column">
						{statusMessages.map(statusMessage => (
							<Grid item key={statusMessage}>
								<Typography color="error" gutterBottom>
									{statusMessage}
								</Typography>
							</Grid>
						))}
					</Grid>
				</Grid>
				<PersonNameWithAvatarPictureFormView user={selectedUser} />
				<Grid item xs={12} md={6}>
					<EmailTextFieldView email={email} emailVerified={emailVerified} />
					{this.getPendingEmailChangeElement(selectedUser)}
				</Grid>
				<Grid item xs={12} md={6}>
					<ReadOnlyTextFieldWithClipboardView
						textToCopy={homeAreaId ?? ''}
						values={{
							id: 'homeAreaName',
							name: 'homeAreaName',
							label: 'Heimatgemeinde (Geo Area)',
							value: homeAreaName ?? '',
							error: homeCommunityAndAreaTenantAreDifferent,
							helperText: homeCommunityAndAreaTenantAreDifferent
								? `Abweichender Mandant der Heimatgemeinde: ${homeAreaTenantName}`
								: '',
							InputLabelProps: { shrink: true }
						}}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<ReadOnlyTextFieldView
						id="oauthAccount"
						name="oauthAccount"
						label="OAuth-ID"
						title={oauthFailedReason}
						value={oauthId ?? ''}
						InputProps={{ readOnly: true }}
						InputLabelProps={{
							shrink: true
						}}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<ReadOnlyTextFieldWithClipboardView
						textToCopy={homeCommunityId ?? ''}
						values={{
							id: 'homeCommunityName',
							name: 'homeCommunityName',
							label: 'Heimat-Mandant (Home Community)',
							value: homeCommunityName ?? '',
							InputLabelProps: { shrink: true }
						}}
					/>
				</Grid>
				<ExpandablePersonDetailFormView
					person={selectedUser}
					showMoreDetails={showMoreDetails}
					handleMoreDetailsLink={this.handleMoreDetailsLink}
				/>
				<Grid item xs={12}>
					{this.renderInformativeText(selectedUser)}
				</Grid>
				<Grid item xs={12}>
					{this.renderDevicesTable(selectedUser)}
				</Grid>
			</Grid>
		);
	}
}

export default UserDetailsView;
