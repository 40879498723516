import {
	ClientApp,
	ClientAppVariant,
	ClientAppVariantTenantContract,
	ClientGeoAreaExtended,
	ClientTenant
} from '@DigitaleDoerfer/digitale-doerfer-api';

export type ValueOf<T> = T[keyof T];

export const FilterType = {
	APP_VARIANT: 'appVariant',
	TENANT: 'tenant'
} as const;

export type Filter<T> = T & {
	type: ValueOf<typeof FilterType>;
	isParentNode?: boolean;
};

export type App = ClientApp & { name: string; subItems?: AppVariant[] };

export type AppVariant = Omit<ClientAppVariant, 'app'> & {
	app: App;
	name: string;
};

export type Tenant = ClientTenant & {
	name: string;
};
export type Contract = ClientAppVariantTenantContract & {
	name: string;
};

export const ListType = {
	...FilterType,
	CONTRACT: 'contract'
} as const;

export type SearchQuery = {
	query: string;
	type: ValueOf<typeof ListType>;
};

export type TreeNodeItem<T> = T & { type: ValueOf<typeof ListType> };

export type Filters = { [key in ValueOf<typeof FilterType>]: { [key: string]: Filter<AppVariant | Tenant> } };

export type GeoArea = ClientGeoAreaExtended;
