import React, { FC, useEffect, useState } from 'react';
import { useStore } from '../store';
import { Grid, Paper, Skeleton, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { imageService } from '../../../ServiceFactory';
import { ClientMediaItem } from '@DigitaleDoerfer/digitale-doerfer-api';
import { ImageSizes } from '../../../shared/services/Image.service';
import useItemDetails from './hooks/useItemDetails';
import { Contract, ListType, ValueOf } from '../types';
import { getBackgroundColorByType } from '../helpers';
import AdditionalNotes from './AdditionalNotes';

const SelectedItemDetails: FC = () => {
	const { item: selectedItem, type: selectedType } = useStore(state => state.selectedListItem);
	const [contract, setContract] = useState<Contract | null>(null);
	const getItemDetails = useItemDetails(contract);

	const useStyles = makeStyles((theme: Theme) =>
		createStyles({
			img: {
				height: '3rem',
				width: '3rem'
			},

			paper: {
				textAlign: 'center',
				height: '100%',
				color: theme.palette.text.secondary,
				backgroundColor: ({ type }: { type: ValueOf<typeof ListType> | undefined }): string =>
					type ? getBackgroundColorByType(type) : 'white'
			}
		})
	);

	const classes = useStyles({ type: selectedType });

	const handleImage = (image: ClientMediaItem): JSX.Element => {
		if (!image as boolean) {
			return <Skeleton variant="circular" animation={false} width="3rem" height="3rem" />;
		}
		const imageUrl = imageService().getAvailableImageUrl(image, ImageSizes.THUMBNAIL);
		return <img className={classes.img} src={imageUrl} alt="Logo" />;
	};

	const printAttributes = (value: string | object | number, key?: string): JSX.Element => {
		const readableName =
			key && getItemDetails && Object.keys(getItemDetails).includes(key) ? getItemDetails[key] : undefined;
		if (typeof value === 'string') {
			return (
				<>
					{readableName ? (
						<Typography>
							{readableName} (Id: {value})
						</Typography>
					) : key === 'additionalNotes' && contract?.id ? (
						<AdditionalNotes notes={value} contractId={contract.id} />
					) : (
						<Typography>{value}</Typography>
					)}
				</>
			);
		}
		if (typeof value === 'number') {
			return <Typography>{new Date(value).toLocaleString('de-DE')}</Typography>;
		}

		return (
			<>
				{readableName && Array.isArray(readableName) ? (
					readableName.map((path, index) => (
						<Typography
							key={index}
							sx={{
								marginBottom: '0.5rem'
							}}
						>
							{path}
						</Typography>
					))
				) : (
					<Grid
						container
						spacing={1}
						sx={{
							justifyContent: 'flex-start',
							alignItems: 'flex-start',
							textAlign: 'left'
						}}
						key={JSON.stringify(value)}
					>
						{value
							? Object.entries(value).map(([key, value]) => (
									<React.Fragment key={JSON.stringify(key + value)}>
										<Grid item xs={3} key={key} zeroMinWidth>
											<Typography
												sx={{
													fontWeight: 'bold',
													overflowWrap: 'break-word'
												}}
											>
												{key.charAt(0).toUpperCase() + key.slice(1)}:
											</Typography>
										</Grid>
										<Grid item xs={9} key={`${key}sValue`} zeroMinWidth>
											{key === 'logo' || key === 'profilePicture' ? handleImage(value) : printAttributes(value, key)}
											{key === 'additionalNotes' && value === undefined && contract?.id && (
												<AdditionalNotes notes={value} contractId={contract.id} />
											)}
										</Grid>
									</React.Fragment>
							  ))
							: ''}
					</Grid>
				)}
			</>
		);
	};

	useEffect(() => {
		if (selectedType === ListType.CONTRACT && selectedItem) setContract(selectedItem);
	}, [selectedType, selectedItem, getItemDetails]);

	return (
		<Paper className={classes.paper} variant="outlined">
			<Typography
				variant="h4"
				component="h2"
				align="left"
				sx={{
					marginBottom: '1rem'
				}}
			>
				Details {selectedItem && selectedType ? `von ${selectedItem.name}` : ''}
			</Typography>
			{selectedItem ? printAttributes(selectedItem) : ''}
		</Paper>
	);
};

export default SelectedItemDetails;
