import React, { ReactNode } from 'react';
import { CircularProgress, TextField } from '@mui/material';
import Autocomplete, { AutocompleteChangeReason } from '@mui/material/Autocomplete';
import { ClientPersonExtended, PagedModelClientPersonExtended } from '@DigitaleDoerfer/digitale-doerfer-api';
import { searchUserPlaceHolder } from '../HelperTexts';
import { geoAreaService, participantsAdminuiPersonApi } from '../../../ServiceFactory';
import GroupUserElementView from './GroupUserElement.view';
import { AnyGeoArea } from '../../utils/AnyGeoArea';
import { GroupStateChangeHandlers } from '../../../modules/groups/GroupStateChangeHandlers';
import { GeoAreaElement } from '../../utils/GeoAreaElements';

interface Props {
	geoAreas?: AnyGeoArea[];
	flattenedGeoAreas?: AnyGeoArea[];
	autocompleteLabel: string;
	groupMembershipAdmins: ClientPersonExtended[];
	groupMembershipAdminGeoAreas: GeoAreaElement[];
	groupStateChangeHandlers: GroupStateChangeHandlers;
}

interface State {
	open: boolean;
	autocompleteOptions: ClientPersonExtended[];
	loading: boolean;
	typingTimeout?: NodeJS.Timeout;
}

const initialState: State = {
	open: false,
	autocompleteOptions: [],
	loading: false,
	typingTimeout: undefined
};

class GroupAdministratorAutocompleteView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = initialState;
		this.getUsers = this.getUsers.bind(this);
		this.handleOpen = this.handleOpen.bind(this);
		this.handleAutocompleteChange = this.handleAutocompleteChange.bind(this);
		this.handleSelectedAutocompleteChange = this.handleSelectedAutocompleteChange.bind(this);
	}

	async getUsers(searchText: string): Promise<void> {
		const { groupMembershipAdmins } = this.props;
		this.setState({ loading: true });
		const response: PagedModelClientPersonExtended = await participantsAdminuiPersonApi().getPersons({
			search: searchText
		});
		// Filter out those users which are already selected
		const userOptions =
			response.content?.filter(
				(filteredUser: ClientPersonExtended) =>
					!groupMembershipAdmins?.some((selectedUser: ClientPersonExtended) => filteredUser.id === selectedUser.id)
			) ?? [];

		this.setState({ autocompleteOptions: userOptions, loading: false });
	}

	handleAutocompleteChange(event: React.ChangeEvent<{ name?: string; value: string }>): void {
		const { value } = event.target;
		const { typingTimeout } = this.state;
		if (value && value.length > 3) {
			this.setState((state: State): State => {
				if (typingTimeout) {
					clearTimeout(typingTimeout);
				}
				return {
					...state,
					typingTimeout: setTimeout(() => this.getUsers(value), 500)
				};
			});
		}
	}

	updateGeoAreasElements(selectedUser: ClientPersonExtended): void {
		const { geoAreas, flattenedGeoAreas, groupMembershipAdminGeoAreas, groupStateChangeHandlers } = this.props;
		const { handleGroupMembershipAdminGeoAreasChange } = groupStateChangeHandlers;
		if (handleGroupMembershipAdminGeoAreasChange && geoAreas && flattenedGeoAreas) {
			const newGroupMembershipAdminGeoAreas = geoAreaService().updatesGeoAreaElementsByPerson(
				groupMembershipAdminGeoAreas,
				geoAreas,
				selectedUser
			);
			handleGroupMembershipAdminGeoAreasChange(newGroupMembershipAdminGeoAreas);
		}
	}

	/**
	 * Handle the selected autocomplete user.
	 * 1. Add the selected user to the groupMembershipAdmins list.
	 * 2. Updates the autocompleteOptions removing the selected user from the list.
	 * @param event The event source of the callback.
	 * @param selectedUser The autocomplete selected user.
	 * @param reason  One of "create-option", "select-option", "remove-option", "blur" or "clear".
	 */
	handleSelectedAutocompleteChange(
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		event: object,
		selectedUser: ClientPersonExtended | null,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		reason: AutocompleteChangeReason
	): void {
		const { groupStateChangeHandlers, groupMembershipAdmins } = this.props;
		const { handleGroupMembershipAdminsChange, handleGroupMembershipAdminGeoAreasChange } = groupStateChangeHandlers;
		const { autocompleteOptions } = this.state;
		const newGroupAdmins: ClientPersonExtended[] = [...groupMembershipAdmins];

		if (selectedUser) {
			newGroupAdmins.push(selectedUser);
			const updatedOptions: ClientPersonExtended[] = autocompleteOptions.filter(
				(userMember: ClientPersonExtended) => userMember.id !== selectedUser.id
			);
			this.setState({ autocompleteOptions: updatedOptions });
			handleGroupMembershipAdminsChange && handleGroupMembershipAdminsChange(newGroupAdmins);
			handleGroupMembershipAdminGeoAreasChange && this.updateGeoAreasElements(selectedUser);
		}
	}

	/**
	 * Changes the state to open the autocomplete options list.
	 * @param open True to open, otherwise, close it.
	 */
	handleOpen(open: boolean): void {
		this.setState({ open });
	}
	render(): JSX.Element {
		const { open, loading, autocompleteOptions } = this.state;
		const { autocompleteLabel } = this.props;

		return (
			<Autocomplete
				id="asynchronous-search"
				open={open}
				filterOptions={(autocompleteOptions: ClientPersonExtended[]): ClientPersonExtended[] => autocompleteOptions}
				onOpen={(): void => {
					this.handleOpen(true);
				}}
				onClose={(): void => {
					this.handleOpen(false);
				}}
				onChange={this.handleSelectedAutocompleteChange}
				isOptionEqualToValue={(user: ClientPersonExtended, value: ClientPersonExtended): boolean =>
					user.firstName === value.firstName
				}
				getOptionLabel={(user: ClientPersonExtended): string => `${user.firstName} ${user.lastName}`}
				renderOption={(props, user: ClientPersonExtended): ReactNode => (
					<li {...props}>
						<GroupUserElementView user={user} isSearchFieldElement={true} />
					</li>
				)}
				openOnFocus={false}
				options={autocompleteOptions}
				loading={loading}
				noOptionsText={'Benutzer wurde nicht gefunden'}
				renderInput={(params): ReactNode => (
					<TextField
						variant="standard"
						{...params}
						label={autocompleteLabel}
						InputLabelProps={{
							shrink: true
						}}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? <CircularProgress color="inherit" size={20} /> : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							)
						}}
						onChange={this.handleAutocompleteChange}
						placeholder={searchUserPlaceHolder}
						autoComplete={'off'}
						fullWidth
					/>
				)}
				autoComplete
				fullWidth
			/>
		);
	}
}

export default GroupAdministratorAutocompleteView;
