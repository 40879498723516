import React, { Component } from 'react';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import GroupIcon from '@mui/icons-material/Group';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import DialogActionView from '../../../../shared/views/ActionMenu/DialogAction.view';
import GroupAdministratorsView from '../../../../shared/views/Group/GroupAdministrators.view';
import GroupAdministratorAutocompleteView from '../../../../shared/views/Group/GroupAdministratorAutocomplete.view';
import {
	ClientGroupExtendedDetail,
	ClientPersonExtended,
	ClientPersonReferenceWithEmail
} from '@DigitaleDoerfer/digitale-doerfer-api';
import { AnyPerson } from '../../../../shared/utils/AnyPerson';
import { GeoAreaElement } from '../../../../shared/utils/GeoAreaElements';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => {
	return createStyles({
		checkField: {
			overflowY: 'visible',
			height: 100
		},
		emphasized: {
			fontWeight: 'bold'
		}
	});
};

interface Props extends ActionMenuItemProps, WithStyles<typeof styles> {
	group: ClientGroupExtendedDetail;
	addAdmin: (groupId: string, adminId: string) => void;
	removeAdmin: (groupId: string, adminId: string) => void;
}

interface State {
	admins: ClientPersonExtended[];
	geoAreas: GeoAreaElement[];
}

class ChangeGroupAdminsActionView extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			admins: [],
			geoAreas: []
		};
		this.handleOpenDialog = this.handleOpenDialog.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.isSubmitDisabled = this.isSubmitDisabled.bind(this);
		this.handleGroupMembershipAdminsChange = this.handleGroupMembershipAdminsChange.bind(this);
		this.handleGroupMembershipDelete = this.handleGroupMembershipDelete.bind(this);
	}

	initDialogData(): void {
		const { group } = this.props;
		const { groupMembershipAdmins, includedGeoAreas } = group;

		const admins = groupMembershipAdmins as Array<ClientPersonExtended>;
		const groupGeoAreas = includedGeoAreas as unknown as Array<GeoAreaElement>;
		this.setState({ admins, geoAreas: groupGeoAreas });
	}

	handleOpenDialog(): void {
		this.initDialogData();
	}

	handleSubmit(): void {
		const { admins } = this.state;
		const { group, addAdmin, removeAdmin } = this.props;
		const initialAdmins = group.groupMembershipAdmins;

		const idsToRemove: string[] = [];
		const idsToAdd: string[] = [];

		admins.forEach((clientPerson: ClientPersonExtended) => {
			const isNew =
				initialAdmins?.find((admin: ClientPersonReferenceWithEmail) => {
					return admin.id === clientPerson.id;
				}) === undefined;
			if (isNew) {
				idsToAdd.push(clientPerson.id);
			}
		});

		initialAdmins?.forEach((clientPerson: ClientPersonReferenceWithEmail) => {
			const isRemoved =
				admins?.find((admin: ClientPersonExtended) => {
					return admin.id === clientPerson.id;
				}) === undefined;
			if (isRemoved) {
				idsToRemove.push(clientPerson.id);
			}
		});
		idsToAdd.forEach((id: string) => {
			addAdmin(group.id, id);
		});
		idsToRemove.forEach((id: string) => {
			removeAdmin(group.id, id);
		});
	}

	isSubmitDisabled(): boolean {
		const { admins } = this.state;

		return admins.length === 0;
	}

	handleGroupMembershipAdminsChange(newGroupMembershipAdmins: ClientPersonExtended[]): void {
		this.setState({ admins: newGroupMembershipAdmins });
	}

	handleGroupMembershipDelete(removedGroupMembershipAdmin: AnyPerson): void {
		const { admins } = this.state;
		const filteredAdmins = admins.filter((user: ClientPersonExtended) => {
			return user.id !== removedGroupMembershipAdmin.id;
		});
		this.setState({ admins: filteredAdmins });
	}

	render(): JSX.Element {
		const { renderMenuItem } = this.props;
		const { admins, geoAreas } = this.state;
		const stateHandlers = {
			handleGroupMembershipAdminsChange: this.handleGroupMembershipAdminsChange
		};
		return (
			<DialogActionView
				itemProps={{
					renderMenuItem,
					icon: <GroupIcon color="primary" />,
					text: 'Administratoren bearbeiten'
				}}
				dialogProps={{
					danger: false,
					titleText: 'Administratoren bearbeiten',
					saveButtonText: 'speichern',
					handleOpen: this.handleOpenDialog,
					handleSave: this.handleSubmit,
					handleIsSaveDisabled: this.isSubmitDisabled,
					wide: true,
					ignoreKeyDownKeys: ['Enter']
				}}
			>
				<GroupAdministratorsView admins={admins} handleUserDeletion={this.handleGroupMembershipDelete}>
					<GroupAdministratorAutocompleteView
						autocompleteLabel="Gruppenadmin(s) hinzufügen"
						groupMembershipAdmins={admins}
						groupMembershipAdminGeoAreas={geoAreas}
						groupStateChangeHandlers={stateHandlers}
					/>
				</GroupAdministratorsView>
			</DialogActionView>
		);
	}
}

export default withStyles(styles)(ChangeGroupAdminsActionView);
