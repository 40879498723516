import {
	ACCEPT_FLAG_AND_DELETE_CONTENT_FAILURE,
	ACCEPT_FLAG_AND_DELETE_CONTENT_REQUEST,
	ACCEPT_FLAG_AND_DELETE_CONTENT_SUCCESS,
	AcceptFlagAndDeleteContentFailureAction,
	AcceptFlagAndDeleteContentRequestAction,
	AcceptFlagAndDeleteContentSuccessAction,
	CHANGE_FLAG_STATUS_FAILURE,
	CHANGE_FLAG_STATUS_REQUEST,
	CHANGE_FLAG_STATUS_SUCCESS,
	ChangeFlagStatusFailureAction,
	ChangeFlagStatusRequestAction,
	ChangeFlagStatusSuccessAction,
	FlaggedContentActionTypes,
	GET_CHAT_FLAG_FAILURE,
	GET_CHAT_FLAG_REQUEST,
	GET_CHAT_FLAG_SUCCESS,
	GET_COMMENT_FLAG_FAILURE,
	GET_COMMENT_FLAG_REQUEST,
	GET_COMMENT_FLAG_SUCCESS,
	GET_FLAGGED_CONTENT_FAILURE,
	GET_FLAGGED_CONTENT_REQUEST,
	GET_FLAGGED_CONTENT_SUCCESS,
	GET_FLAGGED_CONTENTS_FAILURE,
	GET_FLAGGED_CONTENTS_REQUEST,
	GET_FLAGGED_CONTENTS_SUCCESS,
	GET_GROUP_FLAG_FAILURE,
	GET_GROUP_FLAG_REQUEST,
	GET_GROUP_FLAG_SUCCESS,
	GET_POST_FLAG_FAILURE,
	GET_POST_FLAG_REQUEST,
	GET_POST_FLAG_SUCCESS,
	GetChatFlagFailureAction,
	GetChatFlagRequestAction,
	GetChatFlagSuccessAction,
	GetCommentFlagFailureAction,
	GetCommentFlagRequestAction,
	GetCommentFlagSuccessAction,
	GetFlaggedContentFailureAction,
	GetFlaggedContentRequestAction,
	GetFlaggedContentsFailureAction,
	GetFlaggedContentsRequestAction,
	GetFlaggedContentsSuccessAction,
	GetFlaggedContentSuccessAction,
	GetGroupFlagFailureAction,
	GetGroupFlagRequestAction,
	GetGroupFlagSuccessAction,
	GetPostFlagFailureAction,
	GetPostFlagRequestAction,
	GetPostFlagSuccessAction
} from './FlaggedContentActionTypes';
import { FlaggedContentState, FlaggedType, initialFlaggedContentState } from './FlaggedContent.state';
import {
	ClientUserGeneratedContentFlagChat,
	ClientUserGeneratedContentFlagChatStatusEnum,
	ClientUserGeneratedContentFlagComment,
	ClientUserGeneratedContentFlagCommentStatusEnum,
	ClientUserGeneratedContentFlagDetail,
	ClientUserGeneratedContentFlagGroupStatusEnum,
	ClientUserGeneratedContentFlagPost,
	ClientUserGeneratedContentFlagPostStatusEnum
} from '@DigitaleDoerfer/digitale-doerfer-api';
import { FlagSummary } from '../flagged-content-details/shared/FlaggedContentDetailsCommons';

const getFlaggedContentsRequest = (
	state: FlaggedContentState,
	action: GetFlaggedContentsRequestAction
): FlaggedContentState => {
	return {
		...state,
		list: {
			...state.list,
			error: null,
			loading: true
		}
	};
};

const getFlaggedContentsSuccess = (
	state: FlaggedContentState,
	action: GetFlaggedContentsSuccessAction
): FlaggedContentState => {
	return {
		...state,
		list: {
			...state.list,
			flaggedContents: action.flaggedContents,
			searchParams: {
				...state.list.searchParams,
				includedStatus: action.includedStatus,
				sorting: action.sorting,
				pagination: action.pagination
			},
			loading: false
		}
	};
};

const getFlaggedContentsFailure = (
	state: FlaggedContentState,
	action: GetFlaggedContentsFailureAction
): FlaggedContentState => {
	return {
		...state,
		list: {
			...state.list,
			error: action.error,
			loading: false
		}
	};
};

const getFlaggedContentRequest = (
	state: FlaggedContentState,
	action: GetFlaggedContentRequestAction
): FlaggedContentState => {
	return {
		...state,
		detail: {
			...state.detail,
			type: FlaggedType.OTHER,
			other: undefined,
			loading: true
		}
	};
};

const getFlaggedContentSuccess = (
	state: FlaggedContentState,
	action: GetFlaggedContentSuccessAction
): FlaggedContentState => {
	return {
		...state,
		detail: {
			...state.detail,
			type: FlaggedType.OTHER,
			post: undefined,
			chat: undefined,
			comment: undefined,
			other: action.flaggedContent,
			flagId: action.flaggedContent.id ?? '',
			loading: false,
			statusRecords: action.statusRecords,
			flagSummary: action.flagSummary
		}
	};
};

const getFlaggedContentFailure = (
	state: FlaggedContentState,
	action: GetFlaggedContentFailureAction
): FlaggedContentState => {
	return {
		...state,
		detail: {
			...state.detail,
			loading: false
		}
	};
};

const getFlaggedGroupRequest = (state: FlaggedContentState, action: GetGroupFlagRequestAction): FlaggedContentState => {
	return {
		...state,
		detail: {
			...state.detail,
			type: FlaggedType.OTHER,
			group: undefined,
			loading: true
		}
	};
};

const getFlaggedGroupSuccess = (state: FlaggedContentState, action: GetGroupFlagSuccessAction): FlaggedContentState => {
	return {
		...state,
		detail: {
			...state.detail,
			type: FlaggedType.GROUP,
			post: undefined,
			chat: undefined,
			comment: undefined,
			group: action.flaggedContent,
			other: undefined,
			flagId: action.flaggedContent.id ?? '',
			loading: false,
			statusRecords: action.statusRecords,
			flagSummary: action.flagSummary
		}
	};
};

const getFlaggedGroupFailure = (state: FlaggedContentState, action: GetGroupFlagFailureAction): FlaggedContentState => {
	return {
		...state,
		detail: {
			...state.detail,
			loading: false
		}
	};
};

const getChatFlagRequest = (state: FlaggedContentState, action: GetChatFlagRequestAction): FlaggedContentState => {
	return {
		...state,
		detail: {
			...state.detail,
			type: FlaggedType.OTHER,
			chat: undefined,
			loading: true
		}
	};
};

const getChatFlagSuccess = (state: FlaggedContentState, action: GetChatFlagSuccessAction): FlaggedContentState => {
	return {
		...state,
		detail: {
			...state.detail,
			type: FlaggedType.CHAT,
			post: undefined,
			chat: action.chatFlagContent,
			comment: undefined,
			other: undefined,
			flagId: action.chatFlagContent.id ?? '',
			loading: false,
			statusRecords: action.statusRecords,
			flagSummary: action.flagSummary
		}
	};
};

const getChatFlagFailure = (state: FlaggedContentState, action: GetChatFlagFailureAction): FlaggedContentState => {
	return {
		...state,
		detail: {
			...state.detail,
			loading: false
		}
	};
};

const getCommentFlagRequest = (
	state: FlaggedContentState,
	action: GetCommentFlagRequestAction
): FlaggedContentState => {
	return {
		...state,
		detail: {
			...state.detail,
			type: FlaggedType.OTHER,
			comment: undefined,
			loading: true
		}
	};
};

const getCommentFlagSuccess = (
	state: FlaggedContentState,
	action: GetCommentFlagSuccessAction
): FlaggedContentState => {
	return {
		...state,
		detail: {
			...state.detail,
			type: FlaggedType.COMMENT,
			post: undefined,
			chat: undefined,
			comment: action.commentFlagContent,
			other: undefined,
			flagId: action.commentFlagContent.id ?? '',
			loading: false,
			statusRecords: action.statusRecords,
			flagSummary: action.flagSummary
		}
	};
};

const getCommentFlagFailure = (
	state: FlaggedContentState,
	action: GetCommentFlagFailureAction
): FlaggedContentState => {
	return {
		...state,
		detail: {
			...state.detail,
			loading: false
		}
	};
};

const getPostFlagRequest = (state: FlaggedContentState, action: GetPostFlagRequestAction): FlaggedContentState => {
	return {
		...state,
		detail: {
			...state.detail,
			type: FlaggedType.OTHER,
			post: undefined,
			loading: true
		}
	};
};

const getPostFlagSuccess = (state: FlaggedContentState, action: GetPostFlagSuccessAction): FlaggedContentState => {
	return {
		...state,
		detail: {
			...state.detail,
			type: FlaggedType.POST,
			post: action.postFlagContent,
			chat: undefined,
			comment: undefined,
			other: undefined,
			flagId: action.postFlagContent.id ?? '',
			loading: false,
			statusRecords: action.statusRecords,
			flagSummary: action.flagSummary
		}
	};
};

const getPostFlagFailure = (state: FlaggedContentState, action: GetPostFlagFailureAction): FlaggedContentState => {
	return {
		...state,
		detail: {
			...state.detail,
			loading: false
		}
	};
};

function getClientUserGeneratedContentFlagChatStatusEnum(
	clientUserGeneratedContentFlagDetailStatusEnum?: string
): ClientUserGeneratedContentFlagChatStatusEnum | undefined {
	switch (clientUserGeneratedContentFlagDetailStatusEnum) {
		case ClientUserGeneratedContentFlagChatStatusEnum.Open:
			return ClientUserGeneratedContentFlagChatStatusEnum.Open;
		case ClientUserGeneratedContentFlagChatStatusEnum.InProgress:
			return ClientUserGeneratedContentFlagChatStatusEnum.InProgress;
		case ClientUserGeneratedContentFlagChatStatusEnum.Accepted:
			return ClientUserGeneratedContentFlagChatStatusEnum.Accepted;
		case ClientUserGeneratedContentFlagChatStatusEnum.Rejected:
			return ClientUserGeneratedContentFlagChatStatusEnum.Rejected;
		default:
			return undefined;
	}
}

function getClientUserGeneratedContentFlagCommentStatusEnum(
	clientUserGeneratedContentFlagDetailStatusEnum?: string
): ClientUserGeneratedContentFlagCommentStatusEnum | undefined {
	switch (clientUserGeneratedContentFlagDetailStatusEnum) {
		case ClientUserGeneratedContentFlagCommentStatusEnum.Open:
			return ClientUserGeneratedContentFlagCommentStatusEnum.Open;
		case ClientUserGeneratedContentFlagCommentStatusEnum.InProgress:
			return ClientUserGeneratedContentFlagCommentStatusEnum.InProgress;
		case ClientUserGeneratedContentFlagCommentStatusEnum.Accepted:
			return ClientUserGeneratedContentFlagCommentStatusEnum.Accepted;
		case ClientUserGeneratedContentFlagCommentStatusEnum.Rejected:
			return ClientUserGeneratedContentFlagCommentStatusEnum.Rejected;
		default:
			return undefined;
	}
}

function getClientUserGeneratedContentFlagPostStatusEnum(
	clientUserGeneratedContentFlagDetailStatusEnum?: string
): ClientUserGeneratedContentFlagPostStatusEnum | undefined {
	switch (clientUserGeneratedContentFlagDetailStatusEnum) {
		case ClientUserGeneratedContentFlagPostStatusEnum.Open:
			return ClientUserGeneratedContentFlagPostStatusEnum.Open;
		case ClientUserGeneratedContentFlagPostStatusEnum.InProgress:
			return ClientUserGeneratedContentFlagPostStatusEnum.InProgress;
		case ClientUserGeneratedContentFlagPostStatusEnum.Accepted:
			return ClientUserGeneratedContentFlagPostStatusEnum.Accepted;
		case ClientUserGeneratedContentFlagPostStatusEnum.Rejected:
			return ClientUserGeneratedContentFlagPostStatusEnum.Rejected;
		default:
			return undefined;
	}
}

function getClientUserGeneratedContentFlagGroupStatusEnum(
	clientUserGeneratedContentFlagDetailStatusEnum?: string
): ClientUserGeneratedContentFlagGroupStatusEnum | undefined {
	switch (clientUserGeneratedContentFlagDetailStatusEnum) {
		case ClientUserGeneratedContentFlagPostStatusEnum.Open:
			return ClientUserGeneratedContentFlagGroupStatusEnum.Open;
		case ClientUserGeneratedContentFlagPostStatusEnum.InProgress:
			return ClientUserGeneratedContentFlagGroupStatusEnum.InProgress;
		case ClientUserGeneratedContentFlagPostStatusEnum.Accepted:
			return ClientUserGeneratedContentFlagGroupStatusEnum.Accepted;
		case ClientUserGeneratedContentFlagPostStatusEnum.Rejected:
			return ClientUserGeneratedContentFlagGroupStatusEnum.Rejected;
		default:
			return undefined;
	}
}

function changeFlagStatus(
	state: FlaggedContentState,
	flagId: string,
	clientUserGeneratedContentResponse: ClientUserGeneratedContentFlagDetail,
	newFlagSummary: FlagSummary
): FlaggedContentState {
	const commonDetailChanges: Partial<FlaggedContentState['detail']> = {
		statusRecords: clientUserGeneratedContentResponse.statusRecords ?? [],
		flagSummary: { ...newFlagSummary }
	};
	const commonDetailFlagChanges: Partial<
		| ClientUserGeneratedContentFlagChat
		| ClientUserGeneratedContentFlagComment
		| ClientUserGeneratedContentFlagPost
		| ClientUserGeneratedContentFlagDetail
	> = {
		statusRecords: clientUserGeneratedContentResponse.statusRecords,
		lastStatusUpdate: clientUserGeneratedContentResponse.lastStatusUpdate,
		lastStatusRecord: clientUserGeneratedContentResponse.lastStatusRecord
	};

	if (state.detail.chat && state.detail.chat.id === flagId) {
		return {
			...state,
			detail: {
				...state.detail,
				chat: {
					...state.detail.chat,
					...commonDetailFlagChanges,
					status: getClientUserGeneratedContentFlagChatStatusEnum(clientUserGeneratedContentResponse.status)
				},
				...commonDetailChanges
			}
		};
	} else if (state.detail.comment && state.detail.comment.id === flagId) {
		return {
			...state,
			detail: {
				...state.detail,
				comment: {
					...state.detail.comment,
					...commonDetailFlagChanges,
					status: getClientUserGeneratedContentFlagCommentStatusEnum(clientUserGeneratedContentResponse.status)
				},
				...commonDetailChanges
			}
		};
	} else if (state.detail.post && state.detail.post.id === flagId) {
		return {
			...state,
			detail: {
				...state.detail,
				post: {
					...state.detail.post,
					...commonDetailFlagChanges,
					status: getClientUserGeneratedContentFlagPostStatusEnum(clientUserGeneratedContentResponse.status)
				},
				...commonDetailChanges
			}
		};
	} else if (state.detail.group && state.detail.group.id === flagId) {
		return {
			...state,
			detail: {
				...state.detail,
				group: {
					...state.detail.group,
					...commonDetailFlagChanges,
					status: getClientUserGeneratedContentFlagGroupStatusEnum(clientUserGeneratedContentResponse.status)
				},
				...commonDetailChanges
			}
		};
	}
	return { ...state };
}

const acceptFlagAndDeleteContentRequest = (
	state: FlaggedContentState,
	action: AcceptFlagAndDeleteContentRequestAction
): FlaggedContentState => {
	return { ...state };
};

const acceptFlagAndDeleteContentSuccess = (
	state: FlaggedContentState,
	action: AcceptFlagAndDeleteContentSuccessAction
): FlaggedContentState => {
	return changeFlagStatus(state, action.flagId, action.clientUserGeneratedContentFlagDetail, action.flagSummary);
};

const acceptFlagAndDeleteContentFailure = (
	state: FlaggedContentState,
	action: AcceptFlagAndDeleteContentFailureAction
): FlaggedContentState => {
	return { ...state };
};

const changeFlagStatusRequest = (
	state: FlaggedContentState,
	action: ChangeFlagStatusRequestAction
): FlaggedContentState => {
	return { ...state };
};

const changeFlagStatusSuccess = (
	state: FlaggedContentState,
	action: ChangeFlagStatusSuccessAction
): FlaggedContentState => {
	return changeFlagStatus(state, action.flagId, action.clientUserGeneratedContentFlagDetail, action.flagSummary);
};

const changeFlagStatusFailure = (
	state: FlaggedContentState,
	action: ChangeFlagStatusFailureAction
): FlaggedContentState => {
	return { ...state };
};

const reducer = (state = initialFlaggedContentState, action: FlaggedContentActionTypes): FlaggedContentState => {
	switch (action.type) {
		case GET_FLAGGED_CONTENTS_REQUEST:
			return getFlaggedContentsRequest(state, action);
		case GET_FLAGGED_CONTENTS_SUCCESS:
			return getFlaggedContentsSuccess(state, action);
		case GET_FLAGGED_CONTENTS_FAILURE:
			return getFlaggedContentsFailure(state, action);
		case GET_FLAGGED_CONTENT_REQUEST:
			return getFlaggedContentRequest(state, action);
		case GET_FLAGGED_CONTENT_SUCCESS:
			return getFlaggedContentSuccess(state, action);
		case GET_FLAGGED_CONTENT_FAILURE:
			return getFlaggedContentFailure(state, action);
		case GET_CHAT_FLAG_REQUEST:
			return getChatFlagRequest(state, action);
		case GET_CHAT_FLAG_SUCCESS:
			return getChatFlagSuccess(state, action);
		case GET_CHAT_FLAG_FAILURE:
			return getChatFlagFailure(state, action);
		case GET_COMMENT_FLAG_REQUEST:
			return getCommentFlagRequest(state, action);
		case GET_COMMENT_FLAG_SUCCESS:
			return getCommentFlagSuccess(state, action);
		case GET_COMMENT_FLAG_FAILURE:
			return getCommentFlagFailure(state, action);
		case GET_POST_FLAG_REQUEST:
			return getPostFlagRequest(state, action);
		case GET_POST_FLAG_SUCCESS:
			return getPostFlagSuccess(state, action);
		case GET_POST_FLAG_FAILURE:
			return getPostFlagFailure(state, action);
		case GET_GROUP_FLAG_REQUEST:
			return getFlaggedGroupRequest(state, action);
		case GET_GROUP_FLAG_SUCCESS:
			return getFlaggedGroupSuccess(state, action);
		case GET_GROUP_FLAG_FAILURE:
			return getFlaggedGroupFailure(state, action);
		case ACCEPT_FLAG_AND_DELETE_CONTENT_REQUEST:
			return acceptFlagAndDeleteContentRequest(state, action);
		case ACCEPT_FLAG_AND_DELETE_CONTENT_SUCCESS:
			return acceptFlagAndDeleteContentSuccess(state, action);
		case ACCEPT_FLAG_AND_DELETE_CONTENT_FAILURE:
			return acceptFlagAndDeleteContentFailure(state, action);
		case CHANGE_FLAG_STATUS_REQUEST:
			return changeFlagStatusRequest(state, action);
		case CHANGE_FLAG_STATUS_SUCCESS:
			return changeFlagStatusSuccess(state, action);
		case CHANGE_FLAG_STATUS_FAILURE:
			return changeFlagStatusFailure(state, action);
		default:
			return state;
	}
};

export default reducer;
