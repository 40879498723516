import React, { Component } from 'react';
import { Grid } from '@mui/material';
import { ClientUserGeneratedContentFlagStatusRecord } from '@DigitaleDoerfer/digitale-doerfer-api';
import FlaggedContentStatusHistoryView from './shared/FlaggedContentStatusHistory.view';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import FlaggedContentDetailsReasonView from './shared/FlaggedContentDetailsReason.view';
import FlaggedContentDetailsStatusView from './shared/FlaggedContentDetailsStatus.view';
import { commonStyles, FlagSummary } from './shared/FlaggedContentDetailsCommons';

interface Props extends WithStyles<typeof commonStyles> {
	flagSummary: FlagSummary;
	statusRecords: ClientUserGeneratedContentFlagStatusRecord[];
}

class FlaggedOtherView extends Component<Props> {
	renderFlaggedEntityInformation(): JSX.Element {
		const { flagSummary } = this.props;

		return (
			<Grid container spacing={3}>
				<FlaggedContentDetailsReasonView reason={flagSummary.reason} />
				<FlaggedContentDetailsStatusView status={flagSummary.status} lastStatusUpdate={flagSummary.lastStatusUpdate} />
			</Grid>
		);
	}

	render(): JSX.Element {
		const { flagSummary, statusRecords } = this.props;
		return (
			<>
				{this.renderFlaggedEntityInformation()}
				<FlaggedContentStatusHistoryView flagSummary={flagSummary} statusRecords={statusRecords} />
			</>
		);
	}
}

export default withStyles(commonStyles)(FlaggedOtherView);
