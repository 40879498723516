import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import { sharedAdminuiAppApi } from '../../../ServiceFactory';
import { getCurrentPage, getNextPage } from '../helpers';
import { useStore } from '../store';
import { ListType, Tenant } from '../types';

const useFetchTenants = (appVariantIds: string[], search?: string): UseInfiniteQueryResult<Tenant[]> => {
	const { setCurrentListPage, currentTenantsPage } = useStore();
	return useInfiniteQuery<Tenant[]>(
		[ListType.TENANT, { appVariantIds, search }],
		({ pageParam = 0 }) => {
			return sharedAdminuiAppApi()
				.getTenantsByContractOrAppVariant({ count: 50, appVariantIds, page: pageParam, search })
				.then(response => response)
				.then(data => {
					const { content, page } = data;
					const { totalPages, number: responsePage } = page || {};
					const processedItems = content?.map(item => item as Tenant) || [];
					const currentPage = getCurrentPage(responsePage, totalPages);
					setCurrentListPage(ListType.TENANT, currentPage);
					return processedItems;
				});
		},
		{
			getNextPageParam: () => getNextPage(currentTenantsPage)
		}
	);
};

export default useFetchTenants;
