import React from 'react';
import { Grid, Paper, Tooltip } from '@mui/material';
import BarLoadingIndicator from '../../shared/views/LoadingIndicators/BarLoadingIndicator.view';
import TablePaginationView from '../../shared/views/Table/TablePagination.view';
import TableView from '../../shared/views/Table/Table.view';
import { TableCell, TableColumn } from '../../shared/views/Table/TableColumn';
import { TableColumnSortingDirection, TableSorting } from '../../shared/views/Table/TableSorting';
import { Pagination } from '../../shared/services/InMemoryTable.service';
import { inMemoryTableService } from '../../ServiceFactory';
import { arraysEqual } from '../../shared/utils/Util';
import { ClientTenantAndAppVariantEntry } from '@DigitaleDoerfer/digitale-doerfer-api';
import SearchBarWithResetButtonView from '../../shared/views/SearchBarWithResetButton/SearchBarWithResetButton.view';
import { searchEventsConfigsTooltipText } from '../../shared/views/HelperTexts';

interface Props {
	appConfigs: ClientTenantAndAppVariantEntry[];
	loading: boolean;
}

const renderText = (cell: TableCell<ClientTenantAndAppVariantEntry>): React.ReactElement => (
	<span>{cell.column.getCellStringValue(cell.row)}</span>
);

const renderTenant = (cell: TableCell<ClientTenantAndAppVariantEntry>): React.ReactElement => {
	const { row } = cell;

	return (
		<Tooltip title={row.tenantId} placement="bottom-start">
			{renderText(cell)}
		</Tooltip>
	);
};

const columns: readonly TableColumn<ClientTenantAndAppVariantEntry>[] = [
	{
		id: 'tenant',
		headerLabel: 'Mandant',
		cellStyles: {
			maxWidth: 300
		},
		getCellStringValue: (appConfig: ClientTenantAndAppVariantEntry): string => appConfig.tenantName,
		renderCell: renderTenant,
		filterable: true,
		sortable: true
	},
	{
		id: 'app',
		headerLabel: 'App',
		cellStyles: {},
		getCellStringValue: (appConfig: ClientTenantAndAppVariantEntry): string => appConfig.appName,
		renderCell: renderText,
		filterable: true,
		sortable: true
	},
	{
		id: 'appIdentifier',
		headerLabel: 'Kennung',
		cellStyles: {},
		getCellStringValue: (appConfig: ClientTenantAndAppVariantEntry): string => appConfig.appIdentifier,
		renderCell: renderText,
		filterable: true,
		sortable: true
	},
	{
		id: 'appVariant',
		headerLabel: 'Variante',
		cellStyles: {},
		getCellStringValue: (appConfig: ClientTenantAndAppVariantEntry): string => appConfig.appVariantName,
		renderCell: renderText,
		filterable: true,
		sortable: true
	},
	{
		id: 'appVariantIdentifier',
		headerLabel: 'App-Varianten-Kennung',
		cellStyles: {},
		getCellStringValue: (appConfig: ClientTenantAndAppVariantEntry): string => appConfig.appVariantIdentifier,
		renderCell: renderText,
		filterable: true,
		sortable: true
	}
] as const;

function idToColumn(columnId: string): TableColumn<ClientTenantAndAppVariantEntry> | undefined {
	return columns.find(column => column.id === columnId);
}

const paginationRowsPerPageOptions = [20, 50, 100, 200] as const;
const initialPaginationRowsPerPage: (typeof paginationRowsPerPageOptions)[number] = 100;

const initialSearchParams = {
	searchCriteria: '',
	pagination: { page: 0, rowsPerPage: initialPaginationRowsPerPage, total: 0 },
	sorting: { columnId: columns[0].id, direction: TableColumnSortingDirection.ASC }
};

interface State {
	displayedAppConfigs: ClientTenantAndAppVariantEntry[];
	searchCriteria: string;
	pagination: Pagination;
	sorting: TableSorting;
}

class AppConfigListView extends React.Component<Props, State> {
	searchNeedsUpdate = false;

	constructor(props: Props) {
		super(props);

		this.state = {
			displayedAppConfigs: [],
			...initialSearchParams
		};
		this.updateDisplayedRowsAndPagination = this.updateDisplayedRowsAndPagination.bind(this);
		this.handleChangePage = this.handleChangePage.bind(this);
		this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
		this.handleSortingChange = this.handleSortingChange.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.handleSearchReset = this.handleSearchReset.bind(this);
	}

	updateDisplayedRowsAndPagination(): void {
		const appConfigsAfterFiltering = inMemoryTableService().applyFilter(
			this.props.appConfigs,
			this.state.searchCriteria,
			columns
		);

		const appConfigsAfterSorting = inMemoryTableService().applySorting(
			appConfigsAfterFiltering,
			this.state.sorting,
			idToColumn
		);
		const appConfigsAfterPagination = inMemoryTableService().applyPagination(
			appConfigsAfterSorting,
			this.state.pagination
		);

		this.setState({
			pagination: { ...this.state.pagination, total: appConfigsAfterSorting.length },
			displayedAppConfigs: appConfigsAfterPagination
		});
	}

	componentDidMount(): void {
		this.updateDisplayedRowsAndPagination();
	}

	componentDidUpdate(prevProps: Props): void {
		if (!arraysEqual(prevProps.appConfigs, this.props.appConfigs)) {
			this.updateDisplayedRowsAndPagination();
		}
	}

	handleChangePage(newPage: number): void {
		this.setState((state: State): State => {
			return { ...state, pagination: { ...state.pagination, page: newPage } };
		}, this.updateDisplayedRowsAndPagination);
	}

	handleChangeRowsPerPage(newRowsPerPage: number): void {
		this.setState((state: State): State => {
			return { ...state, pagination: { ...state.pagination, rowsPerPage: newRowsPerPage, page: 0 } };
		}, this.updateDisplayedRowsAndPagination);
	}

	handleSortingChange(newSorting: TableSorting): void {
		this.setState((state: State): State => {
			return {
				...state,
				pagination: { ...state.pagination, page: 0 },
				sorting: newSorting
			};
		}, this.updateDisplayedRowsAndPagination);
	}

	handleSearchChange(searchCriteria: string): void {
		this.setState(
			{ ...this.state, searchCriteria, pagination: { ...this.state.pagination, page: 0 } },
			this.updateDisplayedRowsAndPagination
		);
	}

	handleSearchReset(): void {
		this.setState((state: State): State => {
			return {
				...state,
				...initialSearchParams
			};
		}, this.updateDisplayedRowsAndPagination);
	}

	getKeyForRow(appConfig: ClientTenantAndAppVariantEntry): React.Key {
		const { appId, appVariantId, tenantId } = appConfig;
		return `${tenantId} ${appId} ${appVariantId}`;
	}

	render(): JSX.Element {
		const { loading } = this.props;
		const { displayedAppConfigs, pagination, searchCriteria, sorting } = this.state;
		const { page, rowsPerPage, total } = pagination;

		return (
			<Paper>
				<BarLoadingIndicator loading={loading} />
				<Grid container spacing={2}>
					<SearchBarWithResetButtonView
						text={searchCriteria}
						label="App-Konfiguration suchen"
						placeholder="z.B. LieferBar Aalen"
						tooltipText={searchEventsConfigsTooltipText}
						handleTextChange={this.handleSearchChange}
						handleReset={this.handleSearchReset}
					/>
					<Grid item xs={12}>
						<TableView<ClientTenantAndAppVariantEntry>
							columns={columns}
							rows={displayedAppConfigs}
							getKeyForRow={this.getKeyForRow}
							sorting={sorting}
							handleSortingChange={this.handleSortingChange}
						/>
						<TablePaginationView
							totalRows={total}
							rowLabelPlural="App-Konfigurationen"
							page={page}
							rowsPerPage={rowsPerPage}
							rowsPerPageOptions={paginationRowsPerPageOptions}
							handleChangePage={this.handleChangePage}
							handleChangeRowsPerPage={this.handleChangeRowsPerPage}
						/>
					</Grid>
				</Grid>
			</Paper>
		);
	}
}

export default AppConfigListView;
