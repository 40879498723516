import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ClientGeoAreaExtended, ClientGroupExtendedDetail } from '@DigitaleDoerfer/digitale-doerfer-api';
import ChangeGroupGeoAreasActionView from './ChangeGroupGeoAreasAction.view';
import {
	showSnackbar,
	SnackbarAction
} from '../../../../shared/views/SnackbarNotification/store/SnackbarNotification.actions';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import { RoleKey, RoleManagementPermission } from '../../../../shared/services/Role.service';
import { roleService } from '../../../../ServiceFactory';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../../store/store';
import { UserActionTypes } from '../../../users/store/UserActionTypes';
import { groupModifyGeoAreasByAdmin } from '../../store/Group.actions';

interface Props extends ActionMenuItemProps {
	group: ClientGroupExtendedDetail | null;
	geoAreas: ClientGeoAreaExtended[];
	roleManagementPermission: RoleManagementPermission;
	showSnackbar: (snackbarAction: SnackbarAction) => void;
	groupModifyGeoAreasByAdmin: (groupId: string, newGeoAreaIds: string[]) => void;
}

class ChangeGroupGeoAreasActionContainer extends Component<Props> {
	constructor(props: Props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	render(): JSX.Element | null {
		const { renderMenuItem, group, roleManagementPermission, geoAreas } = this.props;
		if (!group) {
			return null;
		}

		if (
			!roleService().hasClientRoleAssignmentSomeValidRole(roleManagementPermission.roles, [RoleKey.GLOBAL_GROUP_ADMIN])
		) {
			return null;
		}

		return (
			<ChangeGroupGeoAreasActionView
				renderMenuItem={renderMenuItem}
				group={group}
				geoAreas={geoAreas}
				handleSubmit={this.handleSubmit}
			/>
		);
	}

	handleSubmit(groupId: string, newGeoAreaIds: string[]): void {
		const { groupModifyGeoAreasByAdmin } = this.props;
		groupModifyGeoAreasByAdmin(groupId, newGeoAreaIds);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		group: state.group.detail.group,
		geoAreas: state.global.geoAreasData.geoAreas,
		roleManagementPermission: state.global.roleManagementPermission
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<UserActionTypes>) => {
	return {
		showSnackbar: (snackbarAction: SnackbarAction): Promise<void> => dispatch(showSnackbar(snackbarAction)),
		groupModifyGeoAreasByAdmin: (groupId: string, newGeoAreaIds: string[]): Promise<void> =>
			dispatch(groupModifyGeoAreasByAdmin(groupId, newGeoAreaIds))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeGroupGeoAreasActionContainer);
