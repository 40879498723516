import { ClientNewsSource } from '@DigitaleDoerfer/digitale-doerfer-api';
import { BaseError } from '../../../shared/errors/Errors';

export interface NewsSourcseState {
	list: {
		clientNewsSource: ClientNewsSource[];
		loading: boolean;
		error: BaseError | null;
	};
	create: {
		newsSourceHasBeenCreated: boolean;
		loading: boolean;
		error: BaseError | null;
	};
}

export const initialNewsSourcseState: NewsSourcseState = {
	list: {
		clientNewsSource: [],
		loading: false,
		error: null
	},
	create: {
		newsSourceHasBeenCreated: false,
		loading: false,
		error: null
	}
};
