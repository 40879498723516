import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../store/store';
import GeoAreasListView from './GeoAreasList.view';
import { RouteComponentProps } from 'react-router-dom';
import { ClientCommunity, ClientGeoAreaExtended } from '@DigitaleDoerfer/digitale-doerfer-api';
import { getGeoAreas } from '../../store/Global.actions';
import { GlobalActionTypes } from '../../store/GlobalActionTypes';

interface GeoAreaRouteParams {
	searchText?: string;
}

interface Props extends RouteComponentProps<GeoAreaRouteParams> {
	geoAreas: ClientGeoAreaExtended[];
	loading: boolean;
	tenants: ClientCommunity[];
	getGeoAreas: () => void;
}

class GeoAreasListContainer extends Component<Props> {
	componentDidMount(): void {
		const { geoAreas, getGeoAreas } = this.props;
		if (geoAreas.length === 0) {
			getGeoAreas();
		}
	}

	render(): JSX.Element {
		const { geoAreas, loading, getGeoAreas, tenants, match } = this.props;
		const initialFilterText = match.params.searchText;
		return (
			<GeoAreasListView
				tenants={tenants}
				geoAreas={geoAreas}
				loading={loading}
				getGeoAreas={getGeoAreas}
				initialFilterText={initialFilterText}
			/>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		loading: state.global.geoAreasData.loading,
		geoAreas: state.global.geoAreasData.geoAreas,
		tenants: state.global.tenants
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<GlobalActionTypes>) => {
	return {
		getGeoAreas: (): Promise<void> => dispatch(getGeoAreas())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GeoAreasListContainer);
