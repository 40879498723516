import { ClientTenantAndAppVariantEntry } from '@DigitaleDoerfer/digitale-doerfer-api';
import { BaseError } from '../../../shared/errors/Errors';

export interface AppConfigState {
	list: {
		appConfigs: ClientTenantAndAppVariantEntry[];
		loading: boolean;
		error: BaseError | null;
	};
}

export const initialAppConfigState: AppConfigState = {
	list: {
		appConfigs: [],
		loading: false,
		error: null
	}
};
