import { BaseError } from '../shared/errors/Errors';
import {
	ClientCommunity,
	ClientGeoArea,
	ClientGeoAreaExtended,
	ClientPersonOwn,
	ClientRoleInformation
} from '@DigitaleDoerfer/digitale-doerfer-api';
import { noPermission, RoleManagementPermission } from '../shared/services/Role.service';

export interface GeoAreaData {
	geoArea: ClientGeoArea | null;
	loading: boolean;
	error: BaseError | null;
}

export interface GeoAreasData {
	geoAreas: ClientGeoAreaExtended[];
	loading: boolean;
	error: BaseError | null;
}

export interface GlobalState {
	error: BaseError | null;
	loading: boolean;
	tenants: ClientCommunity[];
	allAvailableRoles: ClientRoleInformation[];
	assignableRoles: ClientRoleInformation[];
	roleManagementPermission: RoleManagementPermission;
	person: ClientPersonOwn | null;
	geoAreaData: GeoAreaData;
	geoAreasData: GeoAreasData;
}

export const initialGlobalState: GlobalState = {
	error: null,
	loading: true,
	tenants: [],
	allAvailableRoles: [],
	assignableRoles: [],
	roleManagementPermission: noPermission,
	person: null,
	geoAreaData: { geoArea: null, loading: false, error: null },
	geoAreasData: { geoAreas: [], loading: false, error: null }
};
