import clsx from 'clsx';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { ClientGeoArea } from '@DigitaleDoerfer/digitale-doerfer-api';
import { TreeItem, TreeItemProps } from '@mui/lab';
import { alpha, CircularProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { blue, lightBlue, red } from '@mui/material/colors';
import NodeIcon from '@mui/icons-material/HouseOutlined';
import useScroll from '../hooks/useScroll';
import { useStore } from '../store';
import { GeoArea } from '../types';
import useFetchGeoAreas from './hooks/useFetchGeoAreas';
import useGeoArea from './hooks/useGeoArea';
import TreeNodeLabel from './TreeNodeLabel';

const StyledTreeItem = withStyles(theme => ({
	root: {
		'&.included': {
			backgroundColor: lightBlue[50]
		},
		'&.excluded': {
			backgroundColor: red[50]
		},
		'&.highlighted': {
			backgroundColor: blue[200]
		}
	},
	label: {
		fontSize: '13px',
		'&:hover': {
			backgroundColor: lightBlue[100]
		}
	},
	iconContainer: {
		'& .close': {
			opacity: 0.3
		}
	},
	group: {
		marginLeft: 7,
		paddingLeft: 18,
		borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`
	}
}))(
	React.forwardRef(function styledTreeItem(props: TreeItemProps, ref) {
		return <TreeItem {...props} ref={ref} />;
	})
);

type TreeNodeProps = {
	geoArea: GeoArea;
	selectedGeoAreaId: ClientGeoArea['id'] | null;
	onNodeLabelClick: (geoAreaId: ClientGeoArea['id']) => void;
	onNodeExpandClick: (geoAreaId: ClientGeoArea['id']) => void;
};
const TreeNode: FC<TreeNodeProps> = ({
	geoArea,
	selectedGeoAreaId,
	onNodeLabelClick,
	onNodeExpandClick
}: TreeNodeProps) => {
	const { getGeoAreasByParentId, childrenIds, isIncluded, isExcluded } = useGeoArea(geoArea.id);
	const highlightedGeoAreaIds = useStore(store => store.highlightedGeoAreaIds);
	const isHighlighted = useMemo<boolean>(
		() => highlightedGeoAreaIds.includes(geoArea.id),
		[geoArea, highlightedGeoAreaIds]
	);
	const [executeScroll, elRef] = useScroll<HTMLDivElement>(true);

	useEffect(() => {
		if (isHighlighted && executeScroll) {
			executeScroll();
		}
	}, [isHighlighted, executeScroll]);

	const children = getGeoAreasByParentId(geoArea.id);
	const hasChildren = children?.length > 0;

	const shouldFetchChildrenOfChildren = hasChildren && selectedGeoAreaId === geoArea.id;

	const { isLoading } = useFetchGeoAreas(geoArea.id, childrenIds, shouldFetchChildrenOfChildren);

	const childNodes = useMemo(() => {
		if (!hasChildren) {
			return [];
		}

		if (hasChildren && children) {
			return Object.values(children).map(childNode => {
				return (
					<TreeNode
						key={childNode.id}
						geoArea={childNode}
						onNodeLabelClick={onNodeLabelClick}
						onNodeExpandClick={onNodeExpandClick}
						selectedGeoAreaId={selectedGeoAreaId}
					/>
				);
			});
		}
	}, [hasChildren, children, selectedGeoAreaId, onNodeLabelClick, onNodeExpandClick]);

	const handleLabelClick = useCallback(
		(geoAreaId: string) => (): void => {
			onNodeLabelClick(geoAreaId);
		},
		[onNodeLabelClick]
	);

	const handleExpandClick = useCallback(
		(geoAreaId: string) => (): void => {
			onNodeExpandClick(geoAreaId);
		},
		[onNodeExpandClick]
	);

	const icon = useMemo(() => {
		if (isLoading) {
			return <CircularProgress size={10} color={'primary'} />;
		}

		if (geoArea.leaf) {
			return <NodeIcon />;
		}
		return null;
	}, [isLoading, geoArea.leaf]);

	return (
		<StyledTreeItem
			nodeId={geoArea.id}
			label={
				<TreeNodeLabel
					geoAreaId={geoArea.id}
					geoAreaName={geoArea.name}
					toggleIsChecked={false}
					showToggle={false}
					onClick={handleLabelClick(geoArea.id)}
				/>
			}
			onClick={handleExpandClick(geoArea.id)}
			icon={icon}
			className={clsx(
				isIncluded ? 'included' : isExcluded ? 'excluded' : 'forgotten',
				isHighlighted ? ' highlighted' : ''
			)}
			ref={elRef}
		>
			{childNodes}
		</StyledTreeItem>
	);
};

export default TreeNode;
