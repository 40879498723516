import {
	ClientAppVariantTenantContract,
	ClientAppVariantTenantContractChangeAdditionalNotesRequest,
	ClientAppVariantTenantContractChangeAdditionalNotesResponse
} from '@DigitaleDoerfer/digitale-doerfer-api';
import { sharedAdminuiAppApi } from '../../../ServiceFactory';

export const changeAdditionalNotes = async (
	changeAdditionalNotesRequestParams: ClientAppVariantTenantContractChangeAdditionalNotesRequest
): Promise<ClientAppVariantTenantContract | null> => {
	try {
		const response: ClientAppVariantTenantContractChangeAdditionalNotesResponse =
			await sharedAdminuiAppApi().onChangeAdditionalNotesRequest({
				clientAppVariantTenantContractChangeAdditionalNotesRequest: changeAdditionalNotesRequestParams
			});
		return response.updatedAppVariantTenantContract;
	} catch (error) {
		console.log(error);
		return null;
	}
};
