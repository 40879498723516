import React from 'react';
import { ClientPersonExtended, ClientPersonOwn } from '@DigitaleDoerfer/digitale-doerfer-api';
import { Grid, Link } from '@mui/material';
import { ReadOnlyTextFieldView } from '../ReadOnlyTextField.view';

interface Props {
	person: ClientPersonOwn | ClientPersonExtended;
	showMoreDetails: boolean;
	handleMoreDetailsLink: () => void;
}

function ExpandablePersonDetailFormView(props: Props): JSX.Element | null {
	const { person, handleMoreDetailsLink, showMoreDetails } = props;
	const street = person.address && person.address.street ? person.address.street : '';
	const { city, zip } = person.address ? person.address : { city: '', zip: '' };

	let zipAndCity = '';
	if (zip && city) {
		zipAndCity = `${zip} ${city}`;
	} else if (zip) {
		zipAndCity = zip;
	} else if (city) {
		zipAndCity = city;
	}

	if (showMoreDetails) {
		return (
			<>
				<Grid item xs={12} md={6}>
					<ReadOnlyTextFieldView
						id="zipAndCity"
						name="zipAndCity"
						label="Wohnort"
						value={zipAndCity}
						InputProps={{ readOnly: true }}
						InputLabelProps={{
							shrink: true
						}}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<ReadOnlyTextFieldView
						id="street"
						name="street"
						label="Straße"
						value={street || ''}
						InputProps={{ readOnly: true }}
						InputLabelProps={{
							shrink: true
						}}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<ReadOnlyTextFieldView
						id="phoneNumber"
						name="phoneNumber"
						label="Telefonnummer"
						value={person.phoneNumber || ''}
						InputProps={{ readOnly: true }}
						InputLabelProps={{
							shrink: true
						}}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<ReadOnlyTextFieldView
						id="nickName"
						name="nickName"
						label="Alias"
						value={person.nickName || ''}
						InputProps={{ readOnly: true }}
						InputLabelProps={{
							shrink: true
						}}
						fullWidth
					/>
				</Grid>
			</>
		);
	} else {
		if (zipAndCity || street || person.nickName || person.phoneNumber) {
			// Render mehr link
			return (
				<Grid item xs={12} hidden={showMoreDetails}>
					<Link href="#" onClick={handleMoreDetailsLink}>
						mehr
					</Link>
				</Grid>
			);
		}
	}

	return <></>;
}

export default ExpandablePersonDetailFormView;
