/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	ClientGroupChangeVisibilityAndAccessibilityByAdminRequestGroupAccessibilityEnum,
	ClientGroupChangeVisibilityAndAccessibilityByAdminRequestGroupContentVisibilityEnum,
	ClientGroupChangeVisibilityAndAccessibilityByAdminRequestGroupVisibilityEnum,
	ClientGroupExtendedDetail,
	ClientGroupExtendedDetailAccessibilityEnum,
	ClientGroupExtendedDetailContentVisibilityEnum,
	ClientGroupExtendedDetailVisibilityEnum
} from '@DigitaleDoerfer/digitale-doerfer-api';
import ChangeGroupAccessibilityActionView from './ChangeGroupAccessibilityAction.view';
import {
	showSnackbar,
	SnackbarAction,
	SnackbarType
} from '../../../../shared/views/SnackbarNotification/store/SnackbarNotification.actions';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import { RoleKey, RoleManagementPermission } from '../../../../shared/services/Role.service';
import { roleService } from '../../../../ServiceFactory';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../../store/store';
import { groupChangeAccessibilty } from '../../store/Group.actions';
import { GroupActionTypes } from '../../store/GroupActionTypes';

interface Props extends ActionMenuItemProps {
	group: ClientGroupExtendedDetail | null;
	roleManagementPermission: RoleManagementPermission;
	showSnackbar: (snackbarAction: SnackbarAction) => void;
	changeAccessibility: (
		groupId: string,
		groupAccessibility: ClientGroupChangeVisibilityAndAccessibilityByAdminRequestGroupAccessibilityEnum,
		groupContentVisibility: ClientGroupChangeVisibilityAndAccessibilityByAdminRequestGroupContentVisibilityEnum,
		groupVisibility: ClientGroupChangeVisibilityAndAccessibilityByAdminRequestGroupVisibilityEnum
	) => void;
}

class ChangeGroupAccessibilityActionContainer extends Component<Props> {
	constructor(props: Props) {
		super(props);
		this.handleAccessibilityChange = this.handleAccessibilityChange.bind(this);
	}

	handleAccessibilityChange(
		newGroupAccessibility: ClientGroupChangeVisibilityAndAccessibilityByAdminRequestGroupAccessibilityEnum
	): void {
		const { group, showSnackbar, changeAccessibility } = this.props;
		const groupID = group?.id;
		const groupAccessibility = group?.accessibility;
		const groupContentVisibility = group?.contentVisibility;
		const groupVisibility = group?.visibility;

		if (
			groupID !== undefined &&
			groupAccessibility !== undefined &&
			groupContentVisibility !== undefined &&
			groupVisibility !== undefined
		) {
			changeAccessibility(
				groupID,
				newGroupAccessibility,
				this.convertfromExtendedDetailtoGroupContentVisibilityEnum(groupContentVisibility),
				this.convertfromExtendedDetailtoGroupVisibilityEnum(groupVisibility)
			);
		} else {
			showSnackbar({
				type: SnackbarType.SNACKBAR_ERROR,
				message: 'Die Gruppe hat keine ID.'
			});
		}
	}

	convertfromExtendedDetailtoGroupAccessibilityEnum(
		groupAccessibility: ClientGroupExtendedDetailAccessibilityEnum
	): any {
		const { Public, ApprovalRequired } =
			ClientGroupChangeVisibilityAndAccessibilityByAdminRequestGroupAccessibilityEnum;
		if (groupAccessibility === ClientGroupExtendedDetailAccessibilityEnum.Public) {
			return Public;
		} else if (groupAccessibility === ClientGroupExtendedDetailAccessibilityEnum.ApprovalRequired) {
			return ApprovalRequired;
		}
	}

	convertfromExtendedDetailtoGroupContentVisibilityEnum(
		groupContentVisibility: ClientGroupExtendedDetailContentVisibilityEnum
	): any {
		const { Anyone, Members, SameHomeArea } =
			ClientGroupChangeVisibilityAndAccessibilityByAdminRequestGroupContentVisibilityEnum;
		if (groupContentVisibility === ClientGroupExtendedDetailContentVisibilityEnum.Anyone) {
			return Anyone;
		} else if (groupContentVisibility === ClientGroupExtendedDetailContentVisibilityEnum.Members) {
			return Members;
		} else if (groupContentVisibility === ClientGroupExtendedDetailContentVisibilityEnum.SameHomeArea) {
			return SameHomeArea;
		}
	}

	convertfromExtendedDetailtoGroupVisibilityEnum(groupVisibility: ClientGroupExtendedDetailVisibilityEnum): any {
		const { Anyone, Members, SameHomeArea } =
			ClientGroupChangeVisibilityAndAccessibilityByAdminRequestGroupContentVisibilityEnum;
		if (groupVisibility === ClientGroupExtendedDetailVisibilityEnum.Anyone) {
			return Anyone;
		} else if (groupVisibility === ClientGroupExtendedDetailVisibilityEnum.Members) {
			return Members;
		} else if (groupVisibility === ClientGroupExtendedDetailVisibilityEnum.SameHomeArea) {
			return SameHomeArea;
		}
	}

	render(): JSX.Element | null {
		const { renderMenuItem, group, roleManagementPermission } = this.props;
		if (!group) {
			return null;
		}

		if (
			!roleService().hasClientRoleAssignmentSomeValidRole(roleManagementPermission.roles, [RoleKey.GLOBAL_GROUP_ADMIN])
		) {
			return null;
		}

		return (
			<ChangeGroupAccessibilityActionView
				renderMenuItem={renderMenuItem}
				fromPropsGroupAccessibility={this.convertfromExtendedDetailtoGroupAccessibilityEnum(group?.accessibility)}
				handleAccessibilityChange={this.handleAccessibilityChange}
			/>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		group: state.group.detail.group,
		roleManagementPermission: state.global.roleManagementPermission
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<GroupActionTypes>) => {
	return {
		showSnackbar: (snackbarAction: SnackbarAction): Promise<void> => dispatch(showSnackbar(snackbarAction)),
		changeAccessibility: (
			groupId: string,
			groupAccessibility: ClientGroupChangeVisibilityAndAccessibilityByAdminRequestGroupAccessibilityEnum,
			groupContentVisibility: ClientGroupChangeVisibilityAndAccessibilityByAdminRequestGroupContentVisibilityEnum,
			groupVisibility: ClientGroupChangeVisibilityAndAccessibilityByAdminRequestGroupVisibilityEnum
		): Promise<void> =>
			dispatch(groupChangeAccessibilty({ groupId, groupAccessibility, groupContentVisibility, groupVisibility }))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeGroupAccessibilityActionContainer);
