import { QueryObserverResult, useQuery } from 'react-query';
import { participantsAdminuiGeoareaApi } from '../../../../ServiceFactory';
import { useStore } from '../../store';
import { GeoArea } from '../../types';

const useFetchGeoAreas = (
	parentGeoAreaId: string,
	childrenIds: string[],
	shouldFetch: boolean,
	includePath = false
): QueryObserverResult<GeoArea[]> => {
	const { addToChildren } = useStore();
	return useQuery(
		['geoAreaChildren', { parentGeoAreaId }],
		() =>
			participantsAdminuiGeoareaApi().getGeoAreaWithChildren({
				depth: 1,
				geoAreaIds: childrenIds?.length > 0 ? childrenIds : undefined,
				revealVisibleGeoAreas: includePath
			}),
		{
			refetchOnWindowFocus: false,
			staleTime: Infinity,
			enabled: shouldFetch,
			onSuccess: (data: GeoArea[]) => {
				addToChildren(data, childrenIds);
			}
		}
	);
};

export default useFetchGeoAreas;
