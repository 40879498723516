import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../store/store';
import NewsSourcesCreateView from './NewsSourcesCreate.view';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Paper } from '@mui/material';
import { createNewsSources } from '../store/NewsSources.actions';
import { ClientNewsSourceCreateRequest, ClientRoleInformation } from '@DigitaleDoerfer/digitale-doerfer-api';
import { BaseError } from '../../../shared/errors/Errors';
import { NewsSourcesActionTypes } from '../store/NewsSourcesActionTypes';
import { roleService } from '../../../ServiceFactory';
import { RoleKey } from '../../../shared/services/Role.service';
import MainLoadingIndicator from '../../../shared/views/LoadingIndicators/MainLoadingIndicator.view';
import { NEWS_SOURCES_URL } from '../NewsSourcesRouting.container';

interface Props extends RouteComponentProps {
	loading: boolean;
	error: BaseError | null;
	newsSourceHasBeenCreated: boolean;
	createNewsSources: (newsSourcesRequestData: ClientNewsSourceCreateRequest) => void;
	assignableRoles: ClientRoleInformation[];
}

class NewsSourcesCreateContainer extends Component<Props> {
	constructor(props: Props) {
		super(props);
		this.dispatchCreateNewsSource = this.dispatchCreateNewsSource.bind(this);
	}

	dispatchCreateNewsSource(newsSourcesRequestData: ClientNewsSourceCreateRequest): void {
		const { createNewsSources } = this.props;
		createNewsSources(newsSourcesRequestData);
	}

	cancelHandler = (): void => {
		this.props.history.push(NEWS_SOURCES_URL);
	};

	render(): JSX.Element {
		const { loading, assignableRoles, newsSourceHasBeenCreated } = this.props;
		const validRoles: RoleKey[] = [RoleKey.SUPER_ADMIN, RoleKey.USER_ADMIN, RoleKey.GLOBAL_USER_ADMIN];

		if (!roleService().hasClientRoleInformationSomeValidRole(assignableRoles, validRoles)) {
			return <Redirect to="/permission-error" />;
		}

		return (
			<>
				<MainLoadingIndicator loading={loading} />
				<Paper hidden={loading}>
					<NewsSourcesCreateView
						loading={loading}
						dispatchCreateNewsSource={this.dispatchCreateNewsSource}
						newsSourceHasBeenCreated={newsSourceHasBeenCreated}
						cancelHandler={this.cancelHandler}
					/>
				</Paper>
			</>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		loading: state.newsSource.create.loading,
		error: state.newsSource.create.error,
		newsSourceHasBeenCreated: state.newsSource.create.newsSourceHasBeenCreated,
		assignableRoles: state.global.assignableRoles
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<NewsSourcesActionTypes>) => {
	return {
		createNewsSources: (newsSourcesRequestData: ClientNewsSourceCreateRequest): Promise<void> =>
			dispatch(createNewsSources(newsSourcesRequestData))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsSourcesCreateContainer);
