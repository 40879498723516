import React from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { imageService } from '../../ServiceFactory';
import { ClientMediaItem } from '@DigitaleDoerfer/digitale-doerfer-api';
import { ImageSizes } from '../../shared/services/Image.service';
import { Box, Grid } from '@mui/material';

const useStyles = makeStyles(() =>
	createStyles({
		image: {
			width: '100px',
			borderRadius: '4px'
		}
	})
);

export interface Props {
	images: ClientMediaItem[];
	leftGalleryBoxPadding: string;
}

function ImageGallery(props: Props): JSX.Element {
	const classes = useStyles();
	const { images, leftGalleryBoxPadding } = props;
	return (
		<Box pl={leftGalleryBoxPadding} pt={0.5}>
			<Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
				{images.map(image => {
					const imageUrl =
						image !== undefined && image !== null
							? imageService().getAvailableImageUrl(image, ImageSizes.THUMBNAIL)
							: '';
					return (
						<Grid key={image.id} item>
							<a href={imageService().getAvailableImageUrl(image, ImageSizes.ORIGINAL)} target="blank">
								<img src={imageUrl} alt="Snow" className={classes.image} />
							</a>
						</Grid>
					);
				})}
			</Grid>
		</Box>
	);
}
export default ImageGallery;
