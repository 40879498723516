import { connect } from 'react-redux';
import React, { Component } from 'react';
import PlatformAdminUIView from './PlatformAdminUI.view';
import { PlatformAdminUIState } from './store/store';
import { restoreAppState } from './modules/auth/App.state';
import { roleService } from './ServiceFactory';
import { RouteComponentProps, withRouter } from 'react-router';
import { ClientRoleAssignment } from '@DigitaleDoerfer/digitale-doerfer-api';
import { Route, Switch } from 'react-router-dom';
import { HOME_URL } from './modules/home/Home.container';
import UsersRoutingContainer, { USERS_URL } from './modules/users/UsersRouting.container';
import { PERMISSION_ERROR_URL, PermissionErrorView } from './modules/error/ErrorPages.view';
import FlaggedContentRoutingContainer, {
	FLAGGED_CONTENTS_URL
} from './modules/flagged-contents/FlaggedContentRouting.container';
import PushEventsRoutingContainer, { PUSH_EVENTS_URL } from './modules/push-events/PushEventsRouting.container';
import AppConfigRoutingContainer, { APP_CONFIGS_URL } from './modules/app-configs/AppConfigRouting.container';
import NewsSourcesRoutingContainer, { NEWS_SOURCES_URL } from './modules/News-Sources/NewsSourcesRouting.container';
import TenantsListRoutingContainer, { TENANTS_URL } from './modules/tenants/TenantsListRouting.container';
import GeoAreasListRoutingContainer, { GEO_AREAS_URL } from './modules/geo-areas/GeoAreasListRouting.container';
import ContractAuditRoutingContainer from './modules/contract-audit/ContractAuditRouting.container';
import HomeRoutingContainer from './modules/home/HomeRouting.container';
import GroupRoutingContainer, { GROUPS_URL } from './modules/groups/GroupRouting.container';
import CONTRACT_AUDIT_ROUTE_URL_BASE from './modules/contract-audit/contractAudit.constants';
import ExternalContributionsRouting, {
	EXTERNAL_CONTRIBUTIONS_URL
} from './modules/external-contributions/ExternalContributionsRouting.container';
import StatisticsRoutingContainer, { STATISTICS_URL } from './modules/statistics/StatisticsRouting.container';

interface Props extends RouteComponentProps {
	ownRolesAssignments: ClientRoleAssignment[];
}

interface State {
	shouldRenderUserMenu: boolean;
	shouldRenderFlaggedContentMenu: boolean;
	shouldRenderGroupMenu: boolean;
	shouldRenderConfigurationMenu: boolean;
	shouldRenderExternalContributionsMenu: boolean;
	shouldRenderStatisticsMenu: boolean;
}

class PlatformAdminUIContainer extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			shouldRenderUserMenu: false,
			shouldRenderFlaggedContentMenu: false,
			shouldRenderGroupMenu: false,
			shouldRenderConfigurationMenu: false,
			shouldRenderExternalContributionsMenu: false,
			shouldRenderStatisticsMenu: false
		};
		this.finishAuthentication = this.finishAuthentication.bind(this);
	}

	async finishAuthentication(): Promise<void> {
		const { history } = this.props;
		restoreAppState(history, { path: '/' });
	}

	componentDidUpdate(prevProps: Props): void {
		const { ownRolesAssignments } = this.props;

		// After getting the assignableRoles
		if (prevProps.ownRolesAssignments.length === 0 && ownRolesAssignments.length > 0) {
			this.setState({
				shouldRenderUserMenu: roleService().shouldRenderUserMenu(ownRolesAssignments),
				shouldRenderFlaggedContentMenu: roleService().shouldRenderFlaggedContentMenu(ownRolesAssignments),
				shouldRenderGroupMenu: roleService().shouldRenderGroupMenu(ownRolesAssignments),
				shouldRenderConfigurationMenu: roleService().shouldRenderConfigurationMenu(ownRolesAssignments),
				shouldRenderExternalContributionsMenu: roleService().shouldRenderExternalContributionsMenu(ownRolesAssignments),
				shouldRenderStatisticsMenu: roleService().shouldRenderStatisticsMenu(ownRolesAssignments)
			});
		}
	}

	renderRoutes(): JSX.Element {
		const {
			shouldRenderUserMenu,
			shouldRenderFlaggedContentMenu,
			shouldRenderGroupMenu,
			shouldRenderConfigurationMenu,
			shouldRenderExternalContributionsMenu,
			shouldRenderStatisticsMenu
		} = this.state;

		return (
			<Switch>
				<Route exact path={HOME_URL}>
					<HomeRoutingContainer />
				</Route>
				<Route path={USERS_URL}>{shouldRenderUserMenu ? <UsersRoutingContainer /> : <PermissionErrorView />}</Route>
				<Route path={FLAGGED_CONTENTS_URL}>
					{shouldRenderFlaggedContentMenu ? <FlaggedContentRoutingContainer /> : <PermissionErrorView />}
				</Route>
				<Route path={GROUPS_URL}>{shouldRenderGroupMenu ? <GroupRoutingContainer /> : <PermissionErrorView />}</Route>
				<Route path={PUSH_EVENTS_URL}>
					{shouldRenderConfigurationMenu ? <PushEventsRoutingContainer /> : <PermissionErrorView />}
				</Route>
				<Route path={APP_CONFIGS_URL}>
					{shouldRenderConfigurationMenu ? <AppConfigRoutingContainer /> : <PermissionErrorView />}
				</Route>
				<Route path={NEWS_SOURCES_URL}>
					{shouldRenderConfigurationMenu ? <NewsSourcesRoutingContainer /> : <PermissionErrorView />}
				</Route>
				<Route path={TENANTS_URL}>
					{shouldRenderConfigurationMenu ? <TenantsListRoutingContainer /> : <PermissionErrorView />}
				</Route>
				<Route path={GEO_AREAS_URL}>
					{shouldRenderConfigurationMenu ? <GeoAreasListRoutingContainer /> : <PermissionErrorView />}
				</Route>
				<Route path={CONTRACT_AUDIT_ROUTE_URL_BASE}>
					{shouldRenderConfigurationMenu ? <ContractAuditRoutingContainer /> : <PermissionErrorView />}
				</Route>
				<Route path={EXTERNAL_CONTRIBUTIONS_URL}>
					{shouldRenderExternalContributionsMenu ? <ExternalContributionsRouting /> : <PermissionErrorView />}
				</Route>
				<Route path={STATISTICS_URL}>
					{shouldRenderStatisticsMenu ? <StatisticsRoutingContainer /> : <PermissionErrorView />}
				</Route>

				<Route path={PERMISSION_ERROR_URL} exact component={PermissionErrorView} />
				<Route component={PermissionErrorView} />
			</Switch>
		);
	}

	render(): JSX.Element {
		const {
			shouldRenderUserMenu,
			shouldRenderFlaggedContentMenu,
			shouldRenderGroupMenu,
			shouldRenderConfigurationMenu,
			shouldRenderExternalContributionsMenu,
			shouldRenderStatisticsMenu
		} = this.state;

		return (
			<PlatformAdminUIView
				shouldRenderUserMenu={shouldRenderUserMenu}
				shouldRenderFlaggedContentMenu={shouldRenderFlaggedContentMenu}
				shouldRenderGroupMenu={shouldRenderGroupMenu}
				shouldRenderConfigurationMenu={shouldRenderConfigurationMenu}
				shouldRenderExternalContributionsMenu={shouldRenderExternalContributionsMenu}
				shouldRenderStatisticsMenu={shouldRenderStatisticsMenu}
			>
				{this.renderRoutes()}
			</PlatformAdminUIView>
		);
	}
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		ownRolesAssignments: state.global.roleManagementPermission.roles
	};
};

export default connect(mapStateToProps)(withRouter(PlatformAdminUIContainer));
