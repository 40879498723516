import React, { Component, PropsWithChildren } from 'react';
import { Box, Paper, Tooltip, Typography } from '@mui/material';
import FormHeaderView from './FormHeader.view';
import SocialMediaListView from './SocialMediaList.view';
import FormFooterView from './FormFooter.view';
import { ClientOauthAccountAuthenticationMethodsEnum } from '@DigitaleDoerfer/digitale-doerfer-api';
import NavButton from '../Buttons/NavButton.view';
import ClipboardView from '../Clipboard.view';

export interface Props {
	title?: string;
	textToCopy: string;
	authenticationMethods?: ClientOauthAccountAuthenticationMethodsEnum[];
	actionMenu?: JSX.Element;
	goToTableHandler?: () => void;
}

interface State {
	showCopyIcon: boolean;
}
class PersonDetailFormView extends Component<PropsWithChildren<Props>, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			showCopyIcon: false
		};
		this.showCopyIcon = this.showCopyIcon.bind(this);
		this.hideCopyIcon = this.hideCopyIcon.bind(this);
	}

	hideCopyIcon(): void {
		this.setState({
			showCopyIcon: false
		});
	}

	showCopyIcon(): void {
		this.setState({
			showCopyIcon: true
		});
	}

	render(): JSX.Element {
		const { title, authenticationMethods, actionMenu, textToCopy, goToTableHandler } = this.props;
		const { showCopyIcon } = this.state;
		const formHeader = title && (
			<FormHeaderView actionMenu={actionMenu}>
				<Box display="flex" onMouseOver={this.showCopyIcon} onMouseLeave={this.hideCopyIcon}>
					<Tooltip title={textToCopy} placement="bottom-end">
						<Typography variant="h1">{title}</Typography>
					</Tooltip>
					<ClipboardView textToCopy={textToCopy} showCopyIcon={showCopyIcon} />
				</Box>
				{authenticationMethods && <SocialMediaListView authenticationMethods={authenticationMethods} />}
			</FormHeaderView>
		);
		const formBody = this.props.children;
		const formFooter = (
			<FormFooterView>
				{goToTableHandler && <NavButton onClick={goToTableHandler}>Zur Benutzertabelle</NavButton>}
			</FormFooterView>
		);

		return (
			<Paper>
				{formHeader}
				{formBody}
				{formFooter}
			</Paper>
		);
	}
}
export default PersonDetailFormView;
