import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../store/store';
import UserCreateView from './../user-create/UserCreate.view';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Paper } from '@mui/material';
import { createUser } from '../store/User.actions';
import {
	ClientCommunity,
	ClientPersonCreateRequest,
	ClientRoleInformation
} from '@DigitaleDoerfer/digitale-doerfer-api';
import { BaseError } from '../../../shared/errors/Errors';
import { UserActionTypes } from '../store/UserActionTypes';
import { roleService } from '../../../ServiceFactory';
import { RoleKey } from '../../../shared/services/Role.service';
import MainLoadingIndicator from '../../../shared/views/LoadingIndicators/MainLoadingIndicator.view';
import { USERS_URL } from '../UsersRouting.container';

interface Props extends RouteComponentProps {
	tenants: ClientCommunity[];
	loading: boolean;
	error: BaseError | null;
	userHasBeenCreated: boolean;
	createUser: (userData: ClientPersonCreateRequest) => void;
	assignableRoles: ClientRoleInformation[];
}

class UserCreateContainer extends Component<Props> {
	constructor(props: Props) {
		super(props);
		this.dispatchCreateUser = this.dispatchCreateUser.bind(this);
	}

	dispatchCreateUser(userData: ClientPersonCreateRequest): void {
		const { createUser } = this.props;
		createUser(userData);
	}

	cancelHandler = (): void => {
		this.props.history.push(USERS_URL);
	};

	render(): JSX.Element {
		const { loading, tenants, error, userHasBeenCreated, assignableRoles } = this.props;
		const validRoles: RoleKey[] = [RoleKey.SUPER_ADMIN, RoleKey.USER_ADMIN, RoleKey.GLOBAL_USER_ADMIN];

		if (!roleService().hasClientRoleInformationSomeValidRole(assignableRoles, validRoles)) {
			return <Redirect to="/permission-error" />;
		}

		return (
			<>
				<MainLoadingIndicator loading={loading} />
				<Paper hidden={loading}>
					<UserCreateView
						title="Benutzer anlegen"
						tenants={tenants}
						error={error}
						loading={loading}
						userHasBeenCreated={userHasBeenCreated}
						cancelHandler={this.cancelHandler}
						dispatchCreateUser={this.dispatchCreateUser}
					/>
				</Paper>
			</>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		loading: state.user.create.loading,
		error: state.user.create.error,
		userHasBeenCreated: state.user.create.userHasBeenCreated,
		assignableRoles: state.global.assignableRoles,
		tenants: state.global.tenants
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<UserActionTypes>) => {
	return {
		createUser: (userData: ClientPersonCreateRequest): Promise<void> => dispatch(createUser(userData))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCreateContainer);
