import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import GroupListView from './GroupList.view';
import { connect } from 'react-redux';
import { GroupActionTypes } from '../store/GroupActionTypes';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../store/store';
import { ListSearchParams } from '../store/Group.state';
import { getGroups } from '../store/Group.actions';
import { ClientCommunity, ClientGroupExtended } from '@DigitaleDoerfer/digitale-doerfer-api';
import { getGroupsDetailsUrlWithId, GROUP_CREATE_URL } from '../GroupRouting.container';

interface Props extends RouteComponentProps {
	loading: boolean;
	tenants: ClientCommunity[];
	groups: ClientGroupExtended[];
	searchParams: ListSearchParams;
	getGroups: (searchParams: ListSearchParams) => void;
}

class GroupListContainer extends Component<Props> {
	constructor(props: Props) {
		super(props);
		this.tenantChangedHandler = this.tenantChangedHandler.bind(this);
	}

	tenantChangedHandler(tenantId: string): void {
		const { searchParams, getGroups } = this.props;
		getGroups({ ...searchParams, tenantId });
	}

	viewGroupDetailsHandler = (groupId: string): void => {
		this.props.history.push(getGroupsDetailsUrlWithId(groupId));
	};

	openNewGroupDetailsTab = (groupId: string): void => {
		window.open(getGroupsDetailsUrlWithId(groupId));
	};

	createGroupHandler = (): void => {
		this.props.history.push(GROUP_CREATE_URL);
	};

	render(): JSX.Element {
		const { tenants, groups, searchParams, loading, getGroups } = this.props;
		return (
			<GroupListView
				loading={loading}
				tenants={tenants}
				groups={groups}
				searchParams={searchParams}
				getGroupsByText={getGroups}
				tenantChangedHandler={this.tenantChangedHandler}
				viewGroupDetailsHandler={this.viewGroupDetailsHandler}
				openNewGroupDetailsTab={this.openNewGroupDetailsTab}
				createGroupHandler={this.createGroupHandler}
			/>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		tenants: state.global.tenants,
		groups: state.group.list.groups,
		loading: state.group.list.loading,
		searchParams: state.group.list.searchParams
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<GroupActionTypes>) => {
	return {
		getGroups: (searchParams: ListSearchParams): Promise<void> => dispatch(getGroups(searchParams))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupListContainer);
