import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import { sharedAdminuiAppApi } from '../../../ServiceFactory';
import { getCurrentPage, getNextPage } from '../helpers';
import { useStore } from '../store';
import { App, AppVariant, ListType } from '../types';

const useFetchAppVariant = (tenantIds: string[], search?: string): UseInfiniteQueryResult<App[]> => {
	const { setCurrentListPage, currentAppVariantPage } = useStore();
	return useInfiniteQuery<App[]>(
		[ListType.APP_VARIANT, { tenantIds, search }],
		({ pageParam = 0 }) =>
			sharedAdminuiAppApi()
				.getAppVariantsByTenantOrContract({ count: 500, tenantIds, page: pageParam, search })
				.then(response => response)
				.then(data => {
					const { content, page } = data;
					const { totalPages, number: responsePage } = page || {};
					const items: { [key: string]: App } = {};
					content?.forEach(({ app, ...childData }) => {
						if (app?.id) {
							if (!items[app.id]) {
								items[app.id] = app as App;
								items[app.id].subItems = [];
							}

							items[app.id].subItems?.push(childData as AppVariant);
						}
					});
					const processedItems = Object.values(items);
					const currentPage = getCurrentPage(responsePage, totalPages);
					setCurrentListPage(ListType.APP_VARIANT, currentPage);
					return processedItems;
				}),
		{
			getNextPageParam: () => getNextPage(currentAppVariantPage)
		}
	);
};

export default useFetchAppVariant;
