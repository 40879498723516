import React, { Component, FormEvent } from 'react';
import { Grid, Paper } from '@mui/material';
import {
	ClientGroupCreateByAdminRequest,
	ClientGroupCreateByAdminRequestGroupAccessibilityEnum,
	ClientGroupCreateByAdminRequestGroupContentVisibilityEnum,
	ClientGroupCreateByAdminRequestGroupVisibilityEnum,
	ClientGroupExtendedAccessibilityEnum,
	ClientGroupExtendedContentVisibilityEnum,
	ClientPersonExtended
} from '@DigitaleDoerfer/digitale-doerfer-api';
import FormHeaderView from '../../../shared/views/PersonDetail/FormHeader.view';
import GroupCreateBasicSettingsView from './GroupCreateBasicSettings.view';
import GroupAdministratorsView from '../../../shared/views/Group/GroupAdministrators.view';
import GroupGeoAreasView from '../../../shared/views/Group/GroupGeoAreas.view';
import FormFooterView from '../../../shared/views/PersonDetail/FormFooter.view';
import ConfirmButton from '../../../shared/views/Buttons/ConfirmButton.view';
import ResetButton from '../../../shared/views/Buttons/ResetButton.view';
import CancelButton from '../../../shared/views/Buttons/CancelButton.view';
import { getSelectedGeoAreasIds, PreFilledState } from './GroupCreate.container';
import GroupAdministratorAutocompleteView from '../../../shared/views/Group/GroupAdministratorAutocomplete.view';
import GroupGeoAreasAutocompleteView from '../../../shared/views/Group/GroupGeoAreasAutocomplete.view';
import { AnyPerson } from '../../../shared/utils/AnyPerson';
import { AnyGeoArea } from '../../../shared/utils/AnyGeoArea';
import { GroupStateChangeHandlers } from '../GroupStateChangeHandlers';
import { GeoAreaElement } from '../../../shared/utils/GeoAreaElements';
import GroupTenantsView from './GroupTenants.view';

function getClientGroupCreateByAdminRequestGroupAccessibilityEnum(
	groupType: ClientGroupExtendedAccessibilityEnum | null
): ClientGroupCreateByAdminRequestGroupAccessibilityEnum | null {
	switch (groupType) {
		case ClientGroupExtendedAccessibilityEnum.Public:
			return ClientGroupCreateByAdminRequestGroupAccessibilityEnum.Public;
		case ClientGroupExtendedAccessibilityEnum.ApprovalRequired:
			return ClientGroupCreateByAdminRequestGroupAccessibilityEnum.ApprovalRequired;
		default:
			return null;
	}
}

function getClientGroupCreateByAdminRequestGroupContentVisibilityEnum(
	groupContentVisibility: boolean
): ClientGroupCreateByAdminRequestGroupContentVisibilityEnum {
	switch (groupContentVisibility) {
		case true:
			return ClientGroupCreateByAdminRequestGroupContentVisibilityEnum.Anyone;
		case false:
			return ClientGroupCreateByAdminRequestGroupContentVisibilityEnum.SameHomeArea;
	}
}

function getClientGroupCreateByAdminRequestGroupVisibilityEnum(
	groupVisibility: ClientGroupExtendedContentVisibilityEnum | null
): ClientGroupCreateByAdminRequestGroupVisibilityEnum | null {
	switch (groupVisibility) {
		case ClientGroupExtendedContentVisibilityEnum.Anyone:
			return ClientGroupCreateByAdminRequestGroupVisibilityEnum.Anyone;
		case ClientGroupExtendedContentVisibilityEnum.Members:
			return ClientGroupCreateByAdminRequestGroupVisibilityEnum.Members;
		case ClientGroupExtendedContentVisibilityEnum.SameHomeArea:
			return ClientGroupCreateByAdminRequestGroupVisibilityEnum.SameHomeArea;
		default:
			return null;
	}
}

interface Props {
	loading: boolean;
	groupHasBeenCreated: boolean;
	geoAreas: AnyGeoArea[];
	flattenedGeoAreas: AnyGeoArea[];
	prefilledState: PreFilledState | undefined;
	cancelHandler: () => void;
	dispatchCreateGroup: (groupData: ClientGroupCreateByAdminRequest) => void;
}

interface State {
	groupName: string;
	groupShortName: string;
	groupType: ClientGroupExtendedAccessibilityEnum | null;
	groupContentVisibility: boolean;
	groupVisibility: ClientGroupExtendedContentVisibilityEnum | null;
	groupMembershipAdmins: ClientPersonExtended[];
	groupMembershipAdminGeoAreas: GeoAreaElement[];
	manualSearchedGeoAreas: GeoAreaElement[];
	groupSelectedTenantId: string | null;
}

const initialState: State = {
	groupName: '',
	groupShortName: '',
	groupType: null,
	groupContentVisibility: false,
	groupVisibility: null,
	groupMembershipAdmins: [],
	groupMembershipAdminGeoAreas: [],
	manualSearchedGeoAreas: [],
	groupSelectedTenantId: null
};

class GroupCreateView extends Component<Props, State> {
	groupStateHandlers: GroupStateChangeHandlers;
	constructor(props: Props) {
		super(props);
		this.state = initialState;
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleStateChange = this.handleStateChange.bind(this);
		this.handleGroupNameChange = this.handleGroupNameChange.bind(this);
		this.handleGroupShortNameChange = this.handleGroupShortNameChange.bind(this);
		this.handleGroupTypeChange = this.handleGroupTypeChange.bind(this);
		this.handleGroupVisibilityChange = this.handleGroupVisibilityChange.bind(this);
		this.handleGroupMembershipAdminsChange = this.handleGroupMembershipAdminsChange.bind(this);
		this.handleGroupTenantSelectionChange = this.handleGroupTenantSelectionChange.bind(this);
		this.handleManualSearchedGeoAreasChange = this.handleManualSearchedGeoAreasChange.bind(this);
		this.handleGroupContentVisibilityChange = this.handleGroupContentVisibilityChange.bind(this);
		this.handleGroupMembershipAdminGeoAreasChange = this.handleGroupMembershipAdminGeoAreasChange.bind(this);
		this.handleUserDeletion = this.handleUserDeletion.bind(this);

		this.groupStateHandlers = {
			handleGroupNameChange: this.handleGroupNameChange,
			handleGroupShortNameChange: this.handleGroupShortNameChange,
			handleGroupTypeChange: this.handleGroupTypeChange,
			handleGroupVisibilityChange: this.handleGroupVisibilityChange,
			handleGroupMembershipAdminsChange: this.handleGroupMembershipAdminsChange,
			handleGroupTenantSelectionChange: this.handleGroupTenantSelectionChange,
			handleManualSearchedGeoAreasChange: this.handleManualSearchedGeoAreasChange,
			handleGroupContentVisibilityChange: this.handleGroupContentVisibilityChange,
			handleGroupMembershipAdminGeoAreasChange: this.handleGroupMembershipAdminGeoAreasChange
		};
	}

	componentDidMount(): void {
		const { prefilledState } = this.props;
		if (prefilledState) {
			this.setState({ ...prefilledState });
		}
	}

	componentDidUpdate(prevProps: Props): void {
		const { groupHasBeenCreated } = this.props;
		// reset form if group was created
		if (!prevProps.groupHasBeenCreated && groupHasBeenCreated) {
			this.resetHandler();
		}
	}

	handleStateChange(name: string, value: unknown): void {
		this.setState({ [name]: value } as Pick<State, keyof State>);
	}

	handleGroupNameChange(newGroupName: string): void {
		this.setState({ groupName: newGroupName });
	}

	handleGroupShortNameChange(newGroupShortName: string): void {
		this.setState({ groupShortName: newGroupShortName });
	}

	handleGroupTypeChange(newGroupType: ClientGroupExtendedAccessibilityEnum | null): void {
		this.setState({ groupType: newGroupType });
	}

	handleGroupContentVisibilityChange(newGroupContentVisibility: boolean): void {
		this.setState({ groupContentVisibility: newGroupContentVisibility });
	}

	handleGroupVisibilityChange(newGroupVisibility: ClientGroupExtendedContentVisibilityEnum | null): void {
		this.setState({ groupVisibility: newGroupVisibility });
	}

	handleGroupMembershipAdminsChange(newGroupMembershipAdmins: ClientPersonExtended[]): void {
		this.setState({ groupMembershipAdmins: newGroupMembershipAdmins });
	}

	handleGroupMembershipAdminGeoAreasChange(newGroupMembershipAdminGeoAreas: GeoAreaElement[]): void {
		this.setState({ groupMembershipAdminGeoAreas: newGroupMembershipAdminGeoAreas });
	}

	handleManualSearchedGeoAreasChange(newManualSearchedGeoAreas: GeoAreaElement[]): void {
		this.setState({ manualSearchedGeoAreas: newManualSearchedGeoAreas });
	}

	handleGroupTenantSelectionChange(tenantId: string): void {
		this.setState({ groupSelectedTenantId: tenantId });
	}

	handleSubmit(event: FormEvent<HTMLFormElement>): void {
		event.preventDefault();
		const { dispatchCreateGroup } = this.props;
		const {
			groupName,
			groupShortName,
			groupType,
			groupContentVisibility,
			groupVisibility,
			groupMembershipAdmins,
			groupMembershipAdminGeoAreas,
			manualSearchedGeoAreas,
			groupSelectedTenantId
		} = this.state;
		const groupProcessedAccessibility = getClientGroupCreateByAdminRequestGroupAccessibilityEnum(groupType);
		const groupProcessedVisibility = getClientGroupCreateByAdminRequestGroupVisibilityEnum(groupVisibility);

		if (
			!groupType ||
			!groupVisibility ||
			!groupMembershipAdmins[0].homeAreaTenantId ||
			!groupProcessedAccessibility ||
			!groupProcessedVisibility
		) {
			// show some error?
			return;
		}

		// Prepare group data request
		const geoAreaIds: string[] = getSelectedGeoAreasIds(groupMembershipAdminGeoAreas, manualSearchedGeoAreas);
		const groupData: ClientGroupCreateByAdminRequest = {
			groupAccessibility: groupProcessedAccessibility,
			groupContentVisibility: getClientGroupCreateByAdminRequestGroupContentVisibilityEnum(groupContentVisibility),
			groupVisibility: groupProcessedVisibility,
			name: groupName,
			shortName: groupShortName,
			groupMembershipAdminIds: groupMembershipAdmins.map(groupMember => groupMember.id),
			tenantId: groupSelectedTenantId ? groupSelectedTenantId : groupMembershipAdmins[0].homeAreaTenantId,
			includedGeoAreaIds: geoAreaIds
		};
		dispatchCreateGroup(groupData);
	}

	resetHandler = (): void => {
		this.setState(initialState);
	};

	/**
	 * Removes user from geoarea elements list.
	 * 1. If there is another user in the same geoArea element just removes the user.
	 * 2. If the geoarea element has just the given user, removes the geoArea element.
	 * @param user User to be removed from geoArea element list.
	 */
	handleGeoAreaElementByUserDeletion(user: ClientPersonExtended): void {
		const { groupMembershipAdminGeoAreas } = this.state;
		groupMembershipAdminGeoAreas?.forEach(groupMemberGeoArea => {
			const foundUserGeoAreaElement = groupMemberGeoArea.geoAreaChildren.find(geoAreaChild =>
				geoAreaChild.users.some(geoAreaUser => geoAreaUser.id === user.id)
			);
			if (foundUserGeoAreaElement) {
				const foundAnotherUserInSameGeoAreaElement = groupMemberGeoArea.geoAreaChildren.some(geoAreaChild =>
					geoAreaChild.users.some(geoAreaUser => geoAreaUser.id !== user.id)
				);
				const newGroupMembershipAdminGeoAreas = [...groupMembershipAdminGeoAreas];
				const removableGeoAreaIndex: number = groupMembershipAdminGeoAreas.findIndex(
					searchedGeoArea => groupMemberGeoArea?.geoAreaParent?.id === searchedGeoArea.geoAreaParent?.id
				);
				if (!foundAnotherUserInSameGeoAreaElement) {
					newGroupMembershipAdminGeoAreas.splice(removableGeoAreaIndex, 1);
				} else {
					const foundGeoAreaChildIndex: number | undefined = groupMemberGeoArea.geoAreaChildren.findIndex(
						geoAreaChild => geoAreaChild.geoArea.id === user.homeAreaId
					);
					//remove only the user from userlists of the geoAreaChild of the geoArea element
					newGroupMembershipAdminGeoAreas[removableGeoAreaIndex].geoAreaChildren[foundGeoAreaChildIndex].users =
						newGroupMembershipAdminGeoAreas[removableGeoAreaIndex].geoAreaChildren[foundGeoAreaChildIndex].users.filter(
							geoUser => geoUser.id !== user.id
						);
				}
				this.handleGroupMembershipAdminGeoAreasChange(newGroupMembershipAdminGeoAreas);
			}
		});
	}

	/**
	 * Removes given user from the groupMembershipAdmins list and groupMembershipAdminGeoAreas list.
	 * @param user User to be removed.
	 */
	handleUserDeletion(user: ClientPersonExtended): void {
		const { groupMembershipAdmins } = this.state;
		const newGroupMembershipAdmins = groupMembershipAdmins.filter(
			(userMember: ClientPersonExtended) => userMember.id !== user.id
		);

		this.handleGroupMembershipAdminsChange(newGroupMembershipAdmins);

		// remove user or geoAreaElement from groupMembershipAdminGeoAreas
		this.handleGeoAreaElementByUserDeletion(user);
	}

	render(): JSX.Element {
		const { loading, geoAreas, flattenedGeoAreas, cancelHandler } = this.props;
		const {
			groupName,
			groupShortName,
			groupType,
			groupVisibility,
			groupContentVisibility,
			groupMembershipAdmins,
			groupMembershipAdminGeoAreas,
			manualSearchedGeoAreas,
			groupSelectedTenantId
		} = this.state;
		const submitDisabled = !(
			groupName &&
			groupShortName &&
			groupType &&
			groupVisibility &&
			(groupSelectedTenantId || (groupMembershipAdmins.length > 0 && groupMembershipAdmins[0].homeAreaTenantId)) &&
			groupMembershipAdminGeoAreas.length > 0
		);

		return (
			<Paper hidden={loading}>
				<form onSubmit={this.handleSubmit}>
					<FormHeaderView title={'Neue Gruppe erstellen'} />
					<GroupCreateBasicSettingsView
						groupName={groupName}
						groupShortName={groupShortName}
						groupType={groupType}
						groupContentVisibility={groupContentVisibility}
						groupVisibility={groupVisibility}
						groupStateChangeHandlers={this.groupStateHandlers}
					/>
					{groupType && groupVisibility && (
						<GroupAdministratorsView
							title="Administratoren"
							admins={groupMembershipAdmins}
							handleUserDeletion={(user: AnyPerson): void => {
								const groupMembershipAdmin = groupMembershipAdmins.find(
									groupMembershipAdmin => groupMembershipAdmin.id === user.id
								);
								if (groupMembershipAdmin) {
									this.handleUserDeletion(groupMembershipAdmin);
								}
							}}
						>
							<GroupAdministratorAutocompleteView
								geoAreas={geoAreas}
								flattenedGeoAreas={flattenedGeoAreas}
								autocompleteLabel="Gruppenadmin(s) festlegen"
								groupMembershipAdmins={groupMembershipAdmins}
								groupMembershipAdminGeoAreas={groupMembershipAdminGeoAreas}
								groupStateChangeHandlers={this.groupStateHandlers}
							/>
						</GroupAdministratorsView>
					)}
					{groupType && groupVisibility && groupMembershipAdminGeoAreas.length > 0 && (
						<>
							<GroupTenantsView
								selectedTenantId={groupSelectedTenantId}
								groupMembershipAdmins={groupMembershipAdmins}
								handleGroupTenantSelectionChange={(selectedTenantId: string): void => {
									this.handleGroupTenantSelectionChange(selectedTenantId);
								}}
							/>
							<GroupGeoAreasView
								title="Gemeinden"
								groupMembershipAdminGeoAreas={groupMembershipAdminGeoAreas}
								manualSearchedGeoAreas={manualSearchedGeoAreas}
								groupStateChangeHandlers={this.groupStateHandlers}
								useAdditionalGeoAreas={true}
								readOnly={false}
							>
								<GroupGeoAreasAutocompleteView
									geoAreas={geoAreas}
									flattenedGeoAreas={flattenedGeoAreas}
									manualSearchedGeoAreas={manualSearchedGeoAreas}
									groupStateChangeHandlers={this.groupStateHandlers}
								/>
							</GroupGeoAreasView>
						</>
					)}
					<FormFooterView>
						<Grid item xs={12} container spacing={2}>
							<Grid item>
								<ConfirmButton disabled={submitDisabled} submit={true}>
									Gruppe Erstellen
								</ConfirmButton>
							</Grid>
							<Grid item>
								<ResetButton onClick={this.resetHandler}>Zurücksetzen</ResetButton>
							</Grid>
							<Grid item>
								<CancelButton onClick={cancelHandler}>Abbrechen</CancelButton>
							</Grid>
						</Grid>
					</FormFooterView>
				</form>
			</Paper>
		);
	}
}
export default GroupCreateView;
