import React from 'react';
import { Avatar, Box } from '@mui/material';
import clsx from 'clsx';
import { red } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { ClientMediaItem } from '@DigitaleDoerfer/digitale-doerfer-api';
import { ImageSizes } from '../services/Image.service';
import defaultAvatar from '../../assets/images/avatar_default.svg';
import deletedAvatar from '../../assets/images/avatar_deleted.svg';
import { imageService } from '../../ServiceFactory';

const useStyles = makeStyles({
	avatarDeletedStyle: {
		backgroundColor: red[500]
	},
	avatarXs: {
		height: '20px !important',
		width: '20px !important',
		margin: '0 0 0 auto'
	},
	avatarSm: {
		height: '50px !important',
		width: '50px !important',
		margin: '0 0 0 auto'
	},
	avatarMd: {
		height: '100px !important',
		width: '100px !important',
		margin: '0 0 0 auto'
	}
});

type AvatarSize = ImageSizes.THUMBNAIL | ImageSizes.SMALL | ImageSizes.MEDIUM;

function getImageSizeCSSClass(
	classes: Record<'avatarXs' | 'avatarSm' | 'avatarMd', string>,
	size?: AvatarSize
): string {
	switch (size) {
		case ImageSizes.THUMBNAIL: {
			return classes.avatarXs;
		}
		case ImageSizes.SMALL: {
			return classes.avatarSm;
		}
		case ImageSizes.MEDIUM: {
			return classes.avatarMd;
		}
		default:
			return '';
	}
}
interface Props {
	avatarPicture?: ClientMediaItem;
	deleted?: boolean;
	size?: AvatarSize;
}

const AvatarView = (props: Props): JSX.Element => {
	const classes = useStyles();
	const { avatarPicture, deleted, size } = props;
	const avatarPictureUrl =
		avatarPicture !== undefined && avatarPicture !== null
			? imageService().getAvailableImageUrl(avatarPicture, size ?? ImageSizes.THUMBNAIL)
			: defaultAvatar;
	const imageUrl = deleted ? deletedAvatar : avatarPictureUrl;

	return (
		<Box
			sx={{
				textAlign: 'end'
			}}
		>
			<Avatar
				src={imageUrl}
				className={clsx(getImageSizeCSSClass(classes, size), deleted ? classes.avatarDeletedStyle : {})}
			/>
		</Box>
	);
};

export default AvatarView;
