import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../../store/store';
import {
	showSnackbar,
	SnackbarAction,
	SnackbarType
} from '../../../../shared/views/SnackbarNotification/store/SnackbarNotification.actions';
import { UserActionTypes } from '../../store/UserActionTypes';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import {
	ClientPersonChangeStatusRequest,
	ClientPersonChangeStatusRequestStatusesToAddEnum,
	ClientPersonChangeStatusRequestStatusesToRemoveEnum,
	ClientPersonExtended,
	ClientPersonExtendedStatusesEnum
} from '@DigitaleDoerfer/digitale-doerfer-api';
import { changeStatusByAdmin } from '../../store/User.actions';
import ChangeStatusActionView from './ChangeStatusAction.view';

interface Props extends ActionMenuItemProps {
	user: ClientPersonExtended | null;
	showSnackbar: (snackbarAction: SnackbarAction) => void;
	changeStatus: (changeStatusRequestParams: ClientPersonChangeStatusRequest) => void;
}

class ChangeStatusActionContainer extends Component<Props> {
	constructor(props: Props) {
		super(props);
		this.handleSave = this.handleSave.bind(this);
	}

	isUserInParallelWorld(user: ClientPersonExtended): boolean {
		return !!user?.statuses?.some(status => status === ClientPersonExtendedStatusesEnum.ParallelWorldInhabitant);
	}

	handleSave(): void {
		const { user, showSnackbar, changeStatus } = this.props;
		const personId = user?.id;
		if (user && personId) {
			changeStatus({
				personId,
				statusesToAdd: !this.isUserInParallelWorld(user)
					? [ClientPersonChangeStatusRequestStatusesToAddEnum.ParallelWorldInhabitant]
					: [],
				statusesToRemove: this.isUserInParallelWorld(user)
					? [ClientPersonChangeStatusRequestStatusesToRemoveEnum.ParallelWorldInhabitant]
					: []
			});
		} else {
			showSnackbar({
				type: SnackbarType.SNACKBAR_ERROR,
				message: 'Benutzer hat keine ID.'
			});
		}
	}

	render(): JSX.Element | null {
		const { user, renderMenuItem } = this.props;
		if (!user || user?.deleted) {
			return null;
		}
		const isInParallelWorld = this.isUserInParallelWorld(user);
		const actionText = isInParallelWorld ? 'In richtige Welt holen' : 'In die Parallelwelt schicken';

		return (
			<ChangeStatusActionView
				text={actionText}
				userFirstName={user.firstName ?? ''}
				isInParallelWorld={isInParallelWorld}
				renderMenuItem={renderMenuItem}
				handleSave={this.handleSave}
			/>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		user: state.user.detail.user
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<UserActionTypes>) => {
	return {
		showSnackbar: (snackbarAction: SnackbarAction): Promise<void> => dispatch(showSnackbar(snackbarAction)),
		changeStatus: (changeStatusRequestParams: ClientPersonChangeStatusRequest): Promise<void> =>
			dispatch(changeStatusByAdmin(changeStatusRequestParams))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeStatusActionContainer);
