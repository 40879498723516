import React, { Component } from 'react';
import { Grid, Link, Typography } from '@mui/material';
import {
	ClientPersonReference,
	ClientUserGeneratedContentFlagChat,
	ClientUserGeneratedContentFlagStatusRecord
} from '@DigitaleDoerfer/digitale-doerfer-api';
import { flaggedContentService, flaggedContentViewService, timeService } from '../../../ServiceFactory';
import FlaggedContentDetailsReasonView from './shared/FlaggedContentDetailsReason.view';
import FlaggedContentDetailsStatusView from './shared/FlaggedContentDetailsStatus.view';
import { TimestampFormat } from '../../../shared/services/Time.service';
import PersonNameWithAvatarPicture from '../../../shared/views/PersonDetail/PersonNameWithAvatarPicture.view';
import FlaggedContentStatusHistoryView from './shared/FlaggedContentStatusHistory.view';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import history from '../../../history';
import { getUsersDetailsUrl } from '../../users/UsersRouting.container';
import { RoleKey, RoleManagementPermission } from '../../../shared/services/Role.service';
import { FlagSummary } from './shared/FlaggedContentDetailsCommons';
import { ReadOnlyTextFieldView } from '../../../shared/views/ReadOnlyTextField.view';

const styles = createStyles({
	participant: {
		paddingLeft: 4
	},
	participantsLine: {
		whiteSpace: 'pre-wrap'
	}
});

interface Props extends WithStyles<typeof styles> {
	chatFlag: ClientUserGeneratedContentFlagChat;
	flagSummary: FlagSummary;
	statusRecords: ClientUserGeneratedContentFlagStatusRecord[];
	roleManagementPermission: RoleManagementPermission;
}

class FlaggedChatView extends Component<Props> {
	renderParticipant(participant: ClientPersonReference): JSX.Element {
		const { classes } = this.props;
		return (
			<PersonNameWithAvatarPicture
				person={participant}
				spaceBetweenIconAndText={4}
				textVariant="body1"
				className={classes.participant}
			/>
		);
	}

	renderParticipantsLine(): JSX.Element {
		const { chatFlag, roleManagementPermission, classes } = this.props;
		const showLinks =
			roleManagementPermission.isRestAdmin ||
			roleManagementPermission.isGlobalUser ||
			roleManagementPermission.roles.some(
				role => role.roleKey === RoleKey.USER_ADMIN || role.roleKey === RoleKey.ROLE_MANAGER
			);

		const participantsComponents =
			chatFlag?.chatParticipants?.map(participant => (
				<span key={participant.id} className={classes.participantsLine}>
					{showLinks ? (
						<Link
							href="#"
							onClick={(event: React.MouseEvent): void => {
								event.preventDefault();
								if (participant.id) {
									history.push(getUsersDetailsUrl(participant.id));
								}
							}}
						>
							{this.renderParticipant(participant)}
						</Link>
					) : (
						this.renderParticipant(participant)
					)}
				</span>
			)) ?? [];
		const participantsComponentStringArray: React.ReactNode[] = [
			<Grid item key="chat between" className={classes.participantsLine}>
				<Typography component="span">Chat zwischen </Typography>
			</Grid>
		];
		const [participantComponentsHead, ...participantComponentsTail] = participantsComponents;

		participantsComponentStringArray.push(participantComponentsHead);

		participantComponentsTail.slice(0, participantComponentsTail.length - 1).forEach((participantComponent, index) => {
			participantsComponentStringArray.push(
				<Grid key={'comma' + index} item className={classes.participantsLine}>
					<Typography component="span">, </Typography>
				</Grid>
			);
			participantsComponentStringArray.push(participantComponent);
		});

		participantsComponentStringArray.push(
			<Grid key="and" item className={classes.participantsLine}>
				<Typography component="span"> und </Typography>
			</Grid>
		);
		participantsComponentStringArray.push(participantsComponents[participantsComponents.length - 1]);
		participantsComponentStringArray.push(
			<Grid key="period" item className={classes.participantsLine}>
				<Typography component="span">{'.'}</Typography>
			</Grid>
		);

		return (
			<Grid item xs={12}>
				<Grid container>{participantsComponentStringArray}</Grid>
			</Grid>
		);
	}

	render(): JSX.Element {
		const { chatFlag, statusRecords } = this.props;
		const flagSummary = flaggedContentService().getFlagSummaryInformation(chatFlag);
		const createdTimestamp = chatFlag.createdTimestamp;
		const lastMessageTimestamp = chatFlag.lastMessageTimestamp;
		const numberOfChatMessages = chatFlag.numberOfChatMessages;
		const topic = chatFlag.topic;
		return (
			<>
				<Grid container spacing={3}>
					{this.renderParticipantsLine()}
					<Grid item xs={12} md={6}>
						<ReadOnlyTextFieldView
							id="createdTimestamp"
							name="createdTimestamp"
							label={'Beginn des Chats'}
							value={flaggedContentViewService().renderInfo(
								createdTimestamp !== undefined
									? timeService().parseTimestamp(createdTimestamp, TimestampFormat.DD_MM_YYYY_HH_MM)
									: undefined
							)}
							InputProps={{ readOnly: true }}
							InputLabelProps={{
								shrink: true
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<ReadOnlyTextFieldView
							id="lastMessageTimestamp"
							name="lastMessageTimestamp"
							label={'Letzte Nachricht im Chat'}
							value={flaggedContentViewService().renderInfo(
								lastMessageTimestamp !== undefined
									? timeService().parseTimestamp(lastMessageTimestamp, TimestampFormat.DD_MM_YYYY_HH_MM)
									: undefined
							)}
							InputProps={{ readOnly: true }}
							InputLabelProps={{
								shrink: true
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<ReadOnlyTextFieldView
							id="numberOfChatMessages"
							name="numberOfChatMessages"
							label={'Anzahl der Nachrichten'}
							value={numberOfChatMessages}
							InputProps={{ readOnly: true }}
							InputLabelProps={{
								shrink: true
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<ReadOnlyTextFieldView
							id="topic"
							name="topic"
							label={'Chat-Topic'}
							value={topic}
							InputProps={{ readOnly: true }}
							InputLabelProps={{
								shrink: true
							}}
							fullWidth
						/>
					</Grid>
					<FlaggedContentDetailsReasonView reason={flaggedContentViewService().renderInfo(flagSummary.reason)} />
					<FlaggedContentDetailsStatusView
						status={flagSummary.status}
						lastStatusUpdate={flagSummary.lastStatusUpdate}
					/>
				</Grid>
				<FlaggedContentStatusHistoryView flagSummary={flagSummary} statusRecords={statusRecords} />
			</>
		);
	}
}

export default withStyles(styles)(FlaggedChatView);
