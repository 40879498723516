import {
	ClientUserGeneratedContentFlag,
	ClientUserGeneratedContentFlagChat,
	ClientUserGeneratedContentFlagComment,
	ClientUserGeneratedContentFlagDetail,
	ClientUserGeneratedContentFlagGroup,
	ClientUserGeneratedContentFlagPost,
	ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum,
	ClientUserGeneratedContentFlagStatusRecord
} from '@DigitaleDoerfer/digitale-doerfer-api';
import { ListSearchParams } from './FlaggedContent.state';
import { BaseError } from '../../../shared/errors/Errors';
import { TableSorting } from '../../../shared/views/Table/TableSorting';
import { IncludedStatusMapping } from '../services/FlaggedContent.service';
import { Pagination } from '../../../shared/services/InMemoryTable.service';
import { FlagSummary } from '../flagged-content-details/shared/FlaggedContentDetailsCommons';

export const GET_FLAGGED_CONTENTS_REQUEST = 'GET_FLAGGED_CONTENTS_REQUEST';
export const GET_FLAGGED_CONTENTS_SUCCESS = 'GET_FLAGGED_CONTENTS_SUCCESS';
export const GET_FLAGGED_CONTENTS_FAILURE = 'GET_FLAGGED_CONTENTS_FAILURE';

export const GET_FLAGGED_CONTENT_REQUEST = 'GET_FLAGGED_CONTENT_REQUEST';
export const GET_FLAGGED_CONTENT_SUCCESS = 'GET_FLAGGED_CONTENT_SUCCESS';
export const GET_FLAGGED_CONTENT_FAILURE = 'GET_FLAGGED_CONTENT_FAILURE';

export const GET_CHAT_FLAG_REQUEST = 'GET_CHAT_FLAG_REQUEST';
export const GET_CHAT_FLAG_SUCCESS = 'GET_CHAT_FLAG_SUCCESS';
export const GET_CHAT_FLAG_FAILURE = 'GET_CHAT_FLAG_FAILURE';

export const GET_COMMENT_FLAG_REQUEST = 'GET_COMMENT_FLAG_REQUEST';
export const GET_COMMENT_FLAG_SUCCESS = 'GET_COMMENT_FLAG_SUCCESS';
export const GET_COMMENT_FLAG_FAILURE = 'GET_COMMENT_FLAG_FAILURE';

export const GET_POST_FLAG_REQUEST = 'GET_POST_FLAG_REQUEST';
export const GET_POST_FLAG_SUCCESS = 'GET_POST_FLAG_SUCCESS';
export const GET_POST_FLAG_FAILURE = 'GET_POST_FLAG_FAILURE';

export const GET_GROUP_FLAG_REQUEST = 'GET_GROUP_FLAG_REQUEST';
export const GET_GROUP_FLAG_SUCCESS = 'GET_GROUP_FLAG_SUCCESS';
export const GET_GROUP_FLAG_FAILURE = 'GET_GROUP_FLAG_FAILURE';

export const ACCEPT_FLAG_AND_DELETE_CONTENT_REQUEST = 'ACCEPT_FLAG_AND_DELETE_CONTENT_REQUEST';
export const ACCEPT_FLAG_AND_DELETE_CONTENT_SUCCESS = 'ACCEPT_FLAG_AND_DELETE_CONTENT_SUCCESS';
export const ACCEPT_FLAG_AND_DELETE_CONTENT_FAILURE = 'ACCEPT_FLAG_AND_DELETE_CONTENT_FAILURE';

export const CHANGE_FLAG_STATUS_REQUEST = 'CHANGE_FLAG_STATUS_REQUEST';
export const CHANGE_FLAG_STATUS_SUCCESS = 'CHANGE_FLAG_STATUS_SUCCESS';
export const CHANGE_FLAG_STATUS_FAILURE = 'CHANGE_FLAG_STATUS_FAILURE';

export interface GetFlaggedContentsRequestAction {
	type: typeof GET_FLAGGED_CONTENTS_REQUEST;
	searchParams: ListSearchParams;
}

export interface GetFlaggedContentsSuccessAction {
	type: typeof GET_FLAGGED_CONTENTS_SUCCESS;
	flaggedContents: ClientUserGeneratedContentFlag[];
	includedStatus: IncludedStatusMapping;
	sorting: TableSorting;
	pagination: Pagination;
}

export interface GetFlaggedContentsFailureAction {
	type: typeof GET_FLAGGED_CONTENTS_FAILURE;
	error: BaseError | null;
}

export interface GetFlaggedContentRequestAction {
	type: typeof GET_FLAGGED_CONTENT_REQUEST;
	flaggedContentId: string;
}

export interface GetFlaggedContentSuccessAction {
	type: typeof GET_FLAGGED_CONTENT_SUCCESS;
	flaggedContent: ClientUserGeneratedContentFlagDetail;
	statusRecords: ClientUserGeneratedContentFlagStatusRecord[];
	flagSummary: FlagSummary;
}

export interface GetFlaggedContentFailureAction {
	type: typeof GET_FLAGGED_CONTENT_FAILURE;
	error: BaseError | null;
}

export interface GetChatFlagRequestAction {
	type: typeof GET_CHAT_FLAG_REQUEST;
	chatFlagId: string;
}

export interface GetChatFlagSuccessAction {
	type: typeof GET_CHAT_FLAG_SUCCESS;
	chatFlagContent: ClientUserGeneratedContentFlagChat;
	statusRecords: ClientUserGeneratedContentFlagStatusRecord[];
	flagSummary: FlagSummary;
}

export interface GetChatFlagFailureAction {
	type: typeof GET_CHAT_FLAG_FAILURE;
	error: BaseError | null;
}

export interface GetCommentFlagRequestAction {
	type: typeof GET_COMMENT_FLAG_REQUEST;
	commentFlagId: string;
}

export interface GetCommentFlagSuccessAction {
	type: typeof GET_COMMENT_FLAG_SUCCESS;
	commentFlagContent: ClientUserGeneratedContentFlagComment;
	statusRecords: ClientUserGeneratedContentFlagStatusRecord[];
	flagSummary: FlagSummary;
}

export interface GetCommentFlagFailureAction {
	type: typeof GET_COMMENT_FLAG_FAILURE;
	error: BaseError | null;
}

export interface GetPostFlagRequestAction {
	type: typeof GET_POST_FLAG_REQUEST;
	postFlagId: string;
}

export interface GetPostFlagSuccessAction {
	type: typeof GET_POST_FLAG_SUCCESS;
	postFlagContent: ClientUserGeneratedContentFlagPost;
	statusRecords: ClientUserGeneratedContentFlagStatusRecord[];
	flagSummary: FlagSummary;
}

export interface GetPostFlagFailureAction {
	type: typeof GET_POST_FLAG_FAILURE;
	error: BaseError | null;
}

export interface GetGroupFlagRequestAction {
	type: typeof GET_GROUP_FLAG_REQUEST;
	flaggedContentId: string;
}

export interface GetGroupFlagSuccessAction {
	type: typeof GET_GROUP_FLAG_SUCCESS;
	flaggedContent: ClientUserGeneratedContentFlagGroup;
	statusRecords: ClientUserGeneratedContentFlagStatusRecord[];
	flagSummary: FlagSummary;
}

export interface GetGroupFlagFailureAction {
	type: typeof GET_GROUP_FLAG_FAILURE;
	error: BaseError | null;
}

export interface AcceptFlagAndDeleteContentRequestAction {
	type: typeof ACCEPT_FLAG_AND_DELETE_CONTENT_REQUEST;
	flagId: string;
	comment: string;
}

export interface AcceptFlagAndDeleteContentSuccessAction {
	type: typeof ACCEPT_FLAG_AND_DELETE_CONTENT_SUCCESS;
	flagId: string;
	clientUserGeneratedContentFlagDetail: ClientUserGeneratedContentFlagDetail;
	flagSummary: FlagSummary;
}

export interface AcceptFlagAndDeleteContentFailureAction {
	type: typeof ACCEPT_FLAG_AND_DELETE_CONTENT_FAILURE;
	error: BaseError | null;
}

export interface ChangeFlagStatusRequestAction {
	type: typeof CHANGE_FLAG_STATUS_REQUEST;
	flagId: string;
	newStatus: ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum;
	comment: string;
}

export interface ChangeFlagStatusSuccessAction {
	type: typeof CHANGE_FLAG_STATUS_SUCCESS;
	flagId: string;
	clientUserGeneratedContentFlagDetail: ClientUserGeneratedContentFlagDetail;
	flagSummary: FlagSummary;
}

export interface ChangeFlagStatusFailureAction {
	type: typeof CHANGE_FLAG_STATUS_FAILURE;
	error: BaseError | null;
}

export type FlaggedContentActionTypes =
	| GetFlaggedContentsRequestAction
	| GetFlaggedContentsSuccessAction
	| GetFlaggedContentsFailureAction
	| GetFlaggedContentRequestAction
	| GetFlaggedContentSuccessAction
	| GetFlaggedContentFailureAction
	| GetChatFlagRequestAction
	| GetChatFlagSuccessAction
	| GetChatFlagFailureAction
	| GetCommentFlagRequestAction
	| GetCommentFlagSuccessAction
	| GetCommentFlagFailureAction
	| GetPostFlagRequestAction
	| GetPostFlagSuccessAction
	| GetPostFlagFailureAction
	| GetGroupFlagRequestAction
	| GetGroupFlagSuccessAction
	| GetGroupFlagFailureAction
	| AcceptFlagAndDeleteContentRequestAction
	| AcceptFlagAndDeleteContentSuccessAction
	| AcceptFlagAndDeleteContentFailureAction
	| ChangeFlagStatusRequestAction
	| ChangeFlagStatusSuccessAction
	| ChangeFlagStatusFailureAction;
