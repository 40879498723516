import React from 'react';
import { ImageSizes } from '../../services/Image.service';
import { Grid } from '@mui/material';
import { ClientPersonReferenceWithEmail, ClientUserGeneratedContentFlag } from '@DigitaleDoerfer/digitale-doerfer-api';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles, { ClassNameMap } from '@mui/styles/withStyles';
import clsx from 'clsx';
import AvatarView from '../Avatar.view';
import PersonNameView from './PersonName.view';
import { Link } from 'react-router-dom';
import { getUsersDetailsUrl } from '../../../modules/users/UsersRouting.container';
import { TableCell } from '../../views/Table/TableColumn';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => {
	return createStyles({
		container: {
			alignSelf: 'center'
		},
		icon: {
			alignSelf: 'center'
		},
		text: {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			paddingLeft: 4
		}
	});
};

interface Props extends WithStyles<typeof styles> {
	cell: TableCell<ClientUserGeneratedContentFlag>;
	width?: number;
	spaceBetweenIconAndText?: number;
	textVariant: 'body1' | 'body2' | 'h6';
	className?: string;
}

const RenderClickableLink = (
	person: ClientPersonReferenceWithEmail | undefined,
	width: number | undefined,
	spaceBetweenIconAndText: number | undefined,
	textVariant: 'body1' | 'body2' | 'h6',
	className: string | undefined,
	classes: ClassNameMap
): React.ReactElement => {
	return (
		<Link to={getUsersDetailsUrl(person?.id ? person?.id : '')}>
			<Grid
				item
				container
				direction="row"
				wrap="nowrap"
				className={clsx(className, classes.container)}
				{...(!spaceBetweenIconAndText ? { spacing: 1 } : {})}
				style={width !== undefined ? { width } : {}}
			>
				<Grid item className={classes.icon}>
					<AvatarView avatarPicture={person?.profilePicture} deleted={person?.deleted} size={ImageSizes.THUMBNAIL} />
				</Grid>
				<Grid
					item
					className={classes.text}
					style={spaceBetweenIconAndText !== undefined ? { paddingLeft: spaceBetweenIconAndText } : {}}
				>
					<PersonNameView person={person} textVariant={textVariant} />
				</Grid>
			</Grid>
		</Link>
	);
};

class PersonNameWithAvatarPicClickable extends React.Component<Props> {
	render(): JSX.Element {
		const { cell, width, spaceBetweenIconAndText, textVariant, className, classes } = this.props;

		if (cell.column.id === 'author' && cell.row.entityAuthor?.id !== undefined) {
			return RenderClickableLink(
				cell.row.entityAuthor,
				width,
				spaceBetweenIconAndText,
				textVariant,
				className,
				classes
			);
		}
		if (cell.column.id === 'creator' && cell.row.flagCreator?.id !== undefined) {
			return RenderClickableLink(cell.row.flagCreator, width, spaceBetweenIconAndText, textVariant, className, classes);
		} else {
			return <span></span>;
		}
	}
}
export default withStyles(styles)(PersonNameWithAvatarPicClickable);
