/* Module is mostly adpated from an already existing module in the digital vital project */
import EditIcon from '@mui/icons-material/Edit';
import { Badge, IconButton } from '@mui/material';
import { ClientMediaItem, ClientTemporaryMediaItem } from '@DigitaleDoerfer/digitale-doerfer-api';
import React, { CSSProperties, FC, Fragment, useCallback, useMemo, useState } from 'react';
import { imageService } from '../../../ServiceFactory';
import { ImageSizes } from '../../../shared/services/Image.service';
import UploadImageDialog from './UploadImageDialog';

type EditableImageProps = {
	clientMediaItem?: ClientMediaItem;
	defaultImage: React.ReactElement;
	rounded?: boolean;
	onChange?: (temporaryMediaItemId: ClientTemporaryMediaItem) => void;
};

const useStyles = (): Record<string, CSSProperties> => ({
	rounded: {
		border: '1px solid currentColor',
		borderRadius: '100%'
	}
});

const EditableImage: FC<EditableImageProps> = (props: EditableImageProps) => {
	const { clientMediaItem, defaultImage, rounded, onChange } = props;
	const styles = useStyles();
	const [isUploadImageDialogOpen, setUploadImageDialogOpen] = useState<boolean>(false);

	const handleUploadImageDialogClose = useCallback(() => {
		setUploadImageDialogOpen(false);
	}, []);

	const handleUploadImageFinished = useCallback(
		(temporaryMediaItemId: ClientTemporaryMediaItem) => {
			if (onChange) onChange(temporaryMediaItemId);
			setUploadImageDialogOpen(false);
		},
		[onChange]
	);

	const showUploadImageDialog = useCallback(() => {
		setUploadImageDialogOpen(true);
	}, []);

	const getImageUrl = useMemo(() => {
		if (clientMediaItem) {
			return imageService().getAvailableImageUrl(clientMediaItem, ImageSizes.THUMBNAIL);
		}
	}, [clientMediaItem]);

	const imageStyle = useMemo(() => {
		return rounded ? { ...styles.rounded } : {};
	}, [rounded, styles]);

	return (
		<Fragment>
			<Badge
				overlap="circular"
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				badgeContent={
					<IconButton size="small" aria-label="edit-image" onClick={showUploadImageDialog}>
						<EditIcon />
					</IconButton>
				}
			>
				{getImageUrl ? (
					<img src={getImageUrl} loading="lazy" height="100rem" style={imageStyle} alt="NewsSource Logo" />
				) : (
					defaultImage
				)}
			</Badge>
			<UploadImageDialog
				open={isUploadImageDialogOpen}
				onClose={handleUploadImageDialogClose}
				onUploadFinished={handleUploadImageFinished}
			/>
		</Fragment>
	);
};

export default EditableImage;
