import { ClientExceptionEntity, ClientExceptionEntityTypeEnum } from '@DigitaleDoerfer/digitale-doerfer-api';

export class BaseError extends Error {
	constructor(public readonly message: string) {
		super(message);
	}

	get displayMessage(): string {
		return this.message;
	}
}

const shortCode = (clientException: ClientExceptionEntity): string => {
	return clientException.type ? ` (${clientException.type})` : '';
};

const detailOrEmpty = (clientException: ClientExceptionEntity): string => {
	return clientException.detail ? clientException.detail + ' ' : '';
};

const DEFAULT_DISPLAY_MESSAGE = 'Fehler beim Serveraufruf.';
export const INTERNAL_ERROR = 'Es ist ein interner Fehler aufgetreten.';

const translate = (clientException: ClientExceptionEntity): string => {
	let message = DEFAULT_DISPLAY_MESSAGE;
	switch (clientException.type) {
		// Please maintain the lexicographic sort order when adding new keys
		case ClientExceptionEntityTypeEnum.AddressInvalid:
			message = 'Die angegebene Adresse ist ungültig.';
			break;
		case ClientExceptionEntityTypeEnum.TenantNotFound:
			message = `Mandant ${detailOrEmpty(clientException)}nicht gefunden.`;
			break;
		case ClientExceptionEntityTypeEnum.EmailAlreadyRegistered:
			message = `Die E-Mail-Adresse ${detailOrEmpty(clientException)}wird schon von einer anderen Person verwendet.`;
			break;
		case ClientExceptionEntityTypeEnum.EmailChangeNotPossible:
			message = `Die E-Mail-Adresse konnte nicht geändert werden.`;
			if (
				clientException.message &&
				clientException.message.indexOf('Next time to change') !== -1 &&
				clientException.detail
			) {
				message += ` Nächstmöglicher Zeitpunkt zum Ändern der E-Mail-Adresse: ${clientException.detail}.`;
			}
			break;
		case ClientExceptionEntityTypeEnum.EventAttributeInvalid:
			// Error not related to user action but to programming bug. Use default message.
			break;
		case ClientExceptionEntityTypeEnum.NotAuthorized:
			message = 'Du hast keinen Zugriff auf diese Funktionalität.';
			break;
		case ClientExceptionEntityTypeEnum.OauthAccountNotFound:
			message = `OAuth-Account für ${detailOrEmpty(clientException)}nicht gefunden.`;
			break;
		case ClientExceptionEntityTypeEnum.OauthEmailAlreadyRegistered:
			message = `Die E-Mail ${detailOrEmpty(clientException)}ist bei Keycloak bereits registriert.`;
			break;
		case ClientExceptionEntityTypeEnum.PasswordChangeNotPossible:
			message = 'Das Passwort konnte nicht zurückgesetzt werden.';
			break;
		case ClientExceptionEntityTypeEnum.PersonAlreadyExists:
			message = `Eine Person mit der OAuth-ID ${detailOrEmpty(clientException)}existiert bereits.`;
			break;
		case ClientExceptionEntityTypeEnum.PersonInformationInvalid:
			// Error not related to user action but to programming bug. The client should check the
			// parameters before sending them. Use default message.
			break;
		case ClientExceptionEntityTypeEnum.PersonNotFound:
			message = `Eine Person mit der ID ${detailOrEmpty(clientException)}konnte nicht gefunden werden.`;
			break;
		case ClientExceptionEntityTypeEnum.PersonWithOauthIdNotFound:
			message = `Eine Person mit der OAuth-ID ${detailOrEmpty(clientException)}konnte nicht gefunden werden.`;
			break;
		case ClientExceptionEntityTypeEnum.RelatedEntityMustNotBeNull:
			// Error not related to user action but to programming bug. Use default message.
			break;
		case ClientExceptionEntityTypeEnum.RelatedEntityNotFound:
			// Error not related to user action but to programming bug. Use default message.
			break;
		case ClientExceptionEntityTypeEnum.RoleManagementNotAllowed:
			message = 'Das Rollen-Management für diese Rolle ist dir nicht erlaubt.';
			break;
		case ClientExceptionEntityTypeEnum.RoleNotFound:
			message = `Die Rolle ${detailOrEmpty(clientException)}konnte nicht gefunden werden.`;
			break;
		case ClientExceptionEntityTypeEnum.UnspecifiedAuthorizationError:
		case ClientExceptionEntityTypeEnum.UnspecifiedBadRequest:
		case ClientExceptionEntityTypeEnum.UnspecifiedError:
		case ClientExceptionEntityTypeEnum.UnspecifiedExternalError:
		case ClientExceptionEntityTypeEnum.UnspecifiedNotFound:
		case ClientExceptionEntityTypeEnum.UnspecifiedServerError:
			// Error not related to user action but to programming bug. Use default message.
			break;
		case ClientExceptionEntityTypeEnum.UserGeneratedContentFlagNotFound:
			message = `Gemeldeten Inhalt mit ID ${detailOrEmpty(clientException)}nicht gefunden.`;
			break;
		case ClientExceptionEntityTypeEnum.WrongCheck:
			message = 'Die Sicherheitabfrage wurde falsch beantwortet.';
			break;
		default:
			// Exception type not yet translated. Using the English exception message as fallback if existing
			if (clientException.message) {
				message = clientException.message;
			}
			break;
	}

	return `${message}${shortCode(clientException)}`;
};

export class ClientExceptionError extends BaseError {
	constructor(public readonly response: ClientExceptionEntity) {
		super(response.message ? response.message : 'Unspecified ClientExceptionError');
	}

	get displayMessage(): string {
		return translate(this.response);
	}
}
