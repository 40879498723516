import React from 'react';
import { ClientGroupExtendedDetail } from '@DigitaleDoerfer/digitale-doerfer-api';
import { Grid, Typography } from '@mui/material';
import { timeService } from '../../../ServiceFactory';
import { TimestampFormat } from '../../../shared/services/Time.service';
import { RoleManagementPermission } from '../../../shared/services/Role.service';

interface Props {
	group: ClientGroupExtendedDetail;
	roleManagementPermission: RoleManagementPermission;
}

const GroupActivitiesView = (props: Props): JSX.Element => {
	const { group } = props;
	// #region TODO (this is mock data to please the compiler)
	const TODO = true;
	const newMembersCount = undefined;

	return (
		<Grid container direction="column" spacing={5}>
			<Grid item xs={12}>
				<Typography variant="h2" gutterBottom>
					Gruppenaktivitäten
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="h4" gutterBottom>
					Mitglieder
				</Typography>

				{group.memberCount !== undefined || newMembersCount !== undefined ? (
					<>
						<Typography variant="body2">
							{group.memberCount !== undefined
								? `${group.memberCount} ${group.memberCount === 1 ? 'Mitglied' : 'Mitglieder'}`
								: undefined}
							{TODO ? undefined : ` (${timeService().parseTimestamp(0, TimestampFormat.DD_MM_YYYY_AT_HH_MM_O_CLOCK)})`}
						</Typography>
						<Typography variant="body2">
							{newMembersCount !== undefined
								? `${newMembersCount} ${newMembersCount === 1 ? 'neues Mitglied' : 'neue Mitglieder'}`
								: undefined}
							{TODO ? undefined : ` (seit ${timeService().parseTimestamp(0, TimestampFormat.DD_MM_YYYY)})`}
						</Typography>
					</>
				) : (
					<Typography component="span" variant="body2">
						Keine Einträge
					</Typography>
				)}
			</Grid>
			<Grid item xs={12}>
				<Typography variant="h4" gutterBottom>
					Letzter Post
				</Typography>
				<Typography component="span" variant="body2">
					{group.lastPost
						? `am ${timeService().parseTimestamp(
								group.lastPost ?? 0,
								TimestampFormat.DD_MM_YYYY_AT_HH_MM_O_CLOCK
						  )} erstellt.`
						: 'Keine Einträge'}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="h4" gutterBottom>
					Letzte Kommentare
				</Typography>
				<Typography component="span" variant="body2">
					{group.lastCommentOfPost
						? `am ${timeService().parseTimestamp(
								group.lastCommentOfPost,
								TimestampFormat.DD_MM_YYYY_AT_HH_MM_O_CLOCK
						  )} verfasst.`
						: 'Keine Einträge'}
				</Typography>
			</Grid>
		</Grid>
	);
};

export default GroupActivitiesView;
