import React from 'react';
import {
	ClientGroupExtendedDetailAccessibilityEnum,
	ClientGroupExtendedDetailContentVisibilityEnum,
	ClientGroupExtendedDetailVisibilityEnum
} from '@DigitaleDoerfer/digitale-doerfer-api';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Box, Grid, Typography } from '@mui/material';
import HoverInfoTooltipView from '../../../shared/views/HoverInfoTooltip.view';

interface Props {
	accessibility: ClientGroupExtendedDetailAccessibilityEnum;
	contentVisibility: ClientGroupExtendedDetailContentVisibilityEnum;
	visibility: ClientGroupExtendedDetailVisibilityEnum;
}

const getAccessibilityIconAndText = (
	accessibility: ClientGroupExtendedDetailAccessibilityEnum
): [() => JSX.Element, string] => {
	switch (accessibility) {
		case ClientGroupExtendedDetailAccessibilityEnum.ApprovalRequired: {
			return [(): JSX.Element => <LockIcon />, 'Private Gruppe'];
		}
		default: {
			return [(): JSX.Element => <LockOpenIcon />, 'Öffentliche Gruppe'];
		}
	}
};

const getContentVisibilityTooltipText = (
	accessibility: ClientGroupExtendedDetailAccessibilityEnum,
	contentVisibility: ClientGroupExtendedDetailContentVisibilityEnum
): string | null => {
	if (accessibility === ClientGroupExtendedDetailAccessibilityEnum.ApprovalRequired) {
		switch (contentVisibility) {
			case ClientGroupExtendedDetailContentVisibilityEnum.Anyone: {
				return 'Der Inhalt ist sichtbar.';
			}
			default: {
				return 'Der Inhalt ist nicht sichtbar.';
			}
		}
	}
	return null;
};

const getVisibilityTooltipText = (visibility: ClientGroupExtendedDetailVisibilityEnum): string => {
	switch (visibility) {
		case ClientGroupExtendedDetailVisibilityEnum.Anyone: {
			return 'Die Gruppe ist global sichtbar.';
		}
		default: {
			return 'Die Mitglieder der Gruppe sind in den Heimatgemeinden sichtbar.';
		}
	}
};

const GroupDetailsGroupTypeView = (props: Props): JSX.Element => {
	const { accessibility, contentVisibility, visibility } = props;
	const [accessibilityIconFn, accessibilityText] = getAccessibilityIconAndText(accessibility);
	const visibilityTooltipText = getVisibilityTooltipText(visibility);
	const contentVisibilityTooltipText = getContentVisibilityTooltipText(accessibility, contentVisibility);
	return (
		<Box display="flex" flexDirection="row" alignItems="center">
			{accessibilityIconFn()}
			{accessibilityText}
			<HoverInfoTooltipView>
				<Box display="flex" flexDirection="column" maxWidth={250}>
					<Typography sx={{ color: 'white' }}>Sichtbarkeit:</Typography>
					<Grid container direction="column" spacing={2}>
						{visibilityTooltipText && (
							<Grid item>
								<Typography variant="body2" sx={{ color: 'white' }}>
									{visibilityTooltipText}
								</Typography>
							</Grid>
						)}
						{contentVisibilityTooltipText && (
							<Grid item>
								<Typography variant="body2" sx={{ color: 'white' }}>
									{contentVisibilityTooltipText}
								</Typography>
							</Grid>
						)}
					</Grid>
				</Box>
			</HoverInfoTooltipView>
		</Box>
	);
};

export default GroupDetailsGroupTypeView;
