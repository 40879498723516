import {
	ClientCommunity,
	ClientGeoArea,
	ClientGeoAreaExtended,
	ClientPersonChangeEmailAddressConfirmation,
	ClientPersonChangeEmailAddressRequest,
	ClientPersonOwn,
	ClientRoleInformation
} from '@DigitaleDoerfer/digitale-doerfer-api';
import { ThunkAction } from 'redux-thunk';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from './store';
import {
	CHANGE_EMAIL_ADDRESS_FAILURE,
	CHANGE_EMAIL_ADDRESS_REQUEST,
	CHANGE_EMAIL_ADDRESS_SUCCESS,
	ChangeEmailAddressFailureAction,
	ChangeEmailAddressRequestAction,
	ChangeEmailAddressSuccessAction,
	GET_GEO_AREA_FAILURE,
	GET_GEO_AREA_REQUEST,
	GET_GEO_AREA_SUCCESS,
	GET_GEO_AREAS_FAILURE,
	GET_GEO_AREAS_REQUEST,
	GET_GEO_AREAS_SUCCESS,
	GetGeoAreaFailureAction,
	GetGeoAreaRequestAction,
	GetGeoAreasFailureAction,
	GetGeoAreasRequestAction,
	GetGeoAreasSuccessAction,
	GetGeoAreaSuccessAction,
	GlobalActionTypes,
	REFRESH_GLOBAL_STATE_FAILURE,
	REFRESH_GLOBAL_STATE_REQUEST,
	REFRESH_GLOBAL_STATE_SUCCESS,
	RefreshGlobalStateFailureAction,
	RefreshGlobalStateRequestAction,
	RefreshGlobalStateSuccessAction
} from './GlobalActionTypes';
import { BaseError } from '../shared/errors/Errors';
import {
	participantsAdminuiGeoareaApi,
	participantsCommunityApi,
	participantsGeoareaApi,
	participantsPersonApi,
	participantsPersonEventsApi,
	participantsRoleAssignmentApi,
	roleService
} from '../ServiceFactory';
import { wrapIntoErrorObject } from '../shared/errors/ErrorWrapper';
import { RoleManagementPermission } from '../shared/services/Role.service';
import {
	showSnackbar,
	showSnackbarError,
	SnackbarType
} from '../shared/views/SnackbarNotification/store/SnackbarNotification.actions';
import { nullSafeGermanStringAscComparator } from '../shared/utils/Util';

export type ThunkResult<R> = ThunkAction<R, PlatformAdminUIState, undefined, GlobalActionTypes>;

export const refreshGlobalStateRequest = (): RefreshGlobalStateRequestAction => {
	return { type: REFRESH_GLOBAL_STATE_REQUEST };
};

export const refreshGlobalStateSuccessAction = (
	allAvailableRoles: ClientRoleInformation[],
	assignableRoles: ClientRoleInformation[],
	tenants: ClientCommunity[],
	roleManagementPermission: RoleManagementPermission,
	person: ClientPersonOwn
): RefreshGlobalStateSuccessAction => {
	return {
		type: REFRESH_GLOBAL_STATE_SUCCESS,
		allAvailableRoles,
		assignableRoles,
		tenants,
		roleManagementPermission,
		person
	};
};

export const refreshGlobalStateFailureAction = (error: BaseError): RefreshGlobalStateFailureAction => {
	return {
		type: REFRESH_GLOBAL_STATE_FAILURE,
		error
	};
};

export const refreshGlobalState = (): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<GlobalActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(refreshGlobalStateRequest());
			const roleManagementPermission: RoleManagementPermission = await roleService().getRoleManagementPermission();
			let allAvailableRoles: ClientRoleInformation[] = await participantsRoleAssignmentApi().getAllAvailableRoles({});
			// Sort roles by displayname alphabetically
			allAvailableRoles = allAvailableRoles.sort(
				nullSafeGermanStringAscComparator((role: ClientRoleInformation) => role.displayName)
			);
			const assignableRoles: ClientRoleInformation[] = await roleService().getAssignableRoles(
				allAvailableRoles,
				roleManagementPermission
			);
			const tenants = await participantsCommunityApi().getAllTenants({});
			const person = await participantsPersonApi().getPerson({});
			dispatch(
				refreshGlobalStateSuccessAction(allAvailableRoles, assignableRoles, tenants, roleManagementPermission, person)
			);
		} catch (error) {
			dispatch(refreshGlobalStateFailureAction(wrapIntoErrorObject(error)));
		}
	};
};

export const getGeoAreaRequest = (geoAreaId: string): GetGeoAreaRequestAction => {
	return {
		type: GET_GEO_AREA_REQUEST,
		geoAreaId
	};
};

export const getGeoAreaSuccess = (geoArea: ClientGeoArea): GetGeoAreaSuccessAction => {
	return {
		type: GET_GEO_AREA_SUCCESS,
		geoArea
	};
};

export const getGeoAreaFailure = (error: BaseError): GetGeoAreaFailureAction => {
	return {
		type: GET_GEO_AREA_FAILURE,
		error
	};
};

export const getGeoArea = (geoAreaId: string): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<GlobalActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(getGeoAreaRequest(geoAreaId));

			const geoArea = await participantsGeoareaApi().getGeoArea({
				geoAreaId
			});

			dispatch(getGeoAreaSuccess(geoArea));
		} catch (error) {
			dispatch(getGeoAreaFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const getGeoAreasRequest = (): GetGeoAreasRequestAction => {
	return {
		type: GET_GEO_AREAS_REQUEST
	};
};

export const getGeoAreasSuccess = (geoAreas: ClientGeoAreaExtended[]): GetGeoAreasSuccessAction => {
	return {
		type: GET_GEO_AREAS_SUCCESS,
		geoAreas
	};
};

export const getGeoAreasFailure = (error: BaseError): GetGeoAreasFailureAction => {
	return {
		type: GET_GEO_AREAS_FAILURE,
		error
	};
};

export const getGeoAreas = (): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<GlobalActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(getGeoAreasRequest());

			const geoAreas = await participantsAdminuiGeoareaApi().getAllGeoAreas({});

			dispatch(getGeoAreasSuccess(geoAreas));
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(getGeoAreasFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const changeEmailAddressRequest = (
	changeEmailAddressRequestParams: ClientPersonChangeEmailAddressRequest
): ChangeEmailAddressRequestAction => {
	return {
		type: CHANGE_EMAIL_ADDRESS_REQUEST,
		changeEmailAddressRequestParams
	};
};

export const changeEmailAddressSuccess = (person: ClientPersonOwn): ChangeEmailAddressSuccessAction => {
	return {
		type: CHANGE_EMAIL_ADDRESS_SUCCESS,
		person
	};
};

export const changeEmailAddressFailure = (error: BaseError): ChangeEmailAddressFailureAction => {
	return {
		type: CHANGE_EMAIL_ADDRESS_FAILURE,
		error
	};
};

export const changeEmailAddress = (
	changeEmailAddressRequestParams: ClientPersonChangeEmailAddressRequest
): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<GlobalActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(changeEmailAddressRequest(changeEmailAddressRequestParams));

			const response: ClientPersonChangeEmailAddressConfirmation =
				await participantsPersonEventsApi().onPersonChangeEmailAddressRequest({
					clientPersonChangeEmailAddressRequest: changeEmailAddressRequestParams
				});
			// See DD-10984: TEMPORAL Uncomment block 1 and remove block 2 when DD-10946 its done

			// BLOCK 1
			/*
			const userWithNewEmail: ClientPersonOwn = {
				...response.person
			};
			dispatch(changeEmailAddressSuccess(userWithNewEmail));
			dispatch(
				showSnackbar({
					type: SnackbarType.SNACKBAR_SUCCESS,
					message: 'Email wurde erfolgreich geändert.'
				})
			);
			*/
			// START BLOCK 2
			if (response.person) {
				const userWithNewEmail: ClientPersonOwn = {
					...response.person
				};
				dispatch(changeEmailAddressSuccess(userWithNewEmail));
				dispatch(
					showSnackbar({
						type: SnackbarType.SNACKBAR_SUCCESS,
						message: 'Email wurde erfolgreich geändert.'
					})
				);
			} else {
				dispatch(
					showSnackbarError(
						wrapIntoErrorObject(
							'Email wurde erfolgreich geändert aber die Ansicht muss jedoch manuell aktualisiert werden.'
						)
					)
				);
			}
			// END BLOCK 2
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(changeEmailAddressFailure(wrapIntoErrorObject(error)));
		}
	};
};
