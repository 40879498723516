import { connect } from 'react-redux';
import React, { Component } from 'react';
import RejectFlagActionView from './RejectFlagAction.view';
import { changeFlagStatus } from '../../store/FlaggedContent.actions';
import { UserActionTypes } from '../../../users/store/UserActionTypes';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../../store/store';
import { ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum } from '@DigitaleDoerfer/digitale-doerfer-api';

interface Props extends ActionMenuItemProps {
	flagId: string;
	changeFlagStatus: (
		flagId: string,
		newStatus: ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum,
		comment: string,
		snackbarMessage?: string,
		returnToTable?: boolean
	) => void;
}

class RejectFlagActionContainer extends Component<Props> {
	constructor(props: Props) {
		super(props);
		this.handleStatusChangeAction = this.handleStatusChangeAction.bind(this);
	}

	handleStatusChangeAction(comment: string): void {
		const { flagId, changeFlagStatus } = this.props;
		changeFlagStatus(
			flagId,
			ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum.Rejected,
			comment,
			'Fall abgeschlossen. Status wurde auf "Abgelehnt" gesetzt, da der Inhalt unkritisch ist.',
			true
		);
	}

	render(): JSX.Element {
		const { renderMenuItem } = this.props;
		return (
			<RejectFlagActionView renderMenuItem={renderMenuItem} handleStatusChangeAction={this.handleStatusChangeAction} />
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		flagId: state.flaggedContent.detail.flagId
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<UserActionTypes>) => {
	return {
		changeFlagStatus: (
			flagId: string,
			newStatus: ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum,
			comment: string,
			snackbarMessage?: string,
			returnToTable?: boolean
		): Promise<void> => dispatch(changeFlagStatus(flagId, newStatus, comment, snackbarMessage, returnToTable))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RejectFlagActionContainer);
