import React, { Component, FormEvent } from 'react';
import { Box, Grid, Paper } from '@mui/material';

import {
	ClientMediaItem,
	ClientNewsSourceCreateRequest,
	ClientTemporaryMediaItem
} from '@DigitaleDoerfer/digitale-doerfer-api';
import ConfirmButton from '../../../shared/views/Buttons/ConfirmButton.view';
import ResetButton from '../../../shared/views/Buttons/ResetButton.view';
import CancelButton from '../../../shared/views/Buttons/CancelButton.view';
import FormHeaderView from '../../../shared/views/PersonDetail/FormHeader.view';
import NewsSourcesWebsiteNameView from '../../../shared/views/NewsSources/NewsSourcesWebsiteName.view';
import NewsSourcesWebsiteUrlView from '../../../shared/views/NewsSources/NewsSourcesWebsiteURL.view';
import NewsSourcesChangeNotesView from '../../../shared/views/NewsSources/NewsSourcesChangeNotes.view';
import NewsSourcesChooseAppID from '../../../shared/views/NewsSources/NewsSourcesAppID.view';
import EditableImage from '../../../shared/views/NewsSources/EditableImage';
import DefaultImage from '../../../assets/svg/DefaultImage.svg';
import { isValidUrl } from '../../../shared/utils/Util';

interface Props {
	loading: boolean;
	newsSourceHasBeenCreated: boolean;
	cancelHandler: () => void;
	dispatchCreateNewsSource: (newsSourcesRequestData: ClientNewsSourceCreateRequest) => void;
}

interface State {
	appId: string;
	websiteName: string;
	newsSourcesURL: string;
	newsSourcesCreatingTime: string;
	newsSourceschangeNotes: string;
	temporaryMediaItemId: string | undefined;
	image: ClientMediaItem | undefined;
	isUrlValid: boolean;
}

const initialState: State = {
	appId: '',
	websiteName: '',
	newsSourcesURL: '',
	newsSourcesCreatingTime: '',
	newsSourceschangeNotes: '',
	temporaryMediaItemId: '',
	image: undefined,
	isUrlValid: true
};

const DefaultImageIcon = <img src={DefaultImage} loading="lazy" height="100rem" alt="News-Source Logo" />;

class NewsSourcesCreateView extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = initialState;
		this.handleSelectionChange = this.handleSelectionChange.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleNewsSourcesWebsiteNameChange = this.handleNewsSourcesWebsiteNameChange.bind(this);
		this.handleNewsSourcesURLChange = this.handleNewsSourcesURLChange.bind(this);
		this.handleNewsSourcesCreatingTimeChange = this.handleNewsSourcesCreatingTimeChange.bind(this);
		this.handleNewsSourceschangeNotesChange = this.handleNewsSourceschangeNotesChange.bind(this);
		this.handleImageChange = this.handleImageChange.bind(this);
		this.handleNewsSourcesAppIDChange = this.handleNewsSourcesAppIDChange.bind(this);
	}

	componentDidUpdate(prevProps: Props): void {
		const { newsSourceHasBeenCreated } = this.props;
		// reset form if newsSource was created
		if (!prevProps.newsSourceHasBeenCreated && newsSourceHasBeenCreated) {
			this.resetHandler();
		}
	}

	handleSelectionChange(event: React.ChangeEvent<{ name?: string; value: unknown }>): void {
		const target = event.target;
		const value = target.value;
		const name = `${target.name || ''}`;
		this.setState({ [name]: value } as Pick<State, keyof State>);
	}

	handleInputChange(event: React.ChangeEvent<{ name?: string; value: unknown }>): void {
		const target = event.target;
		const value = target.value;
		const name = `${target.name || ''}`;
		this.setState({ [name]: value } as Pick<State, keyof State>);
	}

	handleSubmit(event: FormEvent<HTMLFormElement>): void {
		event.preventDefault();
		const { dispatchCreateNewsSource } = this.props;
		const { appId, websiteName, newsSourcesURL, newsSourceschangeNotes, temporaryMediaItemId } = this.state;
		//Prepare newsSource data request
		if (temporaryMediaItemId) {
			const newsSourceData: ClientNewsSourceCreateRequest = {
				appId,
				siteName: websiteName,
				siteUrl: newsSourcesURL,
				changeNotes: newsSourceschangeNotes,
				temporaryMediaItemId
			};
			dispatchCreateNewsSource(newsSourceData);
		}
	}

	handleNewsSourcesWebsiteNameChange(newWebsiteName: string): void {
		this.setState({ websiteName: newWebsiteName });
	}

	handleNewsSourcesURLChange(newURL: string): void {
		const urlValid: boolean = isValidUrl(newURL);
		this.setState({ isUrlValid: urlValid, newsSourcesURL: newURL });
	}

	handleNewsSourcesCreatingTimeChange(newCreatingTime: string): void {
		this.setState({ newsSourcesCreatingTime: newCreatingTime });
	}

	handleNewsSourceschangeNotesChange(newchangeNotes: string): void {
		this.setState({ newsSourceschangeNotes: newchangeNotes });
	}

	handleNewsSourcesAppIDChange(newAppId: string): void {
		this.setState({ appId: newAppId });
	}

	resetHandler = (): void => {
		this.setState(initialState);
	};

	handleImageChange(temporaryMediaItem: ClientTemporaryMediaItem): void {
		if (temporaryMediaItem) {
			this.setState({ image: temporaryMediaItem.mediaItem });
		}
		this.setState({ temporaryMediaItemId: temporaryMediaItem.id });
	}

	render(): JSX.Element {
		const { loading, cancelHandler } = this.props;
		const { appId, websiteName, newsSourcesURL, newsSourceschangeNotes, image, isUrlValid } = this.state;
		const submitDisabled = !(appId && websiteName && newsSourcesURL && newsSourceschangeNotes && image && isUrlValid);
		return (
			<Paper hidden={loading}>
				<form onSubmit={this.handleSubmit}>
					<Box mt={1}>
						<FormHeaderView title={'Create NewsSource'} />
						<Box display={'flex'} justifyContent={'right'}>
							<EditableImage
								clientMediaItem={image ?? undefined}
								defaultImage={DefaultImageIcon}
								onChange={this.handleImageChange}
							/>
						</Box>
						<Box marginTop={2}>
							<Grid container spacing={3}>
								<Grid item xs={12} md={6}>
									<NewsSourcesWebsiteNameView
										websiteName={websiteName}
										newsSourcesWebsiteNameChange={this.handleNewsSourcesWebsiteNameChange}
									></NewsSourcesWebsiteNameView>
								</Grid>
								<Grid item xs={12} md={6}>
									<NewsSourcesWebsiteUrlView
										newsSourcesURL={newsSourcesURL}
										urlValid={isUrlValid}
										newsSourcesURLChange={this.handleNewsSourcesURLChange}
									></NewsSourcesWebsiteUrlView>
								</Grid>
								<Grid item xs={12} md={6}>
									<NewsSourcesChangeNotesView
										newsSourceschangeNotes={newsSourceschangeNotes}
										newsSourceschangeNotesChange={this.handleNewsSourceschangeNotesChange}
									></NewsSourcesChangeNotesView>
								</Grid>
								<Grid item xs={12} md={6}>
									<NewsSourcesChooseAppID
										newsSourcesAppId={appId}
										newsSourcesAppIDChange={this.handleNewsSourcesAppIDChange}
									></NewsSourcesChooseAppID>
								</Grid>

								<Grid item xs={12} container spacing={2}>
									<Grid item>
										<ConfirmButton disabled={submitDisabled} submit={true}>
											Create News-Source
										</ConfirmButton>
									</Grid>
									<Grid item>
										<ResetButton onClick={this.resetHandler}>Zurücksetzen</ResetButton>
									</Grid>
									<Grid item>
										<CancelButton onClick={cancelHandler}>Abbrechen</CancelButton>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Box>
				</form>
			</Paper>
		);
	}
}
export default NewsSourcesCreateView;
