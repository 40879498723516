import React from 'react';
import { Grid, IconButton, Input, InputLabel, MenuItem, Paper, Select, SelectChangeEvent } from '@mui/material';
import { ClientPersonReferenceWithEmail, ClientUserGeneratedContentFlag } from '@DigitaleDoerfer/digitale-doerfer-api';
import { initialFlaggedContentState, ListSearchParams } from './store/FlaggedContent.state';
import { BaseError } from '../../shared/errors/Errors';
import BarLoadingIndicator from '../../shared/views/LoadingIndicators/BarLoadingIndicator.view';
import { flaggedContentService, flaggedContentViewService, timeService } from '../../ServiceFactory';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import {
	INCLUDED_STATUS_MAPPING_UNRESOLVED,
	IncludedStatusMapping,
	includedStatusMappings
} from './services/FlaggedContent.service';
import { TimestampFormat } from '../../shared/services/Time.service';
import { TableCell, TableColumn } from '../../shared/views/Table/TableColumn';
import TableView from '../../shared/views/Table/Table.view';
import TablePaginationView from '../../shared/views/Table/TablePagination.view';
import ReloadButton from '../../shared/views/Buttons/ReloadButton.view';
import { TableColumnSortingDirection, TableSorting } from '../../shared/views/Table/TableSorting';
import PersonNameWithAvatarPicture from '../../shared/views/PersonDetail/PersonNameWithAvatarPicture.view';
import PersonNameWithAvatarPicClickable from '../../shared/views/PersonDetail/PersonNameWithAvatarPicClickable.view';
import { Link } from 'react-router-dom';
import OpenInNewSharpIcon from '@mui/icons-material/OpenInNewSharp';
import { getFlaggedContentsDetailsUrlWithEntityTypeAndId } from './FlaggedContentRouting.container';

const tableColumnFlaggedContentWidth = 205;
const tableColumnAuthorWidth = 200;
const tableColumnTenantWidth = 175;
const tableColumnStatusWidth = 115;
const tableColumnLastStatusUpdateWidth = 185;
const tableColumnCreatorWidth = 200;

// this column should be stretched
const tableColumnReasonUpdateMinWidth = 500;

// sum up column widths to determine minimum width of table
const tableMinWidth = [
	tableColumnFlaggedContentWidth,
	tableColumnAuthorWidth,
	tableColumnTenantWidth,
	tableColumnStatusWidth,
	tableColumnLastStatusUpdateWidth,
	tableColumnCreatorWidth
].reduce((a, b) => a + b, tableColumnReasonUpdateMinWidth);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => {
	return createStyles({
		table: {
			/* The reason column should be the only column that stretches and it should have a minimum width.
			However, CSS minWidth does not work on columns, which is why we force the table to have a minimum
			width forcing it to fill the extra space with the single remaining non-width-constrained column. */
			minWidth: tableMinWidth
		},
		reloadButtonContainer: {
			margin: '14px 14px auto !important;'
		}
	});
};

interface Props extends WithStyles<typeof styles> {
	flaggedContents: ClientUserGeneratedContentFlag[];
	loading: boolean;
	error: BaseError | null;
	searchParams: ListSearchParams;
	getFlaggedContents: (params: ListSearchParams) => void;
	viewFlaggedContentDetailsHandler: (flaggedContentId: string, flaggedContentEntityType: string) => void;
	openNewFlaggedContentDetailsTab: (flaggedContentId: string, flaggedContentEntityType: string) => void;
}

const renderPerson = (person: ClientPersonReferenceWithEmail | undefined, width: number): React.ReactElement | null => {
	return <PersonNameWithAvatarPicture person={person} width={width} textVariant="h6" />;
};

const renderPersonLink = (
	cell: TableCell<ClientUserGeneratedContentFlag>,
	width: number
): React.ReactElement | null => {
	return <PersonNameWithAvatarPicClickable cell={cell} width={width} textVariant="h6" />;
};

const renderAuthor = (cell: TableCell<ClientUserGeneratedContentFlag>): React.ReactElement | null => {
	if (cell.column.clickable) {
		return renderPersonLink(cell, tableColumnAuthorWidth);
	} else {
		return renderPerson(cell.row.entityAuthor, tableColumnAuthorWidth);
	}
};

const renderCreator = (cell: TableCell<ClientUserGeneratedContentFlag>): React.ReactElement | null => {
	const { flagCreator } = cell.row;

	if (!flagCreator) {
		// If flagCreator is null, consider it AI generated
		return <span style={{ backgroundColor: 'pink', padding: '4px', borderRadius: '4px' }}>KI-generiert</span>;
	} else {
		if (cell.column.clickable) {
			return renderPersonLink(cell, tableColumnCreatorWidth);
		} else {
			return renderPerson(cell.row.flagCreator, tableColumnCreatorWidth);
		}
	}
};

const getLink = (flaggedContentId: string, flaggedContentEntityType: string): string => {
	return getFlaggedContentsDetailsUrlWithEntityTypeAndId(
		flaggedContentService().getShortEntityTypeName(flaggedContentEntityType),
		flaggedContentId
	);
};
const renderShowReportButtons = (cell: TableCell<ClientUserGeneratedContentFlag>): React.ReactElement | null => {
	if (cell.column.clickable) {
		if (cell.row.entityType !== undefined) {
			return (
				<Link to={getLink(cell.row.id, cell.row.entityType)}>
					<IconButton aria-label="open" size="large">
						<OpenInNewSharpIcon fontSize="small" />
					</IconButton>
				</Link>
			);
		} else {
			return <span></span>;
		}
	} else {
		return <span></span>;
	}
};

const columns: readonly TableColumn<ClientUserGeneratedContentFlag>[] = [
	{
		id: 'showReportButtons',
		headerLabel: '',
		clickable: true,
		cellStyles: {
			width: tableColumnCreatorWidth
		},
		getCellStringValue: (): string => {
			return '';
		},

		renderCell: renderShowReportButtons,
		filterable: true,
		sortable: true
	},
	{
		id: 'flaggedContent',
		headerLabel: 'Gemeldeter Inhalt',
		clickable: false,
		cellStyles: {
			width: tableColumnFlaggedContentWidth
		},
		getCellStringValue: (flaggedContent: ClientUserGeneratedContentFlag): string => {
			if (flaggedContent.entityType) {
				return flaggedContentViewService().getEntityTypeDisplayName(flaggedContent.entityType);
			}

			return '';
		},
		filterable: true,
		sortable: true
	},
	{
		id: 'author',
		headerLabel: 'Erstellt von',
		clickable: true,
		cellStyles: {
			width: tableColumnAuthorWidth
		},
		getCellStringValue: (flaggedContent: ClientUserGeneratedContentFlag): string => {
			const { entityAuthor } = flaggedContent;

			if (!entityAuthor) {
				return '';
			}

			if (entityAuthor.deleted) {
				return 'Gelöschte Person';
			}

			if (entityAuthor.firstName?.trim() || entityAuthor.lastName?.trim()) {
				return `${entityAuthor.firstName || ''} ${entityAuthor.lastName || ''}`;
			}

			return '';
		},
		renderCell: renderAuthor,
		filterable: true,
		sortable: true
	},
	{
		id: 'reason',
		// we are planning to show the reason here, but currently its actually the last comment of this case
		headerLabel: 'Letzter Kommentar',
		// Setting a maxWidth on a table cell prevents it from growing due to its text content but still allows growing due to the width of the table.
		cellStyles: { maxWidth: 0 },
		clickable: false,
		getCellStringValue: (flaggedContent: ClientUserGeneratedContentFlag): string =>
			flaggedContent.lastStatusRecord?.comment ?? '',
		filterable: true,
		sortable: false
	},
	{
		id: 'tenant',
		headerLabel: 'Mandant',
		clickable: false,
		cellStyles: {
			width: tableColumnTenantWidth
		},
		getCellStringValue: (flaggedContent: ClientUserGeneratedContentFlag): string => flaggedContent.tenant?.name ?? '',
		filterable: true,
		sortable: true
	},
	{
		id: 'lastStatusUpdate',
		headerLabel: 'Letztes Update',
		clickable: false,
		cellStyles: {
			width: tableColumnLastStatusUpdateWidth
		},
		getCellStringValue: (flaggedContent: ClientUserGeneratedContentFlag): string =>
			flaggedContent.lastStatusUpdate
				? timeService().parseTimestamp(flaggedContent.lastStatusUpdate, TimestampFormat.DD_MM_YYYY_HH_MM)
				: '',
		initialSortingDirection: TableColumnSortingDirection.DESC,
		filterable: true,
		sortable: true
	},
	{
		id: 'creator',
		headerLabel: 'Gemeldet von',
		clickable: true,
		cellStyles: {
			width: tableColumnCreatorWidth
		},
		getCellStringValue: (flaggedContent: ClientUserGeneratedContentFlag): string => {
			const { flagCreator } = flaggedContent;

			if (!flagCreator) {
				return '';
			}

			if (flagCreator.deleted) {
				return 'Gelöschte Person';
			}

			if (flagCreator.firstName?.trim() || flagCreator.lastName?.trim()) {
				return `${flagCreator.firstName || ''} ${flagCreator.lastName || ''}`;
			}

			return '';
		},
		renderCell: renderCreator,
		filterable: true,
		sortable: true
	}
];

const paginationRowsPerPageOptions = [
	initialFlaggedContentState.list.searchParams.pagination.rowsPerPage,
	50,
	100,
	200
] as const;

interface State {
	newSearchParams: ListSearchParams;
	typingTimeout?: NodeJS.Timeout;
}

class FlaggedContentListView extends React.Component<Props, State> {
	searchNeedsUpdate = false;

	constructor(props: Props) {
		super(props);
		const { searchParams } = props;
		this.state = {
			newSearchParams: searchParams,
			typingTimeout: undefined
		};
		this.updateSearch = this.updateSearch.bind(this);
		this.handleChangePage = this.handleChangePage.bind(this);
		this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
		this.handleSortingChange = this.handleSortingChange.bind(this);
		this.handleIncludedStatusSelectionChange = this.handleIncludedStatusSelectionChange.bind(this);
		this.handleReloadButtonClick = this.handleReloadButtonClick.bind(this);
	}

	updateSearch(): void {
		if (!this.props.loading) {
			this.searchNeedsUpdate = false;

			const { getFlaggedContents } = this.props;

			const { newSearchParams } = this.state;

			getFlaggedContents(newSearchParams);
		} else {
			this.searchNeedsUpdate = true;
		}
	}

	componentDidMount(): void {
		this.updateSearch();
	}

	componentDidUpdate(): void {
		if (this.searchNeedsUpdate) {
			this.updateSearch();
		}
	}

	handleChangePage(newPage: number): void {
		this.setState((state: State): State => {
			return {
				...state,
				newSearchParams: {
					...state.newSearchParams,
					pagination: { ...state.newSearchParams.pagination, page: newPage }
				}
			};
		}, this.updateSearch);
	}

	handleChangeRowsPerPage(newRowsPerPage: number): void {
		this.setState((state: State): State => {
			return {
				...state,
				newSearchParams: {
					...state.newSearchParams,
					pagination: { ...state.newSearchParams.pagination, rowsPerPage: newRowsPerPage, page: 0 }
				}
			};
		}, this.updateSearch);
	}

	handleSortingChange(newSorting: TableSorting): void {
		this.setState((state: State): State => {
			return {
				...state,
				newSearchParams: {
					...state.newSearchParams,
					pagination: { ...state.newSearchParams.pagination, page: 0 },
					sorting: newSorting
				}
			};
		}, this.updateSearch);
	}

	handleReloadButtonClick(): void {
		this.updateSearch();
	}

	async handleIncludedStatusSelectionChange(event: SelectChangeEvent<string>): Promise<void> {
		const value: IncludedStatusMapping =
			flaggedContentService().getIncludedStatusMappingByKey(`${event.target.value}`) ??
			INCLUDED_STATUS_MAPPING_UNRESOLVED;

		this.setState((state: State): State => {
			return {
				...state,
				newSearchParams: {
					...state.newSearchParams,
					includedStatus: value,
					pagination: { ...state.newSearchParams.pagination, page: 0 }
				}
			};
		}, this.updateSearch);
	}

	getKeyForRow(flaggedContent: ClientUserGeneratedContentFlag): React.Key {
		if (!flaggedContent.id) {
			console.warn('no id defined for ' + flaggedContent.toString());
			return JSON.stringify(flaggedContent);
		}

		return flaggedContent.id;
	}

	renderTable(): JSX.Element {
		const { flaggedContents, searchParams, classes } = this.props;
		const { sorting } = searchParams;

		return (
			<TableView<ClientUserGeneratedContentFlag>
				className={classes.table}
				columns={columns}
				rows={flaggedContents}
				getKeyForRow={this.getKeyForRow}
				sorting={sorting}
				handleSortingChange={this.handleSortingChange}
			/>
		);
	}

	renderPagination(): JSX.Element {
		const { searchParams } = this.props;
		const { page, rowsPerPage, total } = searchParams.pagination;

		return (
			<TablePaginationView
				totalRows={total}
				rowLabelPlural="Beiträge"
				page={page}
				rowsPerPage={rowsPerPage}
				rowsPerPageOptions={paginationRowsPerPageOptions}
				handleChangePage={this.handleChangePage}
				handleChangeRowsPerPage={this.handleChangeRowsPerPage}
			/>
		);
	}

	render(): JSX.Element {
		const { loading, classes } = this.props;
		const { includedStatus: newIncludedStatus } = this.state.newSearchParams;
		return (
			<Paper>
				<BarLoadingIndicator loading={loading} />
				<Grid container spacing={2}>
					<Grid item xs={12} md={4}>
						<InputLabel htmlFor="includedStatus">Status auswählen</InputLabel>
						<Select
							variant="standard"
							name="includedStatus"
							value={`${newIncludedStatus.key}`}
							onChange={this.handleIncludedStatusSelectionChange}
							input={<Input id="includedStatus" />}
							fullWidth
						>
							{includedStatusMappings.map(mapping => {
								return (
									<MenuItem key={mapping.key} value={mapping.key}>
										{mapping.displayName} {mapping.explanation && `(${mapping.explanation})`}
									</MenuItem>
								);
							})}
						</Select>
					</Grid>
					<Grid item className={classes.reloadButtonContainer}>
						<ReloadButton onClick={this.handleReloadButtonClick}>Neu Laden</ReloadButton>
					</Grid>
					<Grid item xs={12}>
						{this.renderTable()}
						{this.renderPagination()}
					</Grid>
				</Grid>
			</Paper>
		);
	}
}
export default withStyles(styles)(FlaggedContentListView);
