import React, { Component } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { ClientOauthAccount, ClientOauthAccountBlockReasonEnum } from '@DigitaleDoerfer/digitale-doerfer-api';
import { ReadOnlyTextFieldView } from '../../../shared/views/ReadOnlyTextField.view';

interface Props {
	email: string;
	loading: boolean;
	selectedUser: ClientOauthAccount;
}

interface State {
	showMoreDetails: boolean;
}

class AuthDetailsView extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { showMoreDetails: false };
	}

	render(): JSX.Element {
		const { email, loading, selectedUser } = this.props;
		if (loading) {
			return <CircularProgress />;
		}
		// Process form data
		const oauthAccount = selectedUser;

		const { name, loginCount } = oauthAccount;

		let { statusMessage, oauthId } = {
			statusMessage: '',
			oauthId: 'Keine OAuth-Information vorhanden'
		};

		if (oauthAccount) {
			if (oauthAccount.blockReason !== null && oauthAccount.blockReason !== undefined) {
				if (oauthAccount.blockReason === ClientOauthAccountBlockReasonEnum.ManuallyBlocked) {
					statusMessage = 'Person gesperrt (vom Support-Team).';
				} else if (oauthAccount.blockReason === ClientOauthAccountBlockReasonEnum.TooManyLoginAttempts) {
					statusMessage = 'Person gesperrt (zu viele Login-Versuche).';
				}
			}
			if (
				oauthAccount.retrievalFailed !== null &&
				oauthAccount.retrievalFailed !== undefined &&
				oauthAccount.retrievalFailed === false
			) {
				oauthId = oauthAccount.oauthId || '';
			} else {
				oauthId = 'Beim Verbinden von Keyclaok ist ein Problem aufgetreten';
			}
		}

		return (
			<>
				{statusMessage && (
					<Box mb={3}>
						<Typography color="error" gutterBottom>
							{statusMessage}
						</Typography>
					</Box>
				)}
				<Grid container spacing={3} justifyContent="flex-start" alignItems="flex-end">
					<Grid item xs={12} md={6}>
						<ReadOnlyTextFieldView
							id="email"
							name="email"
							label="Email"
							value={email}
							InputProps={{
								readOnly: true
							}}
							InputLabelProps={{
								shrink: true
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<ReadOnlyTextFieldView
							id="oauthAccount"
							name="oauthAccount"
							label="OAuth-ID"
							value={oauthId || ''}
							InputProps={{ readOnly: true }}
							InputLabelProps={{
								shrink: true
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography>
							{loginCount && loginCount > 0
								? `${name || email} hat sich ${loginCount}-mal eingeloggt.`
								: `${name || email} hat sich noch nicht eingeloggt.`}
						</Typography>
					</Grid>
				</Grid>
			</>
		);
	}
}
export default AuthDetailsView;
