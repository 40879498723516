import {
	BASE_PATH,
	BaseAPI,
	FetchParams,
	Middleware,
	RequestContext,
	ResponseContext
} from '@DigitaleDoerfer/digitale-doerfer-api/runtime';
import { BACKEND_URL } from './AuthSettings';
import { ClientExceptionError } from '../../shared/errors/Errors';
import { ClientExceptionEntity } from '@DigitaleDoerfer/digitale-doerfer-api';
import { getAccessToken } from '../../modules/auth/authToken';

const AUTHORIZATION_HEADER = 'Authorization';
const APP_VARIANT_ID_HEADER = 'appVariantIdentifier';
const APP_VARIANT_ID = 'de.fhg.iese.dd.platform.adminui';

class DigitaleDoerferMiddleware implements Middleware {
	private static createAuthenticationHeader(token: string): string {
		return 'Bearer ' + token;
	}

	async pre(context: RequestContext): Promise<FetchParams | void> {
		const { init, url } = context;
		const accessToken = getAccessToken();
		if (!accessToken) return;

		const authenticationHeader = DigitaleDoerferMiddleware.createAuthenticationHeader(accessToken);
		const headers = new Headers(init.headers);
		headers.set(AUTHORIZATION_HEADER, authenticationHeader);
		headers.set(APP_VARIANT_ID_HEADER, APP_VARIANT_ID);
		init.headers = headers;
		const newUrl = BACKEND_URL + url.substr(BASE_PATH.length + 1);
		return {
			init,
			url: newUrl
		};
	}

	async post(context: ResponseContext): Promise<Response | void> {
		if (context.response.status >= 400) {
			// Server returned with HTTP error code, so response is ClientException.
			// If for certain cases, this is not true, add handling here.
			const errorResponse: ClientExceptionEntity = await context.response.json();

			// Note that here it is assumed that the underlying, autogenerated code of runtime.ts
			// does not "swallow" the exception.
			// See https://erbenschell.iese.fraunhofer.de/DigitaleDoerfer/Web/platform-admin-ui/-/tags/advanced-error-handling
			// for a more advanced version.
			throw new ClientExceptionError(errorResponse);
		}
	}
}

class DigitaleDoerferAPIFactory {
	private static middleWare: Middleware;

	public static createMiddleware(): void {
		DigitaleDoerferAPIFactory.middleWare = new DigitaleDoerferMiddleware();
	}

	public static createDigitaleDoerferAPI<T extends BaseAPI>(api: T): T {
		if (!DigitaleDoerferAPIFactory.middleWare) {
			throw new Error('Middleware not initialised!');
		}
		return api.withMiddleware(DigitaleDoerferAPIFactory.middleWare);
	}
}

export default DigitaleDoerferAPIFactory;
