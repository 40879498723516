import React from 'react';
import { Grid, Input, InputLabel, MenuItem, Select, SelectChangeEvent, Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { ClientRoleInformation } from '@DigitaleDoerfer/digitale-doerfer-api';
import { EmptyField } from '../store/User.state';
import SearchFieldView from '../../../shared/views/SearchField.view';
import ResetButton from '../../../shared/views/Buttons/ResetButton.view';
import { searchUserPlaceHolder } from '../../../shared/views/HelperTexts';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => {
	return createStyles({
		resetButtonContainer: {
			paddingTop: '21px !important',
			height: '65px !important'
		}
	});
};

interface Props extends WithStyles<typeof styles> {
	allAvailableRoles: ClientRoleInformation[];
	text: string;
	role: string;
	handleTextChange: (searchCriteria: string) => void;
	handleRoleChange: (role: string) => void;
	handleReset: () => void;
}

interface State {
	newText: string;
	newRole: string;
}

class UserListSearchAndFilterView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		const { text, role } = this.props;
		this.state = { newText: text, newRole: role };
		this.handleTextInstantChange = this.handleTextInstantChange.bind(this);
		this.handleRoleChange = this.handleRoleChange.bind(this);
		this.handleReset = this.handleReset.bind(this);
	}

	handleTextInstantChange(text: string): void {
		this.setState({ newText: text });
	}

	handleRoleChange(event: SelectChangeEvent<string>): void {
		const value = `${event.target.value || ''}`;

		this.setState({ newRole: value }, () => this.props.handleRoleChange(value));
	}

	handleReset(): void {
		this.setState({ newText: '', newRole: EmptyField.NONE }, () => this.props.handleReset());
	}

	render(): JSX.Element {
		const { allAvailableRoles, handleTextChange: handleSearchTextChange, classes } = this.props;
		const { newText, newRole } = this.state;
		const showResetButton = newText || newRole !== EmptyField.NONE;
		return (
			<>
				<Grid item xs={12} md={4}>
					<SearchFieldView
						handleDeferredChange={handleSearchTextChange}
						handleInstantChange={this.handleTextInstantChange}
						label={'Person suchen'}
						placeHolder={
							newRole === EmptyField.NONE ? searchUserPlaceHolder : 'Suche verfügbar, wenn keine Rolle ausgewählt ist.'
						}
						value={newText}
						disabled={newRole !== EmptyField.NONE}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<InputLabel htmlFor="role">Rolle auswählen</InputLabel>
					<Select
						variant="standard"
						name="role"
						value={newRole}
						onChange={this.handleRoleChange}
						input={<Input id="role" />}
						disabled={!!newText}
						fullWidth
					>
						<MenuItem value={EmptyField.NONE}>Keine</MenuItem>
						{allAvailableRoles.map((role: ClientRoleInformation) => {
							return (
								<MenuItem key={role.key} value={role.key}>
									{role.displayName}
								</MenuItem>
							);
						})}
					</Select>
				</Grid>
				<Grid item xs={12} md={4} className={classes.resetButtonContainer}>
					{showResetButton && <ResetButton onClick={this.handleReset}>Zurücksetzen</ResetButton>}
				</Grid>
			</>
		);
	}
}

export default withStyles(styles)(UserListSearchAndFilterView);
