import React, { ReactNode } from 'react';
import { Grid, TextField, Theme, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import ResetButton from '../Buttons/ResetButton.view';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { ClientCommunity } from '@DigitaleDoerfer/digitale-doerfer-api';
import { CreateFilterOptionsConfig } from '@mui/material/useAutocomplete';
import { EmptyField } from '../../../modules/users/store/User.state';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => {
	return createStyles({
		resetButtonContainer: {
			paddingTop: '21px !important',
			height: '65px !important'
		}
	});
};

interface Props extends WithStyles<typeof styles> {
	searchedTenant: string;
	tenants: ClientCommunity[];
	handleSearchTenantChange: (tenant: ClientCommunity) => void;
	handleReset: () => void;
}

interface State {
	newText: string;
	open: boolean;
}

class TenantsAutocompleteView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		const { searchedTenant } = this.props;
		const tenantName = this.props.tenants.find(tenant => tenant.id === searchedTenant)?.name ?? '';
		this.state = { newText: tenantName, open: false };
		this.handleOpen = this.handleOpen.bind(this);
		this.handleReset = this.handleReset.bind(this);
		this.handleTextInstantChange = this.handleTextInstantChange.bind(this);
		this.handleSelectedAutocompleteChange = this.handleSelectedAutocompleteChange.bind(this);
	}

	handleReset(): void {
		const { handleReset } = this.props;
		this.setState({ newText: '' }, () => handleReset());
	}

	handleTextInstantChange(event: React.ChangeEvent<{ value: string }>): void {
		const value = `${event.target.value || ''}`;
		this.setState({ newText: value });
	}

	/**
	 * Handle the selected autocomplete tenant.
	 * @param event The event source of the callback.
	 * @param selectedTenant The autocomplete selected tenant.
	 * @param reason  One of "create-option", "select-option", "remove-option", "blur" or "clear".
	 */
	handleSelectedAutocompleteChange(
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		event: object,
		selectedTenant: ClientCommunity | null
	): void {
		const { handleSearchTenantChange } = this.props;
		if (selectedTenant) {
			handleSearchTenantChange(selectedTenant);
			this.setState({ newText: selectedTenant.name ?? '' });
		}
	}

	/**
	 * Changes the state to open the autocomplete options list.
	 * @param open True to open, otherwise, close it.
	 */
	handleOpen(open: boolean): void {
		const { newText } = this.state;
		if (newText !== '' || open === false) {
			this.setState({ open });
		}
	}

	render(): JSX.Element {
		const { tenants, classes, searchedTenant } = this.props;
		const { newText, open } = this.state;
		const config: CreateFilterOptionsConfig<ClientCommunity> = { limit: 10 };
		const filterOptions = createFilterOptions(config);
		return (
			<>
				<Grid item xs={12} md={4}>
					<Autocomplete
						open={open}
						onOpen={(): void => {
							this.handleOpen(true);
						}}
						onClose={(): void => {
							this.handleOpen(false);
						}}
						options={newText === '' ? [] : tenants}
						filterOptions={filterOptions}
						noOptionsText={''}
						onChange={this.handleSelectedAutocompleteChange}
						isOptionEqualToValue={(tenant: ClientCommunity, value: ClientCommunity): boolean =>
							tenant.name === value.name
						}
						getOptionLabel={(tenant: ClientCommunity): string => tenant?.name ?? ''}
						renderOption={(props, tenant: ClientCommunity): ReactNode => (
							<li {...props}>
								<Typography variant="h6">{tenant?.name ?? ''}</Typography>
							</li>
						)}
						renderInput={(params): ReactNode => (
							<TextField
								variant="standard"
								{...params}
								InputLabelProps={{ shrink: true }}
								onChange={this.handleTextInstantChange}
								value={newText === '' ? '' : newText}
								label="Mandant wählen"
								placeholder="Mandant eingeben"
								fullWidth
							/>
						)}
						openOnFocus={false}
						inputValue={newText === '' ? '' : newText}
						disableClearable
						autoComplete
					/>
				</Grid>
				<Grid item xs={12} md={4} className={classes.resetButtonContainer}>
					{(newText || searchedTenant !== EmptyField.NONE) && (
						<ResetButton onClick={this.handleReset}>Zurücksetzen</ResetButton>
					)}
				</Grid>
			</>
		);
	}
}

export default withStyles(styles)(TenantsAutocompleteView);
