import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../../store/store';
import UnblockLoginActionView from './UnblockLoginAction.view';
import {
	showSnackbar,
	SnackbarAction,
	SnackbarType
} from '../../../../shared/views/SnackbarNotification/store/SnackbarNotification.actions';
import { UserActionTypes } from '../../store/UserActionTypes';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import {
	ClientOauthAccountBlockReasonEnum,
	ClientPersonExtended,
	ClientPersonUnblockAutomaticallyBlockedLoginByAdminRequest,
	ClientPersonUnblockLoginByAdminRequest
} from '@DigitaleDoerfer/digitale-doerfer-api';
import { unblockAutomaticallyBlockedLoginByAdmin, unblockLoginByAdmin } from '../../store/User.actions';

interface Props extends ActionMenuItemProps {
	user: ClientPersonExtended | null;
	showSnackbar: (snackbarAction: SnackbarAction) => void;
	unblockLogin: (unblockLoginRequestParams: ClientPersonUnblockLoginByAdminRequest) => void;
	unblockAutomaticallyBlockedLogin: (
		unblockAutomaticallyBlockedLoginRequestParams: ClientPersonUnblockAutomaticallyBlockedLoginByAdminRequest
	) => void;
}

class UnblockActionContainer extends Component<Props> {
	constructor(props: Props) {
		super(props);

		this.handleSave = this.handleSave.bind(this);
	}

	handleSave(): void {
		const { user, showSnackbar, unblockLogin, unblockAutomaticallyBlockedLogin } = this.props;
		const personId = user?.id;
		if (personId !== undefined) {
			if (user?.oauthAccount) {
				switch (user?.oauthAccount.blockReason) {
					case ClientOauthAccountBlockReasonEnum.ManuallyBlocked: {
						unblockLogin({
							personId
						});

						break;
					}
					case ClientOauthAccountBlockReasonEnum.TooManyLoginAttempts: {
						unblockAutomaticallyBlockedLogin({
							personId
						});

						break;
					}
					default: {
						showSnackbar({
							type: SnackbarType.SNACKBAR_INFO,
							message: 'Die Person ist nicht gesperrt.'
						});
					}
				}
			}
		} else {
			showSnackbar({
				type: SnackbarType.SNACKBAR_ERROR,
				message: 'Die Person hat keine ID.'
			});
		}
	}

	render(): JSX.Element | null {
		const { user, renderMenuItem } = this.props;
		if (
			user?.oauthAccount &&
			user.oauthAccount.blockReason !== ClientOauthAccountBlockReasonEnum.ManuallyBlocked &&
			user.oauthAccount.blockReason !== ClientOauthAccountBlockReasonEnum.TooManyLoginAttempts
		) {
			return null;
		}
		return <UnblockLoginActionView renderMenuItem={renderMenuItem} handleSave={this.handleSave} />;
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		user: state.user.detail.user
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<UserActionTypes>) => {
	return {
		showSnackbar: (snackbarAction: SnackbarAction): Promise<void> => dispatch(showSnackbar(snackbarAction)),
		unblockLogin: (unblockLoginRequestParams: ClientPersonUnblockLoginByAdminRequest): Promise<void> =>
			dispatch(unblockLoginByAdmin(unblockLoginRequestParams)),
		unblockAutomaticallyBlockedLogin: (
			unblockAutomaticallyBlockedLoginRequestParams: ClientPersonUnblockAutomaticallyBlockedLoginByAdminRequest
		): Promise<void> => dispatch(unblockAutomaticallyBlockedLoginByAdmin(unblockAutomaticallyBlockedLoginRequestParams))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UnblockActionContainer);
