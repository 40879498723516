import React, { Component } from 'react';
import { Box, Grid, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import {
	ClientComment,
	ClientPost,
	ClientUserGeneratedContentFlagComment,
	ClientUserGeneratedContentFlagStatusRecord
} from '@DigitaleDoerfer/digitale-doerfer-api';
import FlaggedContentStatusHistoryView from './shared/FlaggedContentStatusHistory.view';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { flaggedContentService, flaggedContentViewService, timeService } from '../../../ServiceFactory';
import FlaggedContentCommentsView from './shared/FlaggedContentComments.view';
import { ClientPostBaseEntity } from '../services/FlaggedContent.service';
import { TimestampFormat } from '../../../shared/services/Time.service';
import FlaggedContentDetailsReasonView from './shared/FlaggedContentDetailsReason.view';
import FlaggedContentDetailsStatusView from './shared/FlaggedContentDetailsStatus.view';
import { commonStyles, FlagSummary } from './shared/FlaggedContentDetailsCommons';
import AvatarView from '../../../shared/views/Avatar.view';
import ImageGallery from '../../../shared/views/ImageGallery.view';
import { ReadOnlyTextFieldView } from '../../../shared/views/ReadOnlyTextField.view';

interface Props extends WithStyles<typeof commonStyles> {
	commentFlag: ClientUserGeneratedContentFlagComment;
	flagPost?: ClientPost;
	flagPostDeleted?: boolean;
	flagSummary: FlagSummary;
	statusRecords: ClientUserGeneratedContentFlagStatusRecord[];
	commentsOfPost: ClientComment[];
}

class FlaggedCommentView extends Component<Props> {
	renderFlaggedEntityInformation(): JSX.Element {
		const { flagSummary, commentFlag } = this.props;
		const flaggedTextTitle = `${flaggedContentViewService().getEntityTypeDisplayName(
			commentFlag.entityType ?? ''
		)} in ${flaggedContentViewService().renderInfo(flagSummary.tenantName)}`;

		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<ReadOnlyTextFieldView
						id="text"
						name="text"
						label={flaggedTextTitle}
						multiline
						maxRows="5"
						value={flaggedContentViewService().renderInfo(flagSummary.text)}
						InputProps={{ readOnly: true }}
						InputLabelProps={{
							shrink: true
						}}
						fullWidth
					/>
				</Grid>
				<FlaggedContentDetailsReasonView reason={flagSummary.reason} />
				<FlaggedContentDetailsStatusView status={flagSummary.status} lastStatusUpdate={flagSummary.lastStatusUpdate} />
			</Grid>
		);
	}

	renderRelatedPostInfo(tenantName?: string, clientPostBaseEntity?: ClientPostBaseEntity): JSX.Element {
		const { flagPostDeleted, classes } = this.props;
		if (!clientPostBaseEntity) {
			return <>Kein gültiger Beitrag gefunden</>;
		}

		return (
			<>
				<ListItem className={classes.listItem} alignItems="flex-start">
					<ListItemAvatar>
						<AvatarView
							avatarPicture={clientPostBaseEntity.creatorAvatarPicture}
							deleted={clientPostBaseEntity.creatorDeleted}
						/>
					</ListItemAvatar>
					<ListItemText
						primary={
							<>
								<Typography component="span" variant="h6" color="textPrimary">
									{`${clientPostBaseEntity.creatorName} - ${
										clientPostBaseEntity.created
											? timeService().parseTimestamp(clientPostBaseEntity.created, TimestampFormat.DD_MM_YYYY_HH_MM)
											: ''
									} - ${flaggedContentViewService().getPostTypeDisplayname(
										clientPostBaseEntity.type
									)} aus ${flaggedContentViewService().renderInfo(tenantName)}`}
								</Typography>
							</>
						}
						secondary={
							<>
								<Typography
									component="span"
									variant="h6"
									color="textPrimary"
									className={flagPostDeleted ? classes.textMarkedAsDeleted : undefined}
								>
									{clientPostBaseEntity.text}
								</Typography>
								{flagPostDeleted && (
									<>
										<br />
										<Typography
											component="span"
											variant="h6"
											color="textPrimary"
											className={classes.deletionTextIndicator}
										>
											Dieser Post wurde gelöscht.
										</Typography>
									</>
								)}
							</>
						}
					/>
				</ListItem>
				<ImageGallery leftGalleryBoxPadding={'50px'} images={clientPostBaseEntity.images ?? []} />
			</>
		);
	}

	renderReportedContent(): JSX.Element | null {
		const { classes, commentsOfPost, flagSummary, flagPost } = this.props;
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography variant="h2" gutterBottom className={classes.typographyTitle}>
						Gesprächsverlauf
					</Typography>
				</Grid>
				<Grid item xs={12} className={classes.gridFirstItem}>
					{this.renderRelatedPostInfo(
						flagSummary.tenantName,
						flaggedContentService().getClientPostBaseEntityFromPost(flagPost)
					)}
					{commentsOfPost && commentsOfPost.length > 0 ? (
						<Box pt={3}>
							<Typography variant="h6" gutterBottom>
								Kommentare:
							</Typography>
						</Box>
					) : (
						''
					)}
					<FlaggedContentCommentsView
						commentsOfPost={commentsOfPost}
						highlightedCommentId={flagSummary.highlightedCommentId || ''}
					/>
				</Grid>
			</Grid>
		);
	}

	render(): JSX.Element {
		const { flagSummary, statusRecords } = this.props;
		return (
			<>
				{this.renderFlaggedEntityInformation()}
				{this.renderReportedContent()}
				<FlaggedContentStatusHistoryView flagSummary={flagSummary} statusRecords={statusRecords} />
			</>
		);
	}
}

export default withStyles(commonStyles)(FlaggedCommentView);
