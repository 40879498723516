import { default as Keycloak, KeycloakInitOptions, KeycloakProfile, KeycloakTokenParsed } from 'keycloak-js';
import React, { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';
import { setAccessToken } from './authToken';
import { setIsAuthenticated } from './authState';
import { KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM, KEYCLOAK_URL } from '../../shared/utils/AuthSettings';
import MainLoadingIndicator from '../../shared/views/LoadingIndicators/MainLoadingIndicator.view';
import DigitaleDoerferAPIFactory from '../../shared/utils/DigitaleDoerferAPIFactory';
import { refreshGlobalState } from '../../store/Global.actions';
import { GlobalActionTypes } from '../../store/GlobalActionTypes';
import { PlatformAdminUIThunkType } from '../../store/store';
import { connect } from 'react-redux';

export const handleError = (e: Error) => {
	console.error('An error occurred. Please try again later.', e);
	throw e;
};

export const keycloak = new Keycloak({
	url: KEYCLOAK_URL,
	realm: KEYCLOAK_REALM,
	clientId: KEYCLOAK_CLIENT_ID
});

const keycloakInitOptions: KeycloakInitOptions = {
	onLoad: 'login-required',
	checkLoginIframe: false,
	pkceMethod: 'S256',
	responseMode: 'fragment',
	redirectUri: window.location.href
};

export type AuthContextValues = {
	isReady: boolean;
	authClient: Keycloak;
	isAuthenticated: boolean;
	authProfile?: KeycloakProfile;
	tokenParsed?: KeycloakTokenParsed;
};

const defaultValues: AuthContextValues = {
	isReady: false,
	authClient: keycloak,
	isAuthenticated: false
};

const AuthContext = createContext<AuthContextValues>(defaultValues);
export const useAuth = () => useContext(AuthContext);

type AuthProviderProps = {
	refresh: () => void;
};

const AuthProvider: FC<AuthProviderProps> = ({ children, refresh }) => {
	const [isReady, setIsReady] = useState<boolean>(false);
	const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
	const [tokenParsed, setTokenParsed] = useState<KeycloakTokenParsed>();
	const [authProfile, setAuthProfile] = useState<KeycloakProfile>();

	keycloak.onReady = () => setIsReady(true);

	keycloak.onAuthSuccess = () =>
		keycloak
			.loadUserProfile()
			.then(profile => {
				setAuthProfile(profile);
			})
			.catch(handleError);

	keycloak.onAuthRefreshSuccess = () => {
		setAccessToken(keycloak.token);
		setTokenParsed(keycloak.tokenParsed);
	};

	keycloak.onTokenExpired = () => keycloak.updateToken(5).catch(handleError);

	// Called if there was an error while trying to refresh the token.
	keycloak.onAuthRefreshError = () => {
		keycloak.clearToken();
		keycloak.logout();
	};

	keycloak.onAuthLogout = () => {
		setAuthenticated(false);
		setIsAuthenticated(false);
		setAccessToken(undefined);
		setTokenParsed(undefined);
		setAuthProfile(undefined);
	};

	useEffect(() => {
		const initializeKeycloak = async () => {
			await keycloak
				.init(keycloakInitOptions)
				.then(value => {
					setAccessToken(keycloak.token);
					setTokenParsed(keycloak.tokenParsed);
					setAuthenticated(value);
					setIsAuthenticated(value);
				})
				.catch(handleError);
			DigitaleDoerferAPIFactory.createMiddleware();
			refresh();
		};

		initializeKeycloak();
	}, []);

	const authValues: AuthContextValues = useMemo(
		() => ({
			isReady,
			authClient: keycloak,
			isAuthenticated,
			authProfile,
			tokenParsed
		}),
		[isReady, isAuthenticated, authProfile, tokenParsed]
	);

	return (
		<AuthContext.Provider value={authValues}>
			{isAuthenticated ? children : <MainLoadingIndicator />}
		</AuthContext.Provider>
	);
};

const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<GlobalActionTypes>) => {
	return {
		refresh: (): Promise<void> => dispatch(refreshGlobalState())
	};
};
export default connect(undefined, mapDispatchToProps)(AuthProvider);
