import React, { Component } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import {
	ClientGroupExtendedAccessibilityEnum,
	ClientGroupExtendedContentVisibilityEnum
} from '@DigitaleDoerfer/digitale-doerfer-api';
import GroupVisibilityView from './GroupVisibility.view';
import GroupTypeView from './GroupType.view';
import GroupNameView from '../../../shared/views/Group/GroupName.view';
import GroupShortNameView from '../../../shared/views/Group/GroupShortName.view';
import { GroupStateChangeHandlers } from '../GroupStateChangeHandlers';

function getClientGroupExtendedAccessibilityEnum(
	groupType: string | null
): ClientGroupExtendedAccessibilityEnum | null {
	switch (groupType) {
		case ClientGroupExtendedAccessibilityEnum.Public:
			return ClientGroupExtendedAccessibilityEnum.Public;
		case ClientGroupExtendedAccessibilityEnum.ApprovalRequired:
			return ClientGroupExtendedAccessibilityEnum.ApprovalRequired;
		default:
			return null;
	}
}

function getClientGroupExtendedContentVisibilityEnum(
	groupVisibility: string | null
): ClientGroupExtendedContentVisibilityEnum | null {
	switch (groupVisibility) {
		case ClientGroupExtendedContentVisibilityEnum.Anyone:
			return ClientGroupExtendedContentVisibilityEnum.Anyone;
		case ClientGroupExtendedContentVisibilityEnum.Members:
			return ClientGroupExtendedContentVisibilityEnum.Members;
		case ClientGroupExtendedContentVisibilityEnum.SameHomeArea:
			return ClientGroupExtendedContentVisibilityEnum.SameHomeArea;
		default:
			return null;
	}
}
interface Props {
	groupName: string;
	groupShortName: string;
	groupType: ClientGroupExtendedAccessibilityEnum | null;
	groupContentVisibility: boolean;
	groupVisibility: ClientGroupExtendedContentVisibilityEnum | null;
	groupStateChangeHandlers: GroupStateChangeHandlers;
}

class GroupCreateBasicSettingsView extends Component<Props> {
	constructor(props: Props) {
		super(props);
		this.handleGroupTypeChange = this.handleGroupTypeChange.bind(this);
		this.handleGroupVisibilityChange = this.handleGroupVisibilityChange.bind(this);
		this.handleGroupContentVisibilityChange = this.handleGroupContentVisibilityChange.bind(this);
	}

	handleGroupTypeChange(event: React.ChangeEvent<{ value: string }>): void {
		const { groupVisibility, groupStateChangeHandlers } = this.props;
		const { handleGroupTypeChange, handleGroupVisibilityChange } = groupStateChangeHandlers;
		const { value } = event.target;
		handleGroupTypeChange && handleGroupTypeChange(getClientGroupExtendedAccessibilityEnum(value));

		// Reset groupVisibility
		if (
			value === ClientGroupExtendedAccessibilityEnum.Public &&
			groupVisibility === ClientGroupExtendedContentVisibilityEnum.Members
		) {
			handleGroupVisibilityChange && handleGroupVisibilityChange(null);
		}
	}

	handleGroupContentVisibilityChange(event: React.ChangeEvent<{ checked: boolean }>): void {
		const { groupVisibility, groupStateChangeHandlers } = this.props;
		const { handleGroupVisibilityChange, handleGroupContentVisibilityChange } = groupStateChangeHandlers;
		const { checked } = event.target;
		// Reset groupVisibility
		if (
			checked &&
			groupVisibility === ClientGroupExtendedContentVisibilityEnum.Members &&
			handleGroupVisibilityChange
		) {
			handleGroupVisibilityChange(null);
		}

		handleGroupContentVisibilityChange && handleGroupContentVisibilityChange(checked);
	}

	handleGroupVisibilityChange(event: React.ChangeEvent<{ value: string }>): void {
		const { handleGroupVisibilityChange } = this.props.groupStateChangeHandlers;
		handleGroupVisibilityChange &&
			handleGroupVisibilityChange(getClientGroupExtendedContentVisibilityEnum(event.target.value));
	}

	render(): JSX.Element {
		const { groupName, groupShortName, groupType, groupVisibility, groupContentVisibility, groupStateChangeHandlers } =
			this.props;

		return (
			<Box mb={4}>
				<Grid container>
					<Grid item xs={12}>
						<Typography variant="h2" gutterBottom>
							Grundeinstellungen
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={6}>
								<GroupNameView
									groupName={groupName}
									handleGroupNameChange={groupStateChangeHandlers.handleGroupNameChange}
								/>
							</Grid>
							<Grid item sm={4} md={3} lg={2}>
								<GroupShortNameView
									groupShortName={groupShortName}
									handleGroupShortNameChange={groupStateChangeHandlers.handleGroupShortNameChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<GroupTypeView
									groupContentVisibility={groupContentVisibility}
									groupType={groupType}
									handleGroupTypeChange={this.handleGroupTypeChange}
									handleGroupContentVisibilityChange={this.handleGroupContentVisibilityChange}
								/>
							</Grid>
							{groupType && (
								<Grid item xs={12}>
									<GroupVisibilityView
										groupType={groupType}
										groupVisibility={groupVisibility}
										groupContentVisibility={groupContentVisibility}
										handleInputChange={this.handleGroupVisibilityChange}
									/>
								</Grid>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Box>
		);
	}
}

export default GroupCreateBasicSettingsView;
