import createStyles from '@mui/styles/createStyles';
import { ClientPersonReferenceWithEmail } from '@DigitaleDoerfer/digitale-doerfer-api';

export const commonStyles = createStyles({
	gridFirstItem: {
		paddingTop: '0 !important'
	},
	typographyTitle: {
		marginTop: '20px !important',
		marginBottom: '0 !important'
	},
	listItem: {
		paddingLeft: 0
	},
	highlight: {
		backgroundColor: '#ff8b8c',
		borderRadius: '4px'
	},
	textMarkedAsDeleted: {
		textDecoration: 'line-through'
	},
	deletionTextIndicator: {
		fontStyle: 'italic'
	},
	textWrap: {
		wordWrap: 'break-word'
	}
});

export interface FlagSummary {
	text?: string;
	tenantName?: string;
	reason?: string;
	status?: string;
	lastStatusUpdate?: string;
	highlightedCommentId?: string;
	flagCreator?: ClientPersonReferenceWithEmail;
}
