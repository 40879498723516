import React, { Component } from 'react';
import FlaggedChatView from './FlaggedChat.view';
import FormHeaderView from '../../../shared/views/PersonDetail/FormHeader.view';
import { connect } from 'react-redux';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../store/store';
import { FlagSummary } from './shared/FlaggedContentDetailsCommons';
import {
	ClientUserGeneratedContentFlagChat,
	ClientUserGeneratedContentFlagStatusRecord
} from '@DigitaleDoerfer/digitale-doerfer-api';
import FlaggedDetailsActionsMenuContainer from './shared/FlaggedDetailsActionsMenu.container';
import { RoleManagementPermission } from '../../../shared/services/Role.service';
import { getChatFlag } from '../store/FlaggedContent.actions';
import { RouteComponentProps } from 'react-router-dom';
import { FlaggedContentActionTypes } from '../store/FlaggedContentActionTypes';
import { Container, Paper } from '@mui/material';
import MainLoadingIndicator from '../../../shared/views/LoadingIndicators/MainLoadingIndicator.view';
import FlaggedContentDetailsFooterView from './shared/FlaggedContentDetailsFooter.view';

interface Identifiable {
	id: string;
}

interface Props extends RouteComponentProps<Identifiable> {
	loading: boolean;
	chatFlag?: ClientUserGeneratedContentFlagChat;
	statusRecords: ClientUserGeneratedContentFlagStatusRecord[];
	flagSummary: FlagSummary;
	roleManagementPermission: RoleManagementPermission;
	getChatFlag: (flagId: string) => void;
}

interface State {
	contentRefreshed: boolean;
}

class FlaggedChatContainer extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { contentRefreshed: false };
	}

	componentDidMount(): void {
		const { getChatFlag, match } = this.props;
		const { id } = match.params;
		getChatFlag(id);
		this.setState({ contentRefreshed: true });
	}

	render(): JSX.Element | null {
		const { loading, chatFlag, statusRecords, flagSummary, roleManagementPermission } = this.props;
		const { contentRefreshed } = this.state;

		return (
			<Container fixed maxWidth="md">
				<MainLoadingIndicator loading={loading || !contentRefreshed} />
				{!loading && contentRefreshed && chatFlag && (
					<Paper>
						<FormHeaderView
							title={'Gemeldeter Chat'}
							actionMenu={<FlaggedDetailsActionsMenuContainer flagStatus={chatFlag.status} />}
						/>
						<FlaggedChatView
							chatFlag={chatFlag}
							statusRecords={statusRecords}
							flagSummary={flagSummary}
							roleManagementPermission={roleManagementPermission}
						/>
						<FlaggedContentDetailsFooterView />
					</Paper>
				)}
			</Container>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		loading: state.flaggedContent.detail.loading,
		chatFlag: state.flaggedContent.detail.chat,
		statusRecords: state.flaggedContent.detail.statusRecords,
		flagSummary: state.flaggedContent.detail.flagSummary,
		roleManagementPermission: state.global.roleManagementPermission
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<FlaggedContentActionTypes>) => {
	return {
		getChatFlag: (flagId: string): Promise<void> => dispatch(getChatFlag(flagId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FlaggedChatContainer);
