import React, { Component } from 'react';
import FlaggedPostView from './FlaggedPost.view';
import FormHeaderView from '../../../shared/views/PersonDetail/FormHeader.view';
import { connect } from 'react-redux';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../store/store';
import { FlagSummary } from './shared/FlaggedContentDetailsCommons';
import {
	ClientUserGeneratedContentFlagPost,
	ClientUserGeneratedContentFlagStatusRecord
} from '@DigitaleDoerfer/digitale-doerfer-api';
import FlaggedDetailsActionsMenuContainer from './shared/FlaggedDetailsActionsMenu.container';
import { flaggedContentService } from '../../../ServiceFactory';
import { RouteComponentProps } from 'react-router-dom';
import { FlaggedContentActionTypes } from '../store/FlaggedContentActionTypes';
import { getPostFlag } from '../store/FlaggedContent.actions';
import MainLoadingIndicator from '../../../shared/views/LoadingIndicators/MainLoadingIndicator.view';
import { Container, Paper } from '@mui/material';
import FlaggedContentDetailsFooterView from './shared/FlaggedContentDetailsFooter.view';

interface Identifiable {
	id: string;
}

interface Props extends RouteComponentProps<Identifiable> {
	loading: boolean;
	postFlag?: ClientUserGeneratedContentFlagPost;
	statusRecords: ClientUserGeneratedContentFlagStatusRecord[];
	flagSummary: FlagSummary;
	getPostFlag: (flagId: string) => void;
}

interface State {
	contentRefreshed: boolean;
}

class FlaggedPostContainer extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { contentRefreshed: false };
	}

	componentDidMount(): void {
		const { getPostFlag, match } = this.props;
		const { id } = match.params;
		getPostFlag(id);
		this.setState({ contentRefreshed: true });
	}

	render(): JSX.Element | null {
		const { loading, postFlag, statusRecords, flagSummary } = this.props;
		const { contentRefreshed } = this.state;

		return (
			<Container fixed maxWidth="md">
				<MainLoadingIndicator loading={loading || !contentRefreshed} />
				{!loading && contentRefreshed && postFlag && (
					<Paper>
						<FormHeaderView
							title={'Gemeldeter Beitrag'}
							actionMenu={<FlaggedDetailsActionsMenuContainer flagStatus={postFlag.status} />}
						/>
						<FlaggedPostView
							flagPost={postFlag.flagPost}
							flagPostDeleted={postFlag.postDeleted}
							flagSummary={flagSummary}
							statusRecords={statusRecords}
							commentsOfPost={flaggedContentService().getCommentsFromFlaggedPostOrComment(postFlag)}
						/>
						<FlaggedContentDetailsFooterView />
					</Paper>
				)}
			</Container>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		loading: state.flaggedContent.detail.loading,
		postFlag: state.flaggedContent.detail.post,
		statusRecords: state.flaggedContent.detail.statusRecords,
		flagSummary: state.flaggedContent.detail.flagSummary
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<FlaggedContentActionTypes>) => {
	return {
		getPostFlag: (flagId: string): Promise<void> => dispatch(getPostFlag(flagId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FlaggedPostContainer);
