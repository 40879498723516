import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import AuthErrorDialogView from './modules/auth/AuthErrorDialog.view';
import NavigationView from './modules/navigation/Navigation.view';
import { BaseError } from './shared/errors/Errors';
import SnackbarNotification from './shared/views/SnackbarNotification/SnackbarNotification';

declare module '@mui/styles' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

interface Props extends RouteComponentProps, React.PropsWithChildren<object> {
	shouldRenderUserMenu: boolean;
	shouldRenderFlaggedContentMenu: boolean;
	shouldRenderGroupMenu: boolean;
	shouldRenderConfigurationMenu: boolean;
	shouldRenderExternalContributionsMenu: boolean;
	shouldRenderStatisticsMenu: boolean;
}

class PlatformAdminUIView extends React.Component<Props> {
	renderError(error: BaseError): JSX.Element {
		return <AuthErrorDialogView error={error} />;
	}

	render(): JSX.Element {
		const {
			shouldRenderUserMenu,
			shouldRenderFlaggedContentMenu,
			shouldRenderGroupMenu,
			shouldRenderConfigurationMenu,
			shouldRenderExternalContributionsMenu,
			shouldRenderStatisticsMenu,
			children
		} = this.props;
		return (
			<>
				{/* CssBaseline applies a collection of HTML element and attribute style-normalizations.
                Read more about it at https://material-ui.com/components/css-baseline/ */}
				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={theme}>
						<CssBaseline />
						<NavigationView
							title="AdministrierBar"
							shouldRenderUserMenu={shouldRenderUserMenu}
							shouldRenderFlaggedContentMenu={shouldRenderFlaggedContentMenu}
							shouldRenderGroupMenu={shouldRenderGroupMenu}
							shouldRenderConfigurationMenu={shouldRenderConfigurationMenu}
							shouldRenderExternalContributionsMenu={shouldRenderExternalContributionsMenu}
							shouldRenderStatisticsMenu={shouldRenderStatisticsMenu}
						>
							<SnackbarNotification />
							{children}
						</NavigationView>
					</ThemeProvider>
				</StyledEngineProvider>
			</>
		);
	}
}

export default withRouter(PlatformAdminUIView);
