import React, { Suspense } from 'react';
import {
	ClientNewsSource,
	PagedModelClientPost,
	GetAllExternalPostsByNewsSourcePostTypesEnum as PostTypeEnum
} from '@DigitaleDoerfer/digitale-doerfer-api';
import SearchView from './Search.view';
import FiltersView from './Filters.view';
import BarLoadingIndicator from '../../shared/views/LoadingIndicators/BarLoadingIndicator.view';
import { Box, Grid, Pagination, Typography } from '@mui/material';
const ExternalPostView = React.lazy(() => import('./ExternalPost.view'));

interface ExternalPostType {
	name: string;
	postType?: PostTypeEnum;
}

interface PaginationInterface {
	page: number;
	rowsPerPage: number;
	totalElements: number;
	totalPages: number;
}
interface Props {
	externalPosts: PagedModelClientPost | undefined;
	pageOfClientNewsSource: ClientNewsSource[];
	externalPostsTypes: ExternalPostType[];
	loading: boolean;
	pagination: PaginationInterface;
	selectedPostTypes: string | undefined;
	handleChangePostType: (newPostType: string) => void;
	handleSearch: (searchString: string) => void;
	handleDelete: (PostID: string | undefined) => void;
	handleChangePage: (event: React.ChangeEvent<unknown>, newPage: number) => void;
}

const ExternalPostsView = (props: Props): JSX.Element => {
	const {
		externalPosts,
		externalPostsTypes,
		loading,
		pagination,
		handleChangePage,
		handleChangePostType,
		handleSearch,
		handleDelete,
		selectedPostTypes,
		pageOfClientNewsSource
	} = props;

	return (
		<div>
			<div style={{ marginBottom: '15px' }}>
				{loading ? (
					<BarLoadingIndicator loading={loading} />
				) : (
					<Suspense fallback={<BarLoadingIndicator loading={loading} />}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={5}>
								<SearchView pageOfClientNewsSource={pageOfClientNewsSource} handleSearch={handleSearch} />
							</Grid>
							<Grid item xs={12} md={5}>
								<FiltersView
									externalPostsTypes={externalPostsTypes}
									selectedPostTypes={selectedPostTypes}
									handleChangePostType={handleChangePostType}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={1}>
							{externalPosts &&
								externalPosts.content?.length !== 0 &&
								externalPosts.content?.map(externalPost => {
									return (
										<Grid item xs={12} md={6} lg={4} key={externalPost.id}>
											<ExternalPostView externalPost={externalPost} handleDelete={handleDelete} />
										</Grid>
									);
								})}
						</Grid>
					</Suspense>
				)}
			</div>
			<Box display="flex" alignItems="center" justifyContent="center">
				{externalPosts?.content?.length === 0 ? (
					<Box marginTop={3} marginRight={'auto'}>
						<Typography variant="body1"> Keine Daten zum Anzeigen </Typography>
					</Box>
				) : (
					<Pagination
						count={pagination.totalPages}
						page={pagination.page + 1}
						siblingCount={1}
						boundaryCount={2}
						defaultPage={1}
						color="primary"
						onChange={handleChangePage}
						size="large"
					/>
				)}
			</Box>
		</div>
	);
};

export default ExternalPostsView;
