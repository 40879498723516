import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../store/store';
import AuthDetailsView from './AuthDetails.view';
import { RouteComponentProps } from 'react-router-dom';
import { Container } from '@mui/material';
import { getAuthUser } from '../store/User.actions';
import { ClientOauthAccount, ClientOauthAccountAuthenticationMethodsEnum } from '@DigitaleDoerfer/digitale-doerfer-api';
import { RelatedEntity } from '../store/User.state';
import { UserActionTypes } from '../store/UserActionTypes';
import PersonDetailFormView from '../../../shared/views/PersonDetail/PersonDetailForm.view';
import { USERS_URL } from '../UsersRouting.container';

interface Props extends RouteComponentProps {
	loading: boolean;
	selectedUser: ClientOauthAccount;
	relatedEntities: RelatedEntity[];
	getUser: (userId: string) => void;
}

interface Identifiable {
	email: string;
}

class AuthDetailsContainer extends Component<Props> {
	componentDidMount(): void {
		const { match, getUser } = this.props;
		const id = match.params as Identifiable;
		if (id.email) {
			getUser(id.email);
		}
	}

	gotToTableHandler = (): void => {
		this.props.history.push(USERS_URL);
	};

	render(): JSX.Element {
		const { loading, selectedUser, match } = this.props;
		const { email } = match.params as Identifiable;
		const title = `Auth-Account ${selectedUser && selectedUser.name ? selectedUser.name : email}`;
		const authenticationMethods: ClientOauthAccountAuthenticationMethodsEnum[] =
			(selectedUser &&
				selectedUser.authenticationMethods &&
				selectedUser.authenticationMethods.map(methods => methods)) ||
			[];
		return (
			<Container fixed maxWidth="md">
				<PersonDetailFormView
					title={title}
					textToCopy={selectedUser.oauthId ?? ''}
					authenticationMethods={authenticationMethods}
					goToTableHandler={this.gotToTableHandler}
				>
					<AuthDetailsView email={email} loading={loading} selectedUser={selectedUser} />
				</PersonDetailFormView>
			</Container>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		loading: state.user.authDetail.loading,
		selectedUser: state.user.authDetail.authUser
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<UserActionTypes>) => {
	return {
		getUser: (email: string): Promise<void> => dispatch(getAuthUser(email))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthDetailsContainer);
