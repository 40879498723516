import { ThunkAction } from 'redux-thunk';
import {
	CREATE_NEWS_SOURCES_FAILURE,
	CREATE_NEWS_SOURCES_REQUEST,
	CREATE_NEWS_SOURCES_SUCCESS,
	CreateNewsSourcesFailureAction,
	CreateNewsSourcesRequestAction,
	CreateNewsSourcesSuccessAction,
	GET_NEWS_SOURCES_FAILURE,
	GET_NEWS_SOURCES_REQUEST,
	GET_NEWS_SOURCES_SUCCESS,
	GetNewsSourcesFailureAction,
	GetNewsSourcesRequestAction,
	GetNewsSourcesSuccessAction,
	NewsSourcesActionTypes
} from './NewsSourcesActionTypes';
import { BaseError } from '../../../shared/errors/Errors';
import { wrapIntoErrorObject } from '../../../shared/errors/ErrorWrapper';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../store/store';
import {
	ClientNewsSource,
	ClientNewsSourceCreateConfirmation,
	ClientNewsSourceCreateRequest
} from '@DigitaleDoerfer/digitale-doerfer-api';
import { grapevineAdminuiNewssourceApi, grapevineAdminuiNewssourceEventsApi } from '../../../ServiceFactory';
import {
	showSnackbar,
	showSnackbarError,
	SnackbarType
} from '../../../shared/views/SnackbarNotification/store/SnackbarNotification.actions';

export type ThunkResult<R> = ThunkAction<R, PlatformAdminUIState, undefined, NewsSourcesActionTypes>;

export const getNewsSourcesRequest = (): GetNewsSourcesRequestAction => {
	return {
		type: GET_NEWS_SOURCES_REQUEST
	};
};

export const getNewsSourcesSuccess = (clientNewsSource: ClientNewsSource[]): GetNewsSourcesSuccessAction => {
	return {
		type: GET_NEWS_SOURCES_SUCCESS,
		clientNewsSource
	};
};

export const getNewsSourcesFailure = (error: BaseError): GetNewsSourcesFailureAction => {
	return {
		type: GET_NEWS_SOURCES_FAILURE,
		error
	};
};

export const getNewsSources = (): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<NewsSourcesActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(getNewsSourcesRequest());

			// Let's get the NewsSource configurations
			const responseTemp = await grapevineAdminuiNewssourceApi().getNewsSources({});
			const response = await grapevineAdminuiNewssourceApi().getNewsSources({
				count: responseTemp.page?.totalElements
			});
			// Prepate data to dispatch getNewsSourcesSuccess
			const clientNewsSource = response.content ? response.content : [];
			dispatch(getNewsSourcesSuccess(clientNewsSource));
		} catch (error) {
			dispatch(getNewsSourcesFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const createNewsSourcesRequest = (
	newsSourcesRequestData: ClientNewsSourceCreateRequest
): CreateNewsSourcesRequestAction => {
	return {
		type: CREATE_NEWS_SOURCES_REQUEST,
		newsSourcesRequestData
	};
};

export const createNewsSourcesSuccess = (clientNewsSource: ClientNewsSource): CreateNewsSourcesSuccessAction => {
	return {
		type: CREATE_NEWS_SOURCES_SUCCESS,
		newsSourcesSuccessData: clientNewsSource
	};
};

export const createNewsSourcesFailure = (error: BaseError): CreateNewsSourcesFailureAction => {
	return {
		type: CREATE_NEWS_SOURCES_FAILURE,
		error
	};
};

export const createNewsSources = (newsSourcesData: ClientNewsSourceCreateRequest): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<NewsSourcesActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(createNewsSourcesRequest(newsSourcesData));

			const response: ClientNewsSourceCreateConfirmation =
				await grapevineAdminuiNewssourceEventsApi().onNewsSourceCreateRequest({
					clientNewsSourceCreateRequest: newsSourcesData
				});
			const temporalFakeCreatedNewsSources: ClientNewsSource = Object.create({}); // TODO remove when DD-10946 is resolved
			const createdNewsSources: ClientNewsSource = response.newsSource
				? response.newsSource
				: temporalFakeCreatedNewsSources;

			dispatch(createNewsSourcesSuccess(createdNewsSources));
			dispatch(
				showSnackbar({
					type: SnackbarType.SNACKBAR_SUCCESS,
					message: 'Eine neue NewsSource wurde erfolgreich erstellt',
					url: '/News-sources'
				})
			);
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(createNewsSourcesFailure(wrapIntoErrorObject(error)));
		}
	};
};
