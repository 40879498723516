import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../store/store';
import {
	ClientCommunity,
	ClientCreateRoleAssignmentRequest,
	ClientPersonExtended,
	ClientRoleInformation
} from '@DigitaleDoerfer/digitale-doerfer-api';
import RoleAssignmentForm from '../../../shared/views/RoleAssignment/RoleAssignmentForm.view';
import { assignRole, removeRole } from '../store/User.actions';
import { UserActionTypes } from '../store/UserActionTypes';
import { showSnackbarError } from '../../../shared/views/SnackbarNotification/store/SnackbarNotification.actions';

interface Props {
	loadingRoleAssignments: boolean;
	allAvailableRoles: ClientRoleInformation[];
	user: ClientPersonExtended | null;
	assignableRoles: ClientRoleInformation[];
	tenants: ClientCommunity[];
	canAddNewRoleAssignments: boolean;
	removeRole: (roleAssignmentId: string) => void;
	assignRole: (roleData: ClientCreateRoleAssignmentRequest) => void;
	showSnackbarError: (error: unknown) => void;
}

class RoleAssignmentFormContainer extends Component<Props> {
	constructor(props: Props) {
		super(props);
		this.handleRemoveRole = this.handleRemoveRole.bind(this);
		this.handleSaveRole = this.handleSaveRole.bind(this);
	}

	handleRemoveRole(roleAssignmentId: string): void {
		const { removeRole } = this.props;
		removeRole(roleAssignmentId);
	}

	handleSaveRole(roleKey: string, relatedEntityId?: string): void {
		const { assignRole, user } = this.props;
		if (user === null) {
			return;
		}
		const roleData: ClientCreateRoleAssignmentRequest = {
			personId: user.id,
			relatedEntityId,
			roleKey
		};
		assignRole(roleData);
	}

	render(): JSX.Element {
		const {
			user,
			assignableRoles,
			allAvailableRoles,
			loadingRoleAssignments,
			tenants,
			canAddNewRoleAssignments,
			showSnackbarError
		} = this.props;

		return (
			<>
				{user && (
					<RoleAssignmentForm
						assignableRoles={assignableRoles}
						allAvailableRoles={allAvailableRoles}
						tenants={tenants}
						user={user}
						loadingRoleAssignments={loadingRoleAssignments}
						canAddNewRoleAssignments={canAddNewRoleAssignments}
						handleRemoveRole={this.handleRemoveRole}
						handleSaveRole={this.handleSaveRole}
						showSnackbarError={showSnackbarError}
					/>
				)}
			</>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		user: state.user.detail.user,
		loadingRoleAssignments: state.user.detail.loadingRoleAssignments,
		allAvailableRoles: state.global.allAvailableRoles,
		assignableRoles: state.global.assignableRoles,
		tenants: state.global.tenants
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<UserActionTypes>) => {
	return {
		removeRole: (roleAssignmentId: string): Promise<void> => dispatch(removeRole(roleAssignmentId)),
		assignRole: (roleData: ClientCreateRoleAssignmentRequest): Promise<void> => dispatch(assignRole(roleData)),
		showSnackbarError: (error: unknown): Promise<void> => dispatch(showSnackbarError(error))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleAssignmentFormContainer);
