import { QueryObserverResult, useQuery } from 'react-query';
import {
	participantsAdminuiGeoareaApi,
	participantsAdminuiTenantApi,
	sharedAdminuiAppApi
} from '../../../../ServiceFactory';
import { ClientAppVariant, ClientGeoAreaExtended, ClientTenant } from '@DigitaleDoerfer/digitale-doerfer-api';
import { Contract, ListType } from '../../types';

export type ItemDetails = {
	[key: string]: string | string[][] | undefined;
	appVariantId: string | undefined;
	geoAreaIdsExcluded: string[][];
	geoAreaIdsIncluded: string[][];
	tenantId: string | undefined;
};

const useFetchAppVariant = (appVariantId: string | undefined): QueryObserverResult<ClientAppVariant> => {
	return useQuery<ClientAppVariant>([ListType.APP_VARIANT, { appVariantId }], () =>
		sharedAdminuiAppApi()
			.getAppVariantById({ appVariantId: appVariantId ?? '' })
			.then(response => response)
	);
};
const useFetchTenant = (tenantId: string | undefined): QueryObserverResult<ClientTenant> => {
	return useQuery<ClientTenant>([ListType.TENANT, { tenantId }], () =>
		participantsAdminuiTenantApi()
			.getTenantById({ tenantId: tenantId ?? '' })
			.then(response => response)
	);
};
const useFetchGeoArea = (geoAreaIds: string[] | undefined): QueryObserverResult<ClientGeoAreaExtended[]> => {
	return useQuery(['ClientGeoAreaExtended', { geoAreaIds }], () =>
		participantsAdminuiGeoareaApi()
			.getGeoAreaWithChildren({ geoAreaIds, depth: 0, revealParentGeoAreas: true })
			.then(response => {
				if (geoAreaIds === undefined || geoAreaIds.length < 1) return [];
				return (
					response?.map(item => {
						return item as ClientGeoAreaExtended;
					}) || []
				);
			})
	);
};

const getGeoAreaString = (geoAreas: ClientGeoAreaExtended[]): string[][] => {
	const paths = (area: ClientGeoAreaExtended | undefined): string[][] => {
		if (area?.name && area?.childGeoAreas) {
			return area.childGeoAreas.flatMap(paths).map(r => [area.name + ' > ' ?? '', ...r]);
		}
		return [[`${area?.name ?? ''} ${area?.id ? `(Id: ${area.id})` : ''}`]];
	};
	return paths(geoAreas[0]);
};

const useItemDetails = (item: Contract | null): ItemDetails => {
	const appVariant = useFetchAppVariant(item?.appVariantId);
	const geoAreasExcluded = useFetchGeoArea(item?.geoAreaIdsExcluded);
	const geoAreasIncluded = useFetchGeoArea(item?.geoAreaIdsIncluded);
	const tenant = useFetchTenant(item?.tenantId);

	return {
		appVariantId: appVariant?.data?.name,
		geoAreaIdsExcluded: geoAreasExcluded?.data ? getGeoAreaString(geoAreasExcluded.data) : [],
		geoAreaIdsIncluded: geoAreasIncluded?.data !== undefined ? getGeoAreaString(geoAreasIncluded.data) : [],
		tenantId: tenant?.data?.name
	};
};

export default useItemDetails;
