import React, { Component } from 'react';
import { Grid } from '@mui/material';
import {
	ClientUserGeneratedContentFlagGroup,
	ClientUserGeneratedContentFlagStatusRecord
} from '@DigitaleDoerfer/digitale-doerfer-api';
import FlaggedContentStatusHistoryView from './shared/FlaggedContentStatusHistory.view';
import { WithStyles, withStyles } from '@mui/styles';
import FlaggedContentDetailsReasonView from './shared/FlaggedContentDetailsReason.view';
import FlaggedContentDetailsStatusView from './shared/FlaggedContentDetailsStatus.view';
import { commonStyles, FlagSummary } from './shared/FlaggedContentDetailsCommons';
import { ReadOnlyTextFieldView } from '../../../shared/views/ReadOnlyTextField.view';
import { flaggedContentViewService } from '../../../ServiceFactory';
import { FlaggedType } from '../store/FlaggedContent.state';

interface Props extends WithStyles<typeof commonStyles> {
	flagSummary: FlagSummary;
	flaggedEntity: ClientUserGeneratedContentFlagGroup;
	statusRecords: ClientUserGeneratedContentFlagStatusRecord[];
}
class FlaggedGroupView extends Component<Props> {
	renderFlaggedEntityInformation(): JSX.Element {
		const {
			flagSummary,
			flaggedEntity: { flagGroup }
		} = this.props;
		const flaggedTextTitle = `${flaggedContentViewService().getEntityTypeDisplayName(
			FlaggedType.GROUP
		)} in ${flaggedContentViewService().renderInfo(flagSummary.tenantName)}`;

		return (
			<Grid container spacing={3}>
				<Grid item container spacing={3} xs={12}>
					<Grid item xs={12} sm={10}>
						<ReadOnlyTextFieldView
							id="name"
							name="name"
							label={flaggedTextTitle}
							multiline
							maxRows={5}
							value={flaggedContentViewService().renderInfo(flagGroup?.name)}
							InputProps={{ readOnly: true }}
							InputLabelProps={{
								shrink: true
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={2} container>
						<ReadOnlyTextFieldView
							id="shortName"
							name="shortName"
							label="Kürzel"
							multiline
							maxRows={5}
							value={flaggedContentViewService().renderInfo(flagGroup?.shortName)}
							InputProps={{ readOnly: true }}
							InputLabelProps={{
								shrink: true
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} container>
						<ReadOnlyTextFieldView
							id="description"
							name="description"
							label="Beschreibung"
							multiline
							maxRows={10}
							value={flaggedContentViewService().renderInfo(flagGroup?.description)}
							InputProps={{ readOnly: true }}
							InputLabelProps={{
								shrink: true
							}}
							fullWidth
						/>
					</Grid>
				</Grid>
				<FlaggedContentDetailsReasonView reason={flagSummary.reason} />
				<FlaggedContentDetailsStatusView status={flagSummary.status} lastStatusUpdate={flagSummary.lastStatusUpdate} />
			</Grid>
		);
	}

	render(): JSX.Element {
		const { flagSummary, statusRecords } = this.props;
		return (
			<>
				{this.renderFlaggedEntityInformation()}
				<FlaggedContentStatusHistoryView flagSummary={flagSummary} statusRecords={statusRecords} />
			</>
		);
	}
}

export default withStyles(commonStyles)(FlaggedGroupView);
