import {
	ClientCreateRoleAssignmentRequest,
	ClientOauthAccount,
	ClientPersonBlockLoginByAdminRequest,
	ClientPersonChangeEmailAddressByAdminRequest,
	ClientPersonChangeNameByAdminRequest,
	ClientPersonChangeStatusRequest,
	ClientPersonCreateRequest,
	ClientPersonExtended,
	ClientPersonUnblockAutomaticallyBlockedLoginByAdminRequest,
	ClientPersonUnblockLoginByAdminRequest,
	ClientRoleAssignment
} from '@DigitaleDoerfer/digitale-doerfer-api';
import { BaseError } from '../../../shared/errors/Errors';
import { TableSorting } from '../../../shared/views/Table/TableSorting';
import { ListSearchParams } from './User.state';
import { Pagination } from '../../../shared/services/InMemoryTable.service';

export const GET_USERS_BY_TEXT_REQUEST = 'GET_USERS_BY_TEXT_REQUEST';
export const GET_USERS_BY_TEXT_SUCCESS = 'GET_USERS_BY_TEXT_SUCCESS';
export const GET_USERS_BY_TEXT_FAILURE = 'GET_USERS_BY_TEXT_FAILURE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const ROLE_ASSIGNMENT_REQUEST = 'ROLE_ASSIGNMENT_REQUEST';
export const ROLE_ASSIGNMENT_SUCCESS = 'ROLE_ASSIGNMENT_SUCCESS';
export const ROLE_ASSIGNMENT_FAILURE = 'ROLE_ASSIGNMENT_FAILURE';

export const REMOVE_ROLE_ASSIGNMENT_REQUEST = 'REMOVE_ROLE_ASSIGNMENT_REQUEST';
export const REMOVE_ROLE_ASSIGNMENT_SUCCESS = 'REMOVE_ROLE_ASSIGNMENT_SUCCESS';
export const REMOVE_ROLE_ASSIGNMENT_FAILURE = 'REMOVE_ROLE_ASSIGNMENT_FAILURE';

export const GET_AUTH_USER_REQUEST = 'GET_AUTH_USER_REQUEST';
export const GET_AUTH_USER_SUCCESS = 'GET_AUTH_USER_SUCCESS';
export const GET_AUTH_USER_FAILURE = 'GET_AUTH_USER_FAILURE';

export const GET_USERS_BY_ROLE_REQUEST = 'GET_USERS_BY_ROLE_REQUEST';
export const GET_USERS_BY_ROLE_SUCCESS = 'GET_USERS_BY_ROLE_SUCCESS';
export const GET_USERS_BY_ROLE_FAILURE = 'GET_USERS_BY_ROLE_FAILURE';

export const CHANGE_EMAIL_ADDRESS_BY_ADMIN_REQUEST = 'CHANGE_EMAIL_ADDRESS_BY_ADMIN_REQUEST';
export const CHANGE_EMAIL_ADDRESS_BY_ADMIN_SUCCESS = 'CHANGE_EMAIL_ADDRESS_BY_ADMIN_SUCCESS';
export const CHANGE_EMAIL_ADDRESS_BY_ADMIN_FAILURE = 'CHANGE_EMAIL_ADDRESS_BY_ADMIN_FAILURE';

export const CHANGE_NAME_BY_ADMIN_REQUEST = 'CHANGE_NAME_BY_ADMIN_REQUEST';
export const CHANGE_NAME_BY_ADMIN_SUCCESS = 'CHANGE_NAME_BY_ADMIN_SUCCESS';
export const CHANGE_NAME_BY_ADMIN_FAILURE = 'CHANGE_NAME_BY_ADMIN_FAILURE';

export const BLOCK_LOGIN_BY_ADMIN_REQUEST = 'BLOCK_LOGIN_BY_ADMIN_REQUEST';
export const BLOCK_LOGIN_BY_ADMIN_SUCCESS = 'BLOCK_LOGIN_BY_ADMIN_SUCCESS';
export const BLOCK_LOGIN_BY_ADMIN_FAILURE = 'BLOCK_LOGIN_BY_ADMIN_FAILURE';

export const UNBLOCK_LOGIN_BY_ADMIN_REQUEST = 'UNBLOCK_LOGIN_BY_ADMIN_REQUEST';
export const UNBLOCK_LOGIN_BY_ADMIN_SUCCESS = 'UNBLOCK_LOGIN_BY_ADMIN_SUCCESS';
export const UNBLOCK_LOGIN_BY_ADMIN_FAILURE = 'UNBLOCK_LOGIN_BY_ADMIN_FAILURE';

export const UNBLOCK_AUTOMATICALLY_BLOCKED_LOGIN_BY_ADMIN_REQUEST =
	'UNBLOCK_AUTOMATICALLY_BLOCKED_LOGIN_BY_ADMIN_REQUEST';
export const UNBLOCK_AUTOMATICALLY_BLOCKED_LOGIN_BY_ADMIN_SUCCESS =
	'UNBLOCK_AUTOMATICALLY_BLOCKED_LOGIN_BY_ADMIN_SUCCESS';
export const UNBLOCK_AUTOMATICALLY_BLOCKED_LOGIN_BY_ADMIN_FAILURE =
	'UNBLOCK_AUTOMATICALLY_BLOCKED_LOGIN_BY_ADMIN_FAILURE';

export const RESEND_VERIFICATION_MAIL_BY_ADMIN_REQUEST = 'RESEND_VERIFICATION_MAIL_BY_ADMIN_REQUEST';
export const RESEND_VERIFICATION_MAIL_BY_ADMIN_SUCCESS = 'RESEND_VERIFICATION_MAIL_BY_ADMIN_SUCCESS';
export const RESEND_VERIFICATION_MAIL_BY_ADMIN_FAILURE = 'RESEND_VERIFICATION_MAIL_BY_ADMIN_FAILURE';

export const RESET_PASSWORD_BY_ADMIN_REQUEST = 'RESET_PASSWORD_BY_ADMIN_REQUEST';
export const RESET_PASSWORD_BY_ADMIN_SUCCESS = 'RESET_PASSWORD_BY_ADMIN_SUCCESS';
export const RESET_PASSWORD_BY_ADMIN_FAILURE = 'RESET_PASSWORD_BY_ADMIN_FAILURE';

export const SET_EMAIL_VERIFIED_BY_ADMIN_REQUEST = 'SET_EMAIL_VERIFIED_BY_ADMIN_REQUEST';
export const SET_EMAIL_VERIFIED_BY_ADMIN_SUCCESS = 'SET_EMAIL_VERIFIED_BY_ADMIN_SUCCESS';
export const SET_EMAIL_VERIFIED_BY_ADMIN_FAILURE = 'SET_EMAIL_VERIFIED_BY_ADMIN_FAILURE';

export const DELETE_USER_BY_ADMIN_REQUEST = 'DELETE_USER_BY_ADMIN_REQUEST';
export const DELETE_USER_BY_ADMIN_SUCCESS = 'DELETE_USER_BY_ADMIN_SUCCESS';
export const DELETE_USER_BY_ADMIN_FAILURE = 'DELETE_USER_BY_ADMIN_FAILURE';

export const CHANGE_STATUS_BY_ADMIN_REQUEST = 'CHANGE_STATUS_BY_ADMIN_REQUEST';
export const CHANGE_STATUS_BY_ADMIN_SUCCESS = 'CHANGE_STATUS_BY_ADMIN_SUCCESS';
export const CHANGE_STATUS_BY_ADMIN_FAILURE = 'CHANGE_STATUS_BY_ADMIN_FAILURE';

export const CANCEL_CHANGE_EMAIL_REQUEST = 'CANCEL_CHANGE_EMAIL_REQUEST';
export const CANCEL_CHANGE_EMAIL_SUCCESS = 'CANCEL_CHANGE_EMAIL_SUCCESS';
export const CANCEL_CHANGE_EMAIL_FAILURE = 'CANCEL_CHANGE_EMAIL_FAILURE';

export interface GetUsersByTextRequestAction {
	type: typeof GET_USERS_BY_TEXT_REQUEST;
	searchParams: ListSearchParams;
}

export interface GetUsersByTextSuccessAction {
	type: typeof GET_USERS_BY_TEXT_SUCCESS;
	users: ClientPersonExtended[];
	text: string;
	sorting: TableSorting;
	pagination: Pagination;
}

export interface GetUsersByTextFailureAction {
	type: typeof GET_USERS_BY_TEXT_FAILURE;
	error: BaseError | null;
}

export interface GetUserRequestAction {
	type: typeof GET_USER_REQUEST;
	id: string;
}

export interface GetUserSuccessAction {
	type: typeof GET_USER_SUCCESS;
	user: ClientPersonExtended;
}

export interface GetUserFailureAction {
	type: typeof GET_USER_FAILURE;
	error: BaseError | null;
}

export interface CreateUserRequestAction {
	type: typeof CREATE_USER_REQUEST;
	userData: ClientPersonCreateRequest;
}

export interface CreateUserSuccessAction {
	type: typeof CREATE_USER_SUCCESS;
	user: ClientPersonExtended;
}

export interface CreateUserFailureAction {
	type: typeof CREATE_USER_FAILURE;
	error: BaseError | null;
}

export interface RoleAssignmentRequestAction {
	type: typeof ROLE_ASSIGNMENT_REQUEST;
	roleData: ClientCreateRoleAssignmentRequest;
}
export interface RoleAssignmentSuccessAction {
	type: typeof ROLE_ASSIGNMENT_SUCCESS;
	clientRoleAssignment: ClientRoleAssignment;
}

export interface RoleAssignmentFailureAction {
	type: typeof ROLE_ASSIGNMENT_FAILURE;
	error: BaseError | null;
}

export interface RemoveRoleAssignmentRequestAction {
	type: typeof REMOVE_ROLE_ASSIGNMENT_REQUEST;
	roleAssignmentId: string;
}
export interface RemoveRoleAssignmentSuccessAction {
	type: typeof REMOVE_ROLE_ASSIGNMENT_SUCCESS;
	roleAssignmentId: string;
}

export interface RemoveRoleAssignmentFailureAction {
	type: typeof REMOVE_ROLE_ASSIGNMENT_FAILURE;
	error: BaseError | null;
}

export interface GetAuthUserRequestAction {
	type: typeof GET_AUTH_USER_REQUEST;
	id: string;
}

export interface GetAuthUserSuccessAction {
	type: typeof GET_AUTH_USER_SUCCESS;
	user: ClientOauthAccount;
}

export interface GetAuthUserFailureAction {
	type: typeof GET_AUTH_USER_FAILURE;
	error: BaseError | null;
}
export interface GetUsersByRoleRequestAction {
	type: typeof GET_USERS_BY_ROLE_REQUEST;
	searchParams: ListSearchParams;
}
export interface GetUsersByRoleSuccessAction {
	type: typeof GET_USERS_BY_ROLE_SUCCESS;
	users: ClientPersonExtended[];
	role: string;
	sorting: TableSorting;
	pagination: Pagination;
}

export interface GetUsersByRoleFailureAction {
	type: typeof GET_USERS_BY_ROLE_FAILURE;
	error: BaseError | null;
}

export interface ChangeEmailAddressByAdminRequestAction {
	type: typeof CHANGE_EMAIL_ADDRESS_BY_ADMIN_REQUEST;
	changeEmailAddressRequestParams: ClientPersonChangeEmailAddressByAdminRequest;
}
export interface ChangeEmailAddressByAdminSuccessAction {
	type: typeof CHANGE_EMAIL_ADDRESS_BY_ADMIN_SUCCESS;
	user: ClientPersonExtended;
}

export interface ChangeEmailAddressByAdminFailureAction {
	type: typeof CHANGE_EMAIL_ADDRESS_BY_ADMIN_FAILURE;
	error: BaseError | null;
}

export interface ChangeNameByAdminRequestAction {
	type: typeof CHANGE_NAME_BY_ADMIN_REQUEST;
	changeNameRequestParams: ClientPersonChangeNameByAdminRequest;
}
export interface ChangeNameByAdminSuccessAction {
	type: typeof CHANGE_NAME_BY_ADMIN_SUCCESS;
	user: ClientPersonExtended;
}

export interface ChangeNameByAdminFailureAction {
	type: typeof CHANGE_NAME_BY_ADMIN_FAILURE;
	error: BaseError | null;
}

export interface BlockLoginByAdminRequestAction {
	type: typeof BLOCK_LOGIN_BY_ADMIN_REQUEST;
	blockLoginRequestParams: ClientPersonBlockLoginByAdminRequest;
}
export interface BlockLoginByAdminSuccessAction {
	type: typeof BLOCK_LOGIN_BY_ADMIN_SUCCESS;
	user: ClientPersonExtended;
}

export interface BlockLoginByAdminFailureAction {
	type: typeof BLOCK_LOGIN_BY_ADMIN_FAILURE;
	error: BaseError | null;
}

export interface UnblockLoginByAdminRequestAction {
	type: typeof UNBLOCK_LOGIN_BY_ADMIN_REQUEST;
	unblockLoginRequestParams: ClientPersonUnblockLoginByAdminRequest;
}
export interface UnblockLoginByAdminSuccessAction {
	type: typeof UNBLOCK_LOGIN_BY_ADMIN_SUCCESS;
	user: ClientPersonExtended;
}

export interface UnblockLoginByAdminFailureAction {
	type: typeof UNBLOCK_LOGIN_BY_ADMIN_FAILURE;
	error: BaseError | null;
}

export interface UnblockAutomaticallyBlockedLoginByAdminRequestAction {
	type: typeof UNBLOCK_AUTOMATICALLY_BLOCKED_LOGIN_BY_ADMIN_REQUEST;
	unblockLoginRequestParams: ClientPersonUnblockAutomaticallyBlockedLoginByAdminRequest;
}
export interface UnblockAutomaticallyBlockedLoginByAdminSuccessAction {
	type: typeof UNBLOCK_AUTOMATICALLY_BLOCKED_LOGIN_BY_ADMIN_SUCCESS;
	user: ClientPersonExtended;
}

export interface UnblockAutomaticallyBlockedLoginByAdminFailureAction {
	type: typeof UNBLOCK_AUTOMATICALLY_BLOCKED_LOGIN_BY_ADMIN_FAILURE;
	error: BaseError | null;
}

export interface ResendVerificationMailByAdminRequestAction {
	type: typeof RESEND_VERIFICATION_MAIL_BY_ADMIN_REQUEST;
	userId: string;
}

export interface ResendVerificationMailByAdminSuccessAction {
	type: typeof RESEND_VERIFICATION_MAIL_BY_ADMIN_SUCCESS;
	userId: string;
}

export interface ResendVerificationMailByAdminFailureAction {
	type: typeof RESEND_VERIFICATION_MAIL_BY_ADMIN_FAILURE;
	error: BaseError | null;
}

export interface ResetPasswordByAdminRequestAction {
	type: typeof RESET_PASSWORD_BY_ADMIN_REQUEST;
	userId: string;
}

export interface ResetPasswordByAdminSuccessAction {
	type: typeof RESET_PASSWORD_BY_ADMIN_SUCCESS;
	userId: string;
}

export interface ResetPasswordByAdminFailureAction {
	type: typeof RESET_PASSWORD_BY_ADMIN_FAILURE;
	error: BaseError | null;
}

export interface SetEmailVerifiedByAdminRequestAction {
	type: typeof SET_EMAIL_VERIFIED_BY_ADMIN_REQUEST;
	userId: string;
}

export interface SetEmailVerifiedByAdminSuccessAction {
	type: typeof SET_EMAIL_VERIFIED_BY_ADMIN_SUCCESS;
	userId: string;
	verifiedEmail: string;
}

export interface SetEmailVerifiedByAdminFailureAction {
	type: typeof SET_EMAIL_VERIFIED_BY_ADMIN_FAILURE;
	error: BaseError | null;
}

export interface DeleteUserByAdminRequestAction {
	type: typeof DELETE_USER_BY_ADMIN_REQUEST;
	userId: string;
}

export interface DeleteUserByAdminSuccessAction {
	type: typeof DELETE_USER_BY_ADMIN_SUCCESS;
	userId: string;
}

export interface DeleteUserByAdminFailureAction {
	type: typeof DELETE_USER_BY_ADMIN_FAILURE;
	error: BaseError | null;
}

export interface ChangeStatusByAdminRequestAction {
	type: typeof CHANGE_STATUS_BY_ADMIN_REQUEST;
	changeStatusRequestParams: ClientPersonChangeStatusRequest;
}

export interface ChangeStatusByAdminSuccessAction {
	type: typeof CHANGE_STATUS_BY_ADMIN_SUCCESS;
	user: ClientPersonExtended;
}

export interface ChangeStatusByAdminFailureAction {
	type: typeof CHANGE_STATUS_BY_ADMIN_FAILURE;
	error: BaseError | null;
}

export interface CancelChangeEmailRequestAction {
	type: typeof CANCEL_CHANGE_EMAIL_REQUEST;
	userId: string;
}

export interface CancelChangeEmailSuccessAction {
	type: typeof CANCEL_CHANGE_EMAIL_SUCCESS;
	userId: string;
}

export interface CancelChangeEmailFailureAction {
	type: typeof CANCEL_CHANGE_EMAIL_FAILURE;
	error: BaseError | null;
}

export type UserActionTypes =
	| GetUsersByTextRequestAction
	| GetUsersByTextSuccessAction
	| GetUsersByTextFailureAction
	| GetUserRequestAction
	| GetUserSuccessAction
	| GetUserFailureAction
	| CreateUserRequestAction
	| CreateUserSuccessAction
	| CreateUserFailureAction
	| RoleAssignmentRequestAction
	| RoleAssignmentSuccessAction
	| RoleAssignmentFailureAction
	| RemoveRoleAssignmentRequestAction
	| RemoveRoleAssignmentSuccessAction
	| RemoveRoleAssignmentFailureAction
	| GetAuthUserRequestAction
	| GetAuthUserSuccessAction
	| GetAuthUserFailureAction
	| GetUsersByRoleRequestAction
	| GetUsersByRoleSuccessAction
	| GetUsersByRoleFailureAction
	| ChangeEmailAddressByAdminRequestAction
	| ChangeEmailAddressByAdminSuccessAction
	| ChangeEmailAddressByAdminFailureAction
	| ChangeNameByAdminRequestAction
	| ChangeNameByAdminSuccessAction
	| ChangeNameByAdminFailureAction
	| BlockLoginByAdminRequestAction
	| BlockLoginByAdminSuccessAction
	| BlockLoginByAdminFailureAction
	| UnblockLoginByAdminRequestAction
	| UnblockLoginByAdminSuccessAction
	| UnblockLoginByAdminFailureAction
	| UnblockAutomaticallyBlockedLoginByAdminRequestAction
	| UnblockAutomaticallyBlockedLoginByAdminSuccessAction
	| UnblockAutomaticallyBlockedLoginByAdminFailureAction
	| ResendVerificationMailByAdminRequestAction
	| ResendVerificationMailByAdminSuccessAction
	| ResendVerificationMailByAdminFailureAction
	| ResetPasswordByAdminRequestAction
	| ResetPasswordByAdminSuccessAction
	| ResetPasswordByAdminFailureAction
	| SetEmailVerifiedByAdminRequestAction
	| SetEmailVerifiedByAdminSuccessAction
	| SetEmailVerifiedByAdminFailureAction
	| DeleteUserByAdminRequestAction
	| DeleteUserByAdminSuccessAction
	| DeleteUserByAdminFailureAction
	| ChangeStatusByAdminRequestAction
	| ChangeStatusByAdminSuccessAction
	| ChangeStatusByAdminFailureAction
	| CancelChangeEmailRequestAction
	| CancelChangeEmailSuccessAction
	| CancelChangeEmailFailureAction;
