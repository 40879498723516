import {
	ClientComment,
	ClientGossip,
	ClientHappening,
	ClientMediaItem,
	ClientNewsItem,
	ClientOffer,
	ClientPost,
	ClientPostTypeEnum,
	ClientSeeking,
	ClientSuggestion,
	ClientUserGeneratedContentFlagChat,
	ClientUserGeneratedContentFlagComment,
	ClientUserGeneratedContentFlagDetail,
	ClientUserGeneratedContentFlagGroup,
	ClientUserGeneratedContentFlagPost,
	ClientUserGeneratedContentFlagStatusEnum,
	ClientUserGeneratedContentFlagStatusRecord,
	ListFlagsIncludedStatusEnum
} from '@DigitaleDoerfer/digitale-doerfer-api';
import { FlaggedType, initialFlaggedContentState } from '../store/FlaggedContent.state';
import { TimeService, TimestampFormat } from '../../../shared/services/Time.service';
import { FlagSummary } from '../flagged-content-details/shared/FlaggedContentDetailsCommons';

export interface IncludedStatusMapping {
	key: React.Key;
	requestStatuses: ListFlagsIncludedStatusEnum[];
	responseStatus?: ClientUserGeneratedContentFlagStatusEnum;
	displayName: string;
	explanation?: string;
}

export interface ClientPostBaseEntity {
	created?: number;
	creatorAvatarPicture?: ClientMediaItem;
	creatorDeleted?: boolean;
	creatorName: string;
	type: ClientPostTypeEnum;
	images: ClientMediaItem[];
	text: string;
}

export const INCLUDED_STATUS_MAPPING_UNRESOLVED = {
	key: 'unresolved',
	requestStatuses: [ListFlagsIncludedStatusEnum.Open, ListFlagsIncludedStatusEnum.InProgress],
	displayName: 'Ungelöst',
	explanation: 'Offen und In Bearbeitung'
};

export const includedStatusMappings: IncludedStatusMapping[] = [
	INCLUDED_STATUS_MAPPING_UNRESOLVED,
	{
		key: 'open',
		requestStatuses: [ListFlagsIncludedStatusEnum.Open],
		responseStatus: ClientUserGeneratedContentFlagStatusEnum.Open,
		displayName: 'Offen'
	},
	{
		key: 'in_progress',
		requestStatuses: [ListFlagsIncludedStatusEnum.InProgress],
		responseStatus: ClientUserGeneratedContentFlagStatusEnum.InProgress,
		displayName: 'In Bearbeitung'
	},
	{
		key: 'accepted',
		requestStatuses: [ListFlagsIncludedStatusEnum.Accepted],
		responseStatus: ClientUserGeneratedContentFlagStatusEnum.Accepted,
		displayName: 'Akzeptiert',
		explanation: 'Inhalt wurde entfernt'
	},
	{
		key: 'rejected',
		requestStatuses: [ListFlagsIncludedStatusEnum.Rejected],
		responseStatus: ClientUserGeneratedContentFlagStatusEnum.Rejected,
		displayName: 'Abgelehnt',
		explanation: 'Inhalt ist unbedenklich'
	},
	{
		key: 'all',
		requestStatuses: [],
		displayName: 'Alle'
	}
];

export class FlaggedContentService {
	constructor(public timeService: TimeService) {}

	getIncludedStatusMappingByResponseStatus(
		value: ClientUserGeneratedContentFlagStatusEnum
	): IncludedStatusMapping | undefined {
		return includedStatusMappings.find(mapping => mapping.responseStatus === value);
	}

	getIncludedStatusMappingByKey(value: React.Key): IncludedStatusMapping | undefined {
		return includedStatusMappings.find(mapping => mapping.key === value);
	}

	getShortEntityTypeName(completeEntityType: string): string {
		const lastWord = completeEntityType.match(/(\w+)$/)?.[0].toUpperCase();
		let shortEntityTypeName = '';
		switch (lastWord) {
			case FlaggedType.CHAT:
				shortEntityTypeName = 'chat';
				break;
			case FlaggedType.COMMENT:
				shortEntityTypeName = 'comment';
				break;
			case FlaggedType.GROUP:
				shortEntityTypeName = 'group';
				break;
			default:
				shortEntityTypeName = 'post';
				break;
		}
		return shortEntityTypeName;
	}

	getFlagSummaryInformation(
		flag:
			| ClientUserGeneratedContentFlagDetail
			| ClientUserGeneratedContentFlagChat
			| ClientUserGeneratedContentFlagComment
			| ClientUserGeneratedContentFlagGroup
			| ClientUserGeneratedContentFlagPost
			| undefined
	): FlagSummary {
		let reason: string | undefined;
		// the reason of flagging is the oldest status record which is the last in the list
		if (flag && flag.statusRecords && flag.statusRecords.length > 0) {
			reason = flag.statusRecords[flag.statusRecords.length - 1].comment;
		}
		const flagSummary: FlagSummary = {
			...initialFlaggedContentState.detail.flagSummary,
			reason,
			tenantName: flag?.tenant?.name,
			status: flag?.status,
			lastStatusUpdate: flag?.lastStatusUpdate
				? this.timeService.parseTimestamp(flag?.lastStatusUpdate, TimestampFormat.DD_MM_YYYY_AT_HH_MM_O_CLOCK)
				: undefined,
			flagCreator: flag?.flagCreator
		};
		return flagSummary;
	}

	getStatusRecordsFromFlagSortedByCreatedAsc(
		flag:
			| ClientUserGeneratedContentFlagDetail
			| ClientUserGeneratedContentFlagChat
			| ClientUserGeneratedContentFlagComment
			| ClientUserGeneratedContentFlagGroup
			| ClientUserGeneratedContentFlagPost
			| undefined
	): ClientUserGeneratedContentFlagStatusRecord[] {
		const records = flag?.statusRecords || [];
		return [...records].sort((status1, status2) => (status1.created || 0) - (status2.created || 0));
	}

	getCommentsFromFlaggedPostOrComment(
		flag: ClientUserGeneratedContentFlagComment | ClientUserGeneratedContentFlagPost | undefined
	): ClientComment[] {
		return flag?.commentsOfPost ? flag?.commentsOfPost : [];
	}

	getClientPostBaseEntityFromPost(post?: ClientPost): ClientPostBaseEntity | undefined {
		let clientPostBaseEntity: ClientPostBaseEntity | undefined;
		const postType = post?.type || '';
		switch (postType) {
			case ClientPostTypeEnum.Gossip:
				clientPostBaseEntity = this.convertInternalClientPostToClientPostBaseEntity(
					post?.gossip,
					ClientPostTypeEnum.Gossip
				);
				break;
			case ClientPostTypeEnum.Happening:
				clientPostBaseEntity = this.convertExternalClientPostToClientPostBaseEntity(
					post?.happening,
					ClientPostTypeEnum.Happening
				);
				break;
			case ClientPostTypeEnum.NewsItem:
				clientPostBaseEntity = this.convertExternalClientPostToClientPostBaseEntity(
					post?.newsItem,
					ClientPostTypeEnum.NewsItem
				);
				break;
			case ClientPostTypeEnum.Offer:
				clientPostBaseEntity = this.convertInternalClientPostToClientPostBaseEntity(
					post?.offer,
					ClientPostTypeEnum.Offer
				);
				break;
			case ClientPostTypeEnum.Seeking:
				clientPostBaseEntity = this.convertInternalClientPostToClientPostBaseEntity(
					post?.seeking,
					ClientPostTypeEnum.Seeking
				);
				break;
			case ClientPostTypeEnum.Suggestion:
				clientPostBaseEntity = this.convertInternalClientPostToClientPostBaseEntity(
					post?.suggestion,
					ClientPostTypeEnum.Suggestion
				);
				break;
			default:
				clientPostBaseEntity = undefined;
				break;
		}
		return clientPostBaseEntity;
	}

	private convertInternalClientPostToClientPostBaseEntity(
		post: ClientGossip | ClientOffer | ClientSeeking | ClientSuggestion | undefined,
		postType: ClientPostTypeEnum
	): ClientPostBaseEntity {
		const creatorName = `${post?.creator?.firstName || ''} ${post?.creator?.lastName || ''}`;
		const creatorAvatarPicture = post?.creator?.profilePicture;
		return {
			created: post?.created,
			creatorName,
			creatorAvatarPicture,
			creatorDeleted: post?.creator?.deleted,
			type: postType,
			images: post?.images || [],
			text: post?.text || ''
		};
	}

	private convertExternalClientPostToClientPostBaseEntity(
		post: ClientHappening | ClientNewsItem | undefined,
		postType: ClientPostTypeEnum
	): ClientPostBaseEntity {
		const creatorName = `${post?.authorName || ''}`;
		const creatorAvatarPicture = post?.sitePicture;
		return {
			created: post?.created,
			creatorName,
			creatorAvatarPicture,
			type: postType,
			images: post?.images || [],
			text: post?.text || ''
		};
	}

	getTextFromPost(post: ClientUserGeneratedContentFlagPost): string | undefined {
		let text = undefined;
		const postType = post.flagPost?.type || '';
		switch (postType) {
			case ClientPostTypeEnum.Gossip:
				text = post.flagPost?.gossip?.text;
				break;
			case ClientPostTypeEnum.Happening:
				text = post.flagPost?.happening?.text;
				break;
			case ClientPostTypeEnum.NewsItem:
				text = post.flagPost?.newsItem?.text;
				break;
			case ClientPostTypeEnum.Offer:
				text = post.flagPost?.offer?.text;
				break;
			case ClientPostTypeEnum.Seeking:
				text = post.flagPost?.seeking?.text;
				break;
			case ClientPostTypeEnum.Suggestion:
				text = post.flagPost?.suggestion?.text;
				break;
			default:
				break;
		}
		return text;
	}
}
