import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../store/store';
import { FlagSummary } from './shared/FlaggedContentDetailsCommons';
import {
	ClientUserGeneratedContentFlagDetail,
	ClientUserGeneratedContentFlagStatusRecord
} from '@DigitaleDoerfer/digitale-doerfer-api';
import FlaggedOtherView from './FlaggedOther.view';
import { RouteComponentProps } from 'react-router-dom';
import FormHeaderView from '../../../shared/views/PersonDetail/FormHeader.view';
import { capitalizeFirstLetter } from '../../../shared/utils/Util';
import { getFlaggedContent } from '../store/FlaggedContent.actions';
import { FlaggedContentActionTypes } from '../store/FlaggedContentActionTypes';
import { Container, Paper } from '@mui/material';
import MainLoadingIndicator from '../../../shared/views/LoadingIndicators/MainLoadingIndicator.view';
import FlaggedContentDetailsFooterView from './shared/FlaggedContentDetailsFooter.view';

interface Identifiable {
	id: string;
	entityType: string;
}

interface Props extends RouteComponentProps<Identifiable> {
	loading: boolean;
	flaggedEntity?: ClientUserGeneratedContentFlagDetail;
	statusRecords: ClientUserGeneratedContentFlagStatusRecord[];
	flagSummary: FlagSummary;
	getFlaggedContent: (flagId: string) => void;
}

interface State {
	contentRefreshed: boolean;
}

class FlaggedOtherContainer extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { contentRefreshed: false };
	}

	componentDidMount(): void {
		const { getFlaggedContent, match } = this.props;
		const { id } = match.params;
		getFlaggedContent(id);
		this.setState({ contentRefreshed: true });
	}

	render(): JSX.Element {
		const { loading, flaggedEntity, statusRecords, flagSummary, match } = this.props;
		const { entityType } = match.params;
		const { contentRefreshed } = this.state;
		return (
			<Container fixed maxWidth="md">
				<MainLoadingIndicator loading={loading || !contentRefreshed} />
				{!loading && contentRefreshed && flaggedEntity && (
					<Paper>
						<FormHeaderView title={`Gemeldeter Inhalt (${capitalizeFirstLetter(entityType)})`} actionMenu={undefined} />
						<FlaggedOtherView flagSummary={flagSummary} statusRecords={statusRecords} />
						<FlaggedContentDetailsFooterView />
					</Paper>
				)}
			</Container>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		loading: state.flaggedContent.detail.loading,
		flaggedEntity: state.flaggedContent.detail.other,
		statusRecords: state.flaggedContent.detail.statusRecords,
		flagSummary: state.flaggedContent.detail.flagSummary
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<FlaggedContentActionTypes>) => {
	return {
		getFlaggedContent: (flagId: string): Promise<void> => dispatch(getFlaggedContent(flagId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FlaggedOtherContainer);
