import { ThunkAction } from 'redux-thunk';
import history from '../../../history';
import {
	BLOCK_LOGIN_BY_ADMIN_FAILURE,
	BLOCK_LOGIN_BY_ADMIN_REQUEST,
	BLOCK_LOGIN_BY_ADMIN_SUCCESS,
	BlockLoginByAdminFailureAction,
	BlockLoginByAdminRequestAction,
	BlockLoginByAdminSuccessAction,
	CANCEL_CHANGE_EMAIL_FAILURE,
	CANCEL_CHANGE_EMAIL_REQUEST,
	CANCEL_CHANGE_EMAIL_SUCCESS,
	CancelChangeEmailFailureAction,
	CancelChangeEmailRequestAction,
	CancelChangeEmailSuccessAction,
	CHANGE_EMAIL_ADDRESS_BY_ADMIN_FAILURE,
	CHANGE_EMAIL_ADDRESS_BY_ADMIN_REQUEST,
	CHANGE_EMAIL_ADDRESS_BY_ADMIN_SUCCESS,
	CHANGE_NAME_BY_ADMIN_FAILURE,
	CHANGE_NAME_BY_ADMIN_REQUEST,
	CHANGE_NAME_BY_ADMIN_SUCCESS,
	CHANGE_STATUS_BY_ADMIN_FAILURE,
	CHANGE_STATUS_BY_ADMIN_REQUEST,
	CHANGE_STATUS_BY_ADMIN_SUCCESS,
	ChangeEmailAddressByAdminFailureAction,
	ChangeEmailAddressByAdminRequestAction,
	ChangeEmailAddressByAdminSuccessAction,
	ChangeNameByAdminFailureAction,
	ChangeNameByAdminRequestAction,
	ChangeNameByAdminSuccessAction,
	ChangeStatusByAdminFailureAction,
	ChangeStatusByAdminRequestAction,
	ChangeStatusByAdminSuccessAction,
	CREATE_USER_FAILURE,
	CREATE_USER_REQUEST,
	CREATE_USER_SUCCESS,
	CreateUserFailureAction,
	CreateUserRequestAction,
	CreateUserSuccessAction,
	DELETE_USER_BY_ADMIN_FAILURE,
	DELETE_USER_BY_ADMIN_REQUEST,
	DELETE_USER_BY_ADMIN_SUCCESS,
	DeleteUserByAdminFailureAction,
	DeleteUserByAdminRequestAction,
	DeleteUserByAdminSuccessAction,
	GET_AUTH_USER_FAILURE,
	GET_AUTH_USER_REQUEST,
	GET_AUTH_USER_SUCCESS,
	GET_USER_FAILURE,
	GET_USER_REQUEST,
	GET_USER_SUCCESS,
	GET_USERS_BY_ROLE_FAILURE,
	GET_USERS_BY_ROLE_REQUEST,
	GET_USERS_BY_ROLE_SUCCESS,
	GET_USERS_BY_TEXT_FAILURE,
	GET_USERS_BY_TEXT_REQUEST,
	GET_USERS_BY_TEXT_SUCCESS,
	GetAuthUserFailureAction,
	GetAuthUserRequestAction,
	GetAuthUserSuccessAction,
	GetUserFailureAction,
	GetUserRequestAction,
	GetUsersByRoleFailureAction,
	GetUsersByRoleRequestAction,
	GetUsersByRoleSuccessAction,
	GetUsersByTextFailureAction,
	GetUsersByTextRequestAction,
	GetUsersByTextSuccessAction,
	GetUserSuccessAction,
	REMOVE_ROLE_ASSIGNMENT_FAILURE,
	REMOVE_ROLE_ASSIGNMENT_REQUEST,
	REMOVE_ROLE_ASSIGNMENT_SUCCESS,
	RemoveRoleAssignmentFailureAction,
	RemoveRoleAssignmentRequestAction,
	RemoveRoleAssignmentSuccessAction,
	RESEND_VERIFICATION_MAIL_BY_ADMIN_FAILURE,
	RESEND_VERIFICATION_MAIL_BY_ADMIN_REQUEST,
	RESEND_VERIFICATION_MAIL_BY_ADMIN_SUCCESS,
	ResendVerificationMailByAdminFailureAction,
	ResendVerificationMailByAdminRequestAction,
	ResendVerificationMailByAdminSuccessAction,
	RESET_PASSWORD_BY_ADMIN_FAILURE,
	RESET_PASSWORD_BY_ADMIN_REQUEST,
	RESET_PASSWORD_BY_ADMIN_SUCCESS,
	ResetPasswordByAdminFailureAction,
	ResetPasswordByAdminRequestAction,
	ResetPasswordByAdminSuccessAction,
	ROLE_ASSIGNMENT_FAILURE,
	ROLE_ASSIGNMENT_REQUEST,
	ROLE_ASSIGNMENT_SUCCESS,
	RoleAssignmentFailureAction,
	RoleAssignmentRequestAction,
	RoleAssignmentSuccessAction,
	SET_EMAIL_VERIFIED_BY_ADMIN_FAILURE,
	SET_EMAIL_VERIFIED_BY_ADMIN_REQUEST,
	SET_EMAIL_VERIFIED_BY_ADMIN_SUCCESS,
	SetEmailVerifiedByAdminFailureAction,
	SetEmailVerifiedByAdminRequestAction,
	SetEmailVerifiedByAdminSuccessAction,
	UNBLOCK_AUTOMATICALLY_BLOCKED_LOGIN_BY_ADMIN_FAILURE,
	UNBLOCK_AUTOMATICALLY_BLOCKED_LOGIN_BY_ADMIN_REQUEST,
	UNBLOCK_AUTOMATICALLY_BLOCKED_LOGIN_BY_ADMIN_SUCCESS,
	UNBLOCK_LOGIN_BY_ADMIN_FAILURE,
	UNBLOCK_LOGIN_BY_ADMIN_REQUEST,
	UNBLOCK_LOGIN_BY_ADMIN_SUCCESS,
	UnblockAutomaticallyBlockedLoginByAdminFailureAction,
	UnblockAutomaticallyBlockedLoginByAdminRequestAction,
	UnblockAutomaticallyBlockedLoginByAdminSuccessAction,
	UnblockLoginByAdminFailureAction,
	UnblockLoginByAdminRequestAction,
	UnblockLoginByAdminSuccessAction,
	UserActionTypes
} from './UserActionTypes';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../store/store';
import { initialUserState, ListSearchParams } from './User.state';
import {
	ClientCreateRoleAssignmentRequest,
	ClientCreateRoleAssignmentResponse,
	ClientExceptionEntityTypeEnum,
	ClientOauthAccount,
	ClientPersonBlockLoginByAdminRequest,
	ClientPersonBlockLoginByAdminResponse,
	ClientPersonChangeEmailAddressByAdminRequest,
	ClientPersonChangeEmailAddressByAdminResponse,
	ClientPersonChangeNameByAdminRequest,
	ClientPersonChangeNameByAdminResponse,
	ClientPersonChangeStatusConfirmation,
	ClientPersonChangeStatusRequest,
	ClientPersonCreateByAdminResponse,
	ClientPersonCreateRequest,
	ClientPersonExtended,
	ClientPersonExtendedStatusesEnum,
	ClientPersonSetEmailVerifiedByAdminResponse,
	ClientPersonUnblockAutomaticallyBlockedLoginByAdminRequest,
	ClientPersonUnblockAutomaticallyBlockedLoginByAdminResponse,
	ClientPersonUnblockLoginByAdminRequest,
	ClientPersonUnblockLoginByAdminResponse,
	ClientRoleAssignment,
	GetPersonsFilteredByRoleSortColumnEnum,
	GetPersonsFilteredByRoleSortDirectionEnum,
	GetPersonsSortColumnEnum,
	GetPersonsSortDirectionEnum
} from '@DigitaleDoerfer/digitale-doerfer-api';
import {
	participantsAdminuiPersonApi,
	participantsAdminuiPersonEventsApi,
	participantsPersonEventsApi,
	participantsRoleAssignmentEventsApi,
	sharedAdminDataprivacyApi
} from '../../../ServiceFactory';
import { BaseError, ClientExceptionError } from '../../../shared/errors/Errors';
import { wrapIntoErrorObject } from '../../../shared/errors/ErrorWrapper';
import {
	showSnackbar,
	showSnackbarError,
	SnackbarType
} from '../../../shared/views/SnackbarNotification/store/SnackbarNotification.actions';
import { TableColumnSortingDirection, TableSorting } from '../../../shared/views/Table/TableSorting';
import { Pagination } from '../../../shared/services/InMemoryTable.service';
import { USERS_URL } from '../UsersRouting.container';

export type ThunkResult<R> = ThunkAction<R, PlatformAdminUIState, undefined, UserActionTypes>;

export const getUsersByTextRequest = (searchParams: ListSearchParams): GetUsersByTextRequestAction => {
	return {
		type: GET_USERS_BY_TEXT_REQUEST,
		searchParams
	};
};

export const getUsersByTextSuccess = (
	users: ClientPersonExtended[],
	text: string,
	sorting: TableSorting,
	pagination: Pagination
): GetUsersByTextSuccessAction => {
	return {
		type: GET_USERS_BY_TEXT_SUCCESS,
		users,
		text,
		sorting,
		pagination
	};
};

export const getUsersByTextFailure = (error: BaseError): GetUsersByTextFailureAction => {
	return {
		type: GET_USERS_BY_TEXT_FAILURE,
		error
	};
};

/**
 * Returns the correct SortColumnEnum
 * @param columnId Not processed column id
 */
const toSortColumnListByTextEnum = (columnId: string): GetPersonsSortColumnEnum | undefined => {
	switch (columnId) {
		case 'email': {
			return GetPersonsSortColumnEnum.Email;
		}
		case 'firstName': {
			return GetPersonsSortColumnEnum.FirstName;
		}
		case 'lastName': {
			return GetPersonsSortColumnEnum.LastName;
		}
	}

	return undefined;
};

const toSortColumnListByTextDirection = (direction: TableColumnSortingDirection): GetPersonsSortDirectionEnum => {
	return direction === TableColumnSortingDirection.ASC
		? GetPersonsSortDirectionEnum.Asc
		: GetPersonsSortDirectionEnum.Desc;
};

export const getUsersByText = (searchParams: ListSearchParams): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<UserActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		const { text, sorting, pagination } = searchParams;
		const { page, rowsPerPage } = pagination;

		try {
			dispatch(getUsersByTextRequest(searchParams));

			const response = await participantsAdminuiPersonApi().getPersons({
				page,
				count: rowsPerPage,
				sortColumn: toSortColumnListByTextEnum(sorting.columnId) ?? GetPersonsSortColumnEnum.Email,
				sortDirection: toSortColumnListByTextDirection(sorting.direction),
				search: text
			});

			// Prepare data to dispatch getUsersByTextSuccess
			const users = response.content ? response.content : [];
			const pagination: Pagination = {
				page: response.page?.number ?? 0,
				rowsPerPage: response.page?.size ?? initialUserState.list.searchParams.pagination.rowsPerPage,
				total: response.page?.totalElements ?? 0
			};
			dispatch(getUsersByTextSuccess(users, text, sorting, pagination));
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(getUsersByTextFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const getUserRequest = (id: string): GetUserRequestAction => {
	return {
		type: GET_USER_REQUEST,
		id
	};
};

export const getUserSuccess = (user: ClientPersonExtended): GetUserSuccessAction => {
	return {
		type: GET_USER_SUCCESS,
		user
	};
};

export const getUserFailure = (error: BaseError): GetUserFailureAction => {
	return {
		type: GET_USER_FAILURE,
		error
	};
};

export const getUser = (userId: string, useSnackbarError: boolean): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<UserActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(getUserRequest(userId));

			const user: ClientPersonExtended = await participantsAdminuiPersonApi().getPersonExtended({
				personId: userId
			});

			dispatch(getUserSuccess(user));
		} catch (error) {
			if (useSnackbarError) {
				history.push(USERS_URL);
				dispatch(showSnackbarError(error));
			}
			dispatch(getUserFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const createUserRequest = (userData: ClientPersonCreateRequest): CreateUserRequestAction => {
	return {
		type: CREATE_USER_REQUEST,
		userData
	};
};

export const createUserSuccess = (user: ClientPersonExtended): CreateUserSuccessAction => {
	return {
		type: CREATE_USER_SUCCESS,
		user
	};
};

export const createUserFailure = (error: BaseError): CreateUserFailureAction => {
	return {
		type: CREATE_USER_FAILURE,
		error
	};
};

export const createUser = (userData: ClientPersonCreateRequest): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<UserActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(createUserRequest(userData));

			const response: ClientPersonCreateByAdminResponse =
				await participantsPersonEventsApi().onPersonCreateByAdminRequest({ clientPersonCreateRequest: userData });
			const createdPerson: ClientPersonExtended = response.createdPerson as ClientPersonExtended;

			dispatch(createUserSuccess(createdPerson));
			dispatch(
				showSnackbar({
					type: SnackbarType.SNACKBAR_SUCCESS,
					message: 'Ein neuer Benutzer wurde erfolgreich angelegt.',
					url: 'details/' + createdPerson.id
				})
			);
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(createUserFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const roleAssignmentRequest = (roleData: ClientCreateRoleAssignmentRequest): RoleAssignmentRequestAction => {
	return {
		type: ROLE_ASSIGNMENT_REQUEST,
		roleData
	};
};

export const roleAssignmentSuccess = (clientRoleAssignment: ClientRoleAssignment): RoleAssignmentSuccessAction => {
	return {
		type: ROLE_ASSIGNMENT_SUCCESS,
		clientRoleAssignment
	};
};

export const roleAssignmentFailure = (error: BaseError): RoleAssignmentFailureAction => {
	return {
		type: ROLE_ASSIGNMENT_FAILURE,
		error
	};
};

export const assignRole = (clientRoleData: ClientCreateRoleAssignmentRequest): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<UserActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(roleAssignmentRequest(clientRoleData));

			const roleAssignmentResponse: ClientCreateRoleAssignmentResponse =
				await participantsRoleAssignmentEventsApi().createRoleAssignment({
					clientCreateRoleAssignmentRequest: clientRoleData
				});
			const roleAssignmentId = roleAssignmentResponse.roleAssignment.id;
			const clientRoleAssignment: ClientRoleAssignment = roleAssignmentResponse.roleAssignment || {
				id: roleAssignmentId,
				personId: '',
				roleKey: ''
			};

			dispatch(roleAssignmentSuccess(clientRoleAssignment));
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(roleAssignmentFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const removeRoleAssignmentRequest = (roleAssignmentId: string): RemoveRoleAssignmentRequestAction => {
	return {
		type: REMOVE_ROLE_ASSIGNMENT_REQUEST,
		roleAssignmentId
	};
};

export const removeRoleAssignmentSuccess = (roleAssignmentId: string): RemoveRoleAssignmentSuccessAction => {
	return {
		type: REMOVE_ROLE_ASSIGNMENT_SUCCESS,
		roleAssignmentId
	};
};

export const removeRoleAssignmentFailure = (error: BaseError): RemoveRoleAssignmentFailureAction => {
	return {
		type: REMOVE_ROLE_ASSIGNMENT_FAILURE,
		error
	};
};

export const removeRole = (roleAssignmentId: string): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<UserActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(removeRoleAssignmentRequest(roleAssignmentId));

			await participantsRoleAssignmentEventsApi().removeRoleAssignment({
				clientRemoveRoleAssignmentRequest: { roleAssignmentId }
			});

			dispatch(removeRoleAssignmentSuccess(roleAssignmentId));
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(removeRoleAssignmentFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const getAuthUserRequest = (id: string): GetAuthUserRequestAction => {
	return {
		type: GET_AUTH_USER_REQUEST,
		id
	};
};

export const getAuthUserSuccess = (user: ClientOauthAccount): GetAuthUserSuccessAction => {
	return {
		type: GET_AUTH_USER_SUCCESS,
		user
	};
};

export const getAuthUserFailure = (error: BaseError): GetAuthUserFailureAction => {
	return {
		type: GET_AUTH_USER_FAILURE,
		error
	};
};

export const getAuthUser = (email: string): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<UserActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(getAuthUserRequest(email));

			const user: ClientOauthAccount = await participantsAdminuiPersonApi().getOauthUser({ email });

			dispatch(getAuthUserSuccess(user));
		} catch (error) {
			history.push(USERS_URL);
			if (
				error instanceof ClientExceptionError &&
				error.response.type === ClientExceptionEntityTypeEnum.UnexpectedEventOccurred
			) {
				dispatch(
					showSnackbar({
						type: SnackbarType.SNACKBAR_ERROR,
						message:
							`Fehler beim Abruf der Informationen zu Keycloak-Benutzer mit E-Mail-Adresse ${email}. ` +
							'Handelt es sich vielleicht um eine ungültige E-Mail-Adresse?'
					})
				);
			} else {
				dispatch(showSnackbarError(error));
			}

			dispatch(getAuthUserFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const getUsersByRoleRequestAction = (searchParams: ListSearchParams): GetUsersByRoleRequestAction => {
	return {
		type: GET_USERS_BY_ROLE_REQUEST,
		searchParams
	};
};

export const getUsersByRoleSuccessAction = (
	users: ClientPersonExtended[],
	role: string,
	sorting: TableSorting,
	pagination: Pagination
): GetUsersByRoleSuccessAction => {
	return {
		type: GET_USERS_BY_ROLE_SUCCESS,
		users,
		role,
		sorting,
		pagination
	};
};

export const getUsersByRoleFailureAction = (error: BaseError): GetUsersByRoleFailureAction => {
	return {
		type: GET_USERS_BY_ROLE_FAILURE,
		error
	};
};

/**
 * Returns the correct SortColumnEnum
 * @param columnId Not processed column id
 */
const toSortColumnListByRoleEnum = (columnId: string): GetPersonsFilteredByRoleSortColumnEnum | undefined => {
	switch (columnId) {
		case 'email': {
			return GetPersonsFilteredByRoleSortColumnEnum.Email;
		}
		case 'firstName': {
			return GetPersonsFilteredByRoleSortColumnEnum.FirstName;
		}
		case 'lastName': {
			return GetPersonsFilteredByRoleSortColumnEnum.LastName;
		}
	}

	return undefined;
};

const toSortColumnListByRoleDirection = (
	direction: TableColumnSortingDirection
): GetPersonsFilteredByRoleSortDirectionEnum => {
	return direction === TableColumnSortingDirection.ASC
		? GetPersonsFilteredByRoleSortDirectionEnum.Asc
		: GetPersonsFilteredByRoleSortDirectionEnum.Desc;
};

export const getUsersByRole = (searchParams: ListSearchParams): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<UserActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		const { role, sorting, pagination } = searchParams;
		const { page, rowsPerPage } = pagination;

		try {
			dispatch(getUsersByRoleRequestAction(searchParams));

			const response = await participantsAdminuiPersonApi().getPersonsFilteredByRole({
				page,
				count: rowsPerPage,
				sortColumn: toSortColumnListByRoleEnum(sorting.columnId) ?? GetPersonsFilteredByRoleSortColumnEnum.Email,
				sortDirection: toSortColumnListByRoleDirection(sorting.direction),
				roleKey: role
			});

			// Prepare data to dispatch getUsersByRoleSuccess
			const users = response.content ? response.content : [];
			const pagination: Pagination = {
				page: response.page?.number ?? 0,
				rowsPerPage: response.page?.size ?? initialUserState.list.searchParams.pagination.rowsPerPage,
				total: response.page?.totalElements ?? 0
			};

			dispatch(getUsersByRoleSuccessAction(users, role, sorting, pagination));
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(getUsersByRoleFailureAction(wrapIntoErrorObject(error)));
		}
	};
};

export const changeEmailAddressByAdminRequest = (
	changeEmailAddressRequestParams: ClientPersonChangeEmailAddressByAdminRequest
): ChangeEmailAddressByAdminRequestAction => {
	return {
		type: CHANGE_EMAIL_ADDRESS_BY_ADMIN_REQUEST,
		changeEmailAddressRequestParams
	};
};

export const changeEmailAddressByAdminSuccess = (
	user: ClientPersonExtended
): ChangeEmailAddressByAdminSuccessAction => {
	return {
		type: CHANGE_EMAIL_ADDRESS_BY_ADMIN_SUCCESS,
		user
	};
};

export const changeEmailAddressByAdminFailure = (error: BaseError): ChangeEmailAddressByAdminFailureAction => {
	return {
		type: CHANGE_EMAIL_ADDRESS_BY_ADMIN_FAILURE,
		error
	};
};

export const changeEmailAddressByAdmin = (
	changeEmailAddressRequestParams: ClientPersonChangeEmailAddressByAdminRequest
): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<UserActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		const { user: userState } = getState();

		try {
			dispatch(changeEmailAddressByAdminRequest(changeEmailAddressRequestParams));
			const response: ClientPersonChangeEmailAddressByAdminResponse =
				await participantsAdminuiPersonEventsApi().onChangeEmailAddressRequest({
					clientPersonChangeEmailAddressByAdminRequest: changeEmailAddressRequestParams
				});
			// Endpoint does not fill the roleAssignments list. Hence, we re-use the current assignments.
			const userWithNewEmail: ClientPersonExtended = {
				...response.updatedPerson,
				roleAssignments: userState.detail.user?.roleAssignments
			};
			dispatch(changeEmailAddressByAdminSuccess(userWithNewEmail));
			dispatch(
				showSnackbar({
					type: SnackbarType.SNACKBAR_SUCCESS,
					message: 'Email wurde erfolgreich geändert.'
				})
			);
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(changeEmailAddressByAdminFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const changeNameByAdminRequest = (
	changeNameRequestParams: ClientPersonChangeNameByAdminRequest
): ChangeNameByAdminRequestAction => {
	return {
		type: CHANGE_NAME_BY_ADMIN_REQUEST,
		changeNameRequestParams
	};
};

export const changeNameByAdminSuccess = (user: ClientPersonExtended): ChangeNameByAdminSuccessAction => {
	return {
		type: CHANGE_NAME_BY_ADMIN_SUCCESS,
		user
	};
};

export const changeNameByAdminFailure = (error: BaseError): ChangeNameByAdminFailureAction => {
	return {
		type: CHANGE_NAME_BY_ADMIN_FAILURE,
		error
	};
};

export const changeNameByAdmin = (
	changeNameRequestParams: ClientPersonChangeNameByAdminRequest
): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<UserActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		const { user: userState } = getState();

		try {
			dispatch(changeNameByAdminRequest(changeNameRequestParams));

			const response: ClientPersonChangeNameByAdminResponse =
				await participantsAdminuiPersonEventsApi().onChangeNameRequest({
					clientPersonChangeNameByAdminRequest: changeNameRequestParams
				});

			// Endpoint does not fill the roleAssignments list. Hence, we re-use the current assignments.
			const userWithNewName: ClientPersonExtended = {
				...response.updatedPerson,
				roleAssignments: userState.detail.user?.roleAssignments
			};
			dispatch(changeNameByAdminSuccess(userWithNewName));
			dispatch(
				showSnackbar({
					type: SnackbarType.SNACKBAR_SUCCESS,
					message: 'Name wurde erfolgreich geändert.'
				})
			);
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(changeNameByAdminFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const blockLoginByAdminRequest = (
	blockLoginRequestParams: ClientPersonBlockLoginByAdminRequest
): BlockLoginByAdminRequestAction => {
	return {
		type: BLOCK_LOGIN_BY_ADMIN_REQUEST,
		blockLoginRequestParams
	};
};

export const blockLoginByAdminSuccess = (user: ClientPersonExtended): BlockLoginByAdminSuccessAction => {
	return {
		type: BLOCK_LOGIN_BY_ADMIN_SUCCESS,
		user
	};
};

export const blockLoginByAdminFailure = (error: BaseError): BlockLoginByAdminFailureAction => {
	return {
		type: BLOCK_LOGIN_BY_ADMIN_FAILURE,
		error
	};
};

export const blockLoginByAdmin = (
	blockLoginRequestParams: ClientPersonBlockLoginByAdminRequest
): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<UserActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(blockLoginByAdminRequest(blockLoginRequestParams));

			const response: ClientPersonBlockLoginByAdminResponse =
				await participantsAdminuiPersonEventsApi().onBlockLoginRequest({
					clientPersonBlockLoginByAdminRequest: blockLoginRequestParams
				});

			// Endpoint does not fill the roleAssignments list. Hence, we re-use the current assignments.
			const userWithNewStatus: ClientPersonExtended = {
				...response.updatedPerson,
				roleAssignments: getState().user.detail.user?.roleAssignments
			};
			dispatch(blockLoginByAdminSuccess(userWithNewStatus));

			const { firstName, lastName } = getState().user.detail.user || {};

			dispatch(
				showSnackbar({
					type: SnackbarType.SNACKBAR_SUCCESS,
					message: `Die Person ${firstName} ${lastName} wurde erfolgreich gesperrt.`
				})
			);
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(blockLoginByAdminFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const unblockLoginByAdminRequest = (
	unblockLoginRequestParams: ClientPersonUnblockLoginByAdminRequest
): UnblockLoginByAdminRequestAction => {
	return {
		type: UNBLOCK_LOGIN_BY_ADMIN_REQUEST,
		unblockLoginRequestParams
	};
};

export const unblockLoginByAdminSuccess = (user: ClientPersonExtended): UnblockLoginByAdminSuccessAction => {
	return {
		type: UNBLOCK_LOGIN_BY_ADMIN_SUCCESS,
		user
	};
};

export const unblockLoginByAdminFailure = (error: BaseError): UnblockLoginByAdminFailureAction => {
	return {
		type: UNBLOCK_LOGIN_BY_ADMIN_FAILURE,
		error
	};
};

export const unblockLoginByAdmin = (
	unblockLoginRequestParams: ClientPersonUnblockLoginByAdminRequest
): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<UserActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(unblockLoginByAdminRequest(unblockLoginRequestParams));
			const response: ClientPersonUnblockLoginByAdminResponse =
				await participantsAdminuiPersonEventsApi().onUnblockLoginRequest({
					clientPersonUnblockLoginByAdminRequest: unblockLoginRequestParams
				});
			// Endpoint does not fill the roleAssignments list. Hence, we re-use the current assignments.
			const userWithNewStatus: ClientPersonExtended = {
				...response.updatedPerson,
				roleAssignments: getState().user.detail.user?.roleAssignments
			};

			dispatch(unblockLoginByAdminSuccess(userWithNewStatus));

			const { firstName, lastName } = getState().user.detail.user || {};

			dispatch(
				showSnackbar({
					type: SnackbarType.SNACKBAR_SUCCESS,
					message: `Die Person ${firstName} ${lastName} wurde erfolgreich entsperrt.`
				})
			);
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(unblockLoginByAdminFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const unblockAutomaticallyBlockedLoginByAdminRequest = (
	unblockLoginRequestParams: ClientPersonUnblockAutomaticallyBlockedLoginByAdminRequest
): UnblockAutomaticallyBlockedLoginByAdminRequestAction => {
	return {
		type: UNBLOCK_AUTOMATICALLY_BLOCKED_LOGIN_BY_ADMIN_REQUEST,
		unblockLoginRequestParams
	};
};

export const unblockAutomaticallyBlockedLoginByAdminSuccess = (
	user: ClientPersonExtended
): UnblockAutomaticallyBlockedLoginByAdminSuccessAction => {
	return {
		type: UNBLOCK_AUTOMATICALLY_BLOCKED_LOGIN_BY_ADMIN_SUCCESS,
		user
	};
};

export const unblockAutomaticallyBlockedLoginByAdminFailure = (
	error: BaseError
): UnblockAutomaticallyBlockedLoginByAdminFailureAction => {
	return {
		type: UNBLOCK_AUTOMATICALLY_BLOCKED_LOGIN_BY_ADMIN_FAILURE,
		error
	};
};

export const unblockAutomaticallyBlockedLoginByAdmin = (
	unblockAutomaticallyBlockedLoginRequestParams: ClientPersonUnblockAutomaticallyBlockedLoginByAdminRequest
): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<UserActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(unblockAutomaticallyBlockedLoginByAdminRequest(unblockAutomaticallyBlockedLoginRequestParams));
			const response: ClientPersonUnblockAutomaticallyBlockedLoginByAdminResponse =
				await participantsAdminuiPersonEventsApi().onUnblockAutomaticallyBlockedLoginRequest({
					clientPersonUnblockAutomaticallyBlockedLoginByAdminRequest: unblockAutomaticallyBlockedLoginRequestParams
				});
			// Endpoint does not fill the roleAssignments list. Hence, we re-use the current assignments.
			const userWithNewStatus: ClientPersonExtended = {
				...response.updatedPerson,
				roleAssignments: getState().user.detail.user?.roleAssignments
			};

			dispatch(unblockAutomaticallyBlockedLoginByAdminSuccess(userWithNewStatus));

			const { firstName, lastName } = getState().user.detail.user || {};

			dispatch(
				showSnackbar({
					type: SnackbarType.SNACKBAR_SUCCESS,
					message: `Die Person ${firstName} ${lastName} wurde erfolgreich entsperrt.`
				})
			);
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(unblockAutomaticallyBlockedLoginByAdminFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const resendVerificationMailByAdminRequest = (userId: string): ResendVerificationMailByAdminRequestAction => {
	return {
		type: RESEND_VERIFICATION_MAIL_BY_ADMIN_REQUEST,
		userId
	};
};

export const resendVerificationMailByAdminSuccess = (userId: string): ResendVerificationMailByAdminSuccessAction => {
	return {
		type: RESEND_VERIFICATION_MAIL_BY_ADMIN_SUCCESS,
		userId
	};
};

export const resendVerificationMailByAdminFailure = (error: BaseError): ResendVerificationMailByAdminFailureAction => {
	return {
		type: RESEND_VERIFICATION_MAIL_BY_ADMIN_FAILURE,
		error
	};
};

export const resendVerificationMailByAdmin = (userId: string): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<UserActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(resendVerificationMailByAdminRequest(userId));

			await participantsAdminuiPersonEventsApi().onSendVerificationMailRequest({
				clientPersonSendVerificationMailByAdminRequest: {
					personId: userId
				}
			});
			// We are not changing the state since there is no (immediate) change.
			// Just sending the userId for action logging purpose
			dispatch(resendVerificationMailByAdminSuccess(userId));
			dispatch(
				showSnackbar({
					type: SnackbarType.SNACKBAR_SUCCESS,
					message: 'Aufforderung zur E-Mail-Bestätigung wurde versandt.'
				})
			);
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(resendVerificationMailByAdminFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const resetPasswordByAdminRequest = (userId: string): ResetPasswordByAdminRequestAction => {
	return {
		type: RESET_PASSWORD_BY_ADMIN_REQUEST,
		userId
	};
};

export const resetPasswordByAdminSuccess = (userId: string): ResetPasswordByAdminSuccessAction => {
	return {
		type: RESET_PASSWORD_BY_ADMIN_SUCCESS,
		userId
	};
};

export const resetPasswordByAdminFailure = (error: BaseError): ResetPasswordByAdminFailureAction => {
	return {
		type: RESET_PASSWORD_BY_ADMIN_FAILURE,
		error
	};
};

export const resetPasswordByAdmin = (userId: string): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<UserActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(resetPasswordByAdminRequest(userId));

			await participantsAdminuiPersonEventsApi().onResetPasswordRequest({
				clientPersonResetPasswordByAdminRequest: {
					personId: userId
				}
			});
			// We are not changing the state since there is no change that is relevant for admin UI.
			// Just sending the userId for action logging purpose
			dispatch(resetPasswordByAdminSuccess(userId));
			dispatch(
				showSnackbar({
					type: SnackbarType.SNACKBAR_SUCCESS,
					message: 'Das Passwort wurde zurückgesetzt.'
				})
			);
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(resetPasswordByAdminFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const setEmailVerifiedByAdminRequest = (userId: string): SetEmailVerifiedByAdminRequestAction => {
	return {
		type: SET_EMAIL_VERIFIED_BY_ADMIN_REQUEST,
		userId
	};
};

export const setEmailVerifiedByAdminSuccess = (
	userId: string,
	verifiedEmail: string
): SetEmailVerifiedByAdminSuccessAction => {
	return {
		type: SET_EMAIL_VERIFIED_BY_ADMIN_SUCCESS,
		userId,
		verifiedEmail
	};
};

export const setEmailVerifiedByAdminFailure = (error: BaseError): SetEmailVerifiedByAdminFailureAction => {
	return {
		type: SET_EMAIL_VERIFIED_BY_ADMIN_FAILURE,
		error
	};
};

export const setEmailVerifiedByAdmin = (userId: string): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<UserActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(setEmailVerifiedByAdminRequest(userId));

			const response: ClientPersonSetEmailVerifiedByAdminResponse =
				await participantsAdminuiPersonEventsApi().onSetEmailVerifiedRequest({
					clientPersonSetEmailVerifiedByAdminRequest: {
						personId: userId,
						emailAddressVerified: true
					}
				});

			// this error can practially never happen since every client person must have an email
			if (!response.updatedPerson.email) {
				throw new Error('Unerwartete Server-Antwort');
			}
			dispatch(setEmailVerifiedByAdminSuccess(userId, response.updatedPerson.email));
			dispatch(
				showSnackbar({
					type: SnackbarType.SNACKBAR_SUCCESS,
					message: 'E-Mail-Adresse bestätigt.'
				})
			);
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(setEmailVerifiedByAdminFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const deleteUserByAdminRequest = (userId: string): DeleteUserByAdminRequestAction => {
	return {
		type: DELETE_USER_BY_ADMIN_REQUEST,
		userId
	};
};

export const deleteUserByAdminSuccess = (userId: string): DeleteUserByAdminSuccessAction => {
	return {
		type: DELETE_USER_BY_ADMIN_SUCCESS,
		userId
	};
};

export const deleteUserByAdminFailure = (error: BaseError): DeleteUserByAdminFailureAction => {
	return {
		type: DELETE_USER_BY_ADMIN_FAILURE,
		error
	};
};

export const deleteUserByAdmin = (userId: string, check: string): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<UserActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(deleteUserByAdminRequest(userId));

			await sharedAdminDataprivacyApi().deletePerson1({
				personId: userId,
				check
			});

			dispatch(deleteUserByAdminSuccess(userId));
			dispatch(
				showSnackbar({
					type: SnackbarType.SNACKBAR_SUCCESS,
					message: 'Person wird gelöscht. Bitte nach ein paar Sekunden hier klicken.',
					url: userId
				})
			);
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(deleteUserByAdminFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const changeStatusByAdminRequest = (
	changeStatusRequestParams: ClientPersonChangeStatusRequest
): ChangeStatusByAdminRequestAction => {
	return {
		type: CHANGE_STATUS_BY_ADMIN_REQUEST,
		changeStatusRequestParams
	};
};

export const changeStatusByAdminSuccess = (user: ClientPersonExtended): ChangeStatusByAdminSuccessAction => {
	return {
		type: CHANGE_STATUS_BY_ADMIN_SUCCESS,
		user
	};
};

export const changeStatusByAdminFailure = (error: BaseError): ChangeStatusByAdminFailureAction => {
	return {
		type: CHANGE_STATUS_BY_ADMIN_FAILURE,
		error
	};
};

export const changeStatusByAdmin = (
	changeStatusRequestParams: ClientPersonChangeStatusRequest
): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<UserActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(changeStatusByAdminRequest(changeStatusRequestParams));

			const response: ClientPersonChangeStatusConfirmation =
				await participantsAdminuiPersonEventsApi().onPersonChangeStatusRequest({
					clientPersonChangeStatusRequest: changeStatusRequestParams
				});
			const { updatedPerson } = response;
			const message = !!updatedPerson?.statuses?.some(
				status => status === ClientPersonExtendedStatusesEnum.ParallelWorldInhabitant
			)
				? `${updatedPerson.firstName} wurde in die Parallelwelt geschickt.`
				: `${updatedPerson.firstName} wurde wieder in die richtige Welt geholt.`;
			dispatch(changeStatusByAdminSuccess(updatedPerson));
			dispatch(
				showSnackbar({
					type: SnackbarType.SNACKBAR_SUCCESS,
					message
				})
			);
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(changeStatusByAdminFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const cancelChangeEmailRequest = (userId: string): CancelChangeEmailRequestAction => {
	return {
		type: CANCEL_CHANGE_EMAIL_REQUEST,
		userId
	};
};

export const cancelChangeEmailSuccess = (userId: string): CancelChangeEmailSuccessAction => {
	return {
		type: CANCEL_CHANGE_EMAIL_SUCCESS,
		userId
	};
};

export const cancelChangeEmailFailure = (error: BaseError): CancelChangeEmailFailureAction => {
	return {
		type: CANCEL_CHANGE_EMAIL_FAILURE,
		error
	};
};

export const cancelChangeEmail = (userId: string): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<UserActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(cancelChangeEmailRequest(userId));

			// we do not need the response since all we need to change is remove the pendingNewEmail
			// attribute in the current user detail (done by the reducer)
			await participantsAdminuiPersonEventsApi().onCancelChangeEmailAddressRequest({
				clientPersonCancelChangeEmailAddressByAdminRequest: {
					personId: userId
				}
			});

			// userId is only used for better tracability in Redux toolbar
			dispatch(cancelChangeEmailSuccess(userId));
			dispatch(
				showSnackbar({
					type: SnackbarType.SNACKBAR_SUCCESS,
					message: 'E-Mail-Änderung abgelehnt.'
				})
			);
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(cancelChangeEmailFailure(wrapIntoErrorObject(error)));
		}
	};
};
