import React from 'react';
import { ClientGroupExtendedDetail } from '@DigitaleDoerfer/digitale-doerfer-api';
import FormHeaderView from '../../../shared/views/PersonDetail/FormHeader.view';
import GroupDetailsGroupTypeView from './GroupDetailsGroupType.view';
import { Grid, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import GroupNameView from '../../../shared/views/Group/GroupName.view';
import GroupShortNameView from '../../../shared/views/Group/GroupShortName.view';
import { geoAreaService, timeService } from '../../../ServiceFactory';
import { TimestampFormat } from '../../../shared/services/Time.service';
import GroupAdministratorsView from '../../../shared/views/Group/GroupAdministrators.view';
import GroupGeoAreasView from '../../../shared/views/Group/GroupGeoAreas.view';
import { AnyGeoArea } from '../../../shared/utils/AnyGeoArea';

const useStyles = makeStyles(
	createStyles({
		geoAreaElementNodeTableCellProps: {
			height: 35
		}
	})
);

interface Props {
	group: ClientGroupExtendedDetail;
	allGeoAreas: AnyGeoArea[];
	actionMenu?: JSX.Element;
}

const GroupDetailsView = (props: Props): JSX.Element => {
	const { group, actionMenu, allGeoAreas } = props;
	const geoAreaElements = geoAreaService().makeGeoAreaElements(group, allGeoAreas);
	const classes = useStyles();
	return (
		<>
			<FormHeaderView
				title={`${group.name}`}
				subtitle={
					group.created !== undefined ? (
						<>
							<Typography component="span" display="inline">
								Erstellt am{' '}
							</Typography>
							<Typography component="span" display="inline" style={{ fontWeight: 'bold' }}>
								{timeService().parseTimestamp(group.created, TimestampFormat.DD_MM_YY_HH_MM)}
							</Typography>
						</>
					) : undefined
				}
				actionMenu={actionMenu}
			/>
			<Grid container direction="column" spacing={4}>
				<Grid item>
					<Typography variant="h2" gutterBottom>
						Basisinformationen
					</Typography>
					<Grid container direction="column" spacing={4}>
						<Grid item>
							<GroupDetailsGroupTypeView
								accessibility={group.accessibility}
								contentVisibility={group.contentVisibility}
								visibility={group.visibility}
							/>
						</Grid>
						<Grid item>
							<Grid container spacing={3}>
								<Grid item xs={12} md={6}>
									<GroupNameView groupName={group.name} isReadOnlyField={true} />
								</Grid>
								<Grid item sm={4} md={3} lg={2}>
									<GroupShortNameView groupShortName={group.shortName} isReadOnlyField={true} />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{group.groupMembershipAdmins && group.groupMembershipAdmins.length > 0 && (
					<Grid item>
						<GroupAdministratorsView admins={group.groupMembershipAdmins} title={'Administratoren'} />
					</Grid>
				)}
				{geoAreaElements.length > 0 && (
					<Grid item>
						<GroupGeoAreasView
							groupMembershipAdminGeoAreas={geoAreaElements}
							readOnly={true}
							title={'Gemeinden'}
							hideHomeAreaOfAdministratorsTypography={true}
							useAdditionalGeoAreas={false}
							hideParent={true}
							hideUnselectedChildren={true}
							geoAreaElementNodeTableCellProps={{
								className: classes.geoAreaElementNodeTableCellProps
							}}
						/>
					</Grid>
				)}
			</Grid>
		</>
	);
};

export default GroupDetailsView;
