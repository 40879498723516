import {
	ClientCommunity,
	GrapevineAdminuiGroupApi,
	GrapevineAdminuiNewssourceApi,
	ParticipantsCommunityApi,
	ParticipantsRoleAssignmentApi
} from '@DigitaleDoerfer/digitale-doerfer-api';
import { RelatedEntity } from '../../modules/users/store/User.state';
import { RoleManagementPermission, RoleService } from './Role.service';

export enum EntityName {
	COMMUNITY = 'Community',
	POOLING_STATION = 'PoolingStation',
	GROUP = 'Group',
	SHOP = 'Shop',
	RELATED_ENTITY_IS_NULL = 'RelatedEntityIsNull',
	TENANT = 'Tenant',
	NEWSSOURCE = 'NewsSource'
}

export class RoleAssignmentRelatedEntityService {
	constructor(
		public grapevineAdminuiGroupApi: GrapevineAdminuiGroupApi,
		public grapevineAdminuiNewssourceApi: GrapevineAdminuiNewssourceApi,
		public participantsCommunityApi: ParticipantsCommunityApi,
		public participantsRoleAssignmentApi: ParticipantsRoleAssignmentApi,
		public roleService: RoleService
	) {}

	/**
	 * Returns related entities list based on the giving entity name.
	 * @param relatedEntityName Related entity name.
	 */
	async getRelatedEntities(relatedEntityName: string, tenantId?: string): Promise<RelatedEntity[] | undefined> {
		let relatedEntities: RelatedEntity[] | undefined;

		switch (relatedEntityName) {
			case EntityName.POOLING_STATION:
				// TODO: const clientPoolingStations: ClientPoolingStation[] = await logisticsPoolingstationApi().getAllPoolingStations({  });
				break;
			case EntityName.COMMUNITY:
				const permission: RoleManagementPermission = await this.roleService.getRoleManagementPermission();
				const clientTenants: ClientCommunity[] = await this.participantsCommunityApi.getAllTenants({});
				if (permission.isRestAdmin || permission.isGlobalUser) {
					relatedEntities = this.getRelatedEntitiesFromTenants(clientTenants);
				} else {
					const ownTenants = clientTenants.filter(
						tenant => tenant.id && permission.tenantIdsToManage.includes(tenant.id)
					);
					relatedEntities = this.getRelatedEntitiesFromTenants(ownTenants);
				}
				break;
			case EntityName.GROUP:
				const clientGroups = await this.grapevineAdminuiGroupApi.getGroups({
					tenantId
				});
				if (clientGroups && clientGroups.content)
					relatedEntities = clientGroups.content.map(clientGroup => {
						const id = clientGroup.id ? clientGroup.id : '';
						const name = clientGroup.name ? clientGroup.name : '';
						return { id, name };
					});
				break;
			case EntityName.SHOP:
				// TODO: const clientShops: ClientShop = await participantsShopApi().getAllShops({});
				break;
			case EntityName.NEWSSOURCE:
				const pageOfClientNewsSource = await this.grapevineAdminuiNewssourceApi.getNewsSources({});
				if (pageOfClientNewsSource && pageOfClientNewsSource.content)
					relatedEntities = pageOfClientNewsSource.content.map(pageOfClientNewsSource => {
						const id = pageOfClientNewsSource.id ? pageOfClientNewsSource.id : '';
						const name = pageOfClientNewsSource.siteName ? pageOfClientNewsSource.siteName : '';
						return { id, name };
					});
				break;
			default:
				break;
		}
		return relatedEntities;
	}

	getRelatedEntitiesFromTenants(tenants: ClientCommunity[]): RelatedEntity[] {
		return tenants.map(cc => {
			const id = cc.id ? cc.id : '';
			const name = cc.name ? cc.name : '';
			return { id, name };
		});
	}

	isTenantBased(relatedEntityName: string): boolean {
		return relatedEntityName === EntityName.TENANT;
	}

	isNewsSourceBased(relatedEntityName: string): boolean {
		return relatedEntityName === EntityName.NEWSSOURCE;
	}
	isGroupBased(relatedEntityName: string): boolean {
		return relatedEntityName === EntityName.GROUP;
	}
}
