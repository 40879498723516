import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import { sharedAdminuiAppApi } from '../../../ServiceFactory';
import { ellipsify, getCurrentPage, getNextPage, isEmptyOrNull } from '../helpers';
import { useStore } from '../store';
import { Contract, ListType } from '../types';

const useFetchContracts = (
	tenantIds: string[],
	appVariantIds: string[],
	search?: string
): UseInfiniteQueryResult<Contract[]> => {
	const { setCurrentListPage, currentContractsPage } = useStore();
	return useInfiniteQuery<Contract[]>(
		[ListType.CONTRACT, { tenantIds, appVariantIds, search }],
		({ pageParam = 0 }) => {
			return sharedAdminuiAppApi()
				.getContractsByTenantOrAppVariant({ count: 500, tenantIds, appVariantIds, page: pageParam, search })
				.then(response => response)
				.then(data => {
					const { content, page } = data;
					const { totalPages, number: responsePage } = page || {};
					const processedItems =
						content?.map(item => {
							const newItem = { ...item } as Contract;
							const formattedDate = item.created ? new Date(item.created).toLocaleString('de-DE') : '';
							newItem.name = isEmptyOrNull(item?.notes)
								? `Nutzungsvertrag ${item.id} von ${formattedDate}`
								: `${ellipsify(item.notes, 40)} von ${formattedDate}`;
							return newItem;
						}) || [];
					const currentPage = getCurrentPage(responsePage, totalPages);
					setCurrentListPage(ListType.CONTRACT, currentPage);
					return processedItems;
				});
		},
		{
			getNextPageParam: () => getNextPage(currentContractsPage)
		}
	);
};

export default useFetchContracts;
