import React from 'react';
import {
	Box,
	FormControl,
	FormLabel,
	Grid,
	Input,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography
} from '@mui/material';
import { ClientPersonExtended } from '@DigitaleDoerfer/digitale-doerfer-api';
import ClickInfoTooltipView from '../../../shared/views/ClickInfoTooltip.view';

interface Props {
	selectedTenantId: string | null;
	groupMembershipAdmins: ClientPersonExtended[];
	handleGroupTenantSelectionChange: (mandantId: string) => void;
}

const GroupTenantsView = (props: Props): JSX.Element => {
	const { selectedTenantId, groupMembershipAdmins, handleGroupTenantSelectionChange } = props;
	const uniqueTenantGroupAdmins: ClientPersonExtended[] = [];
	groupMembershipAdmins.forEach((groupAdmin: ClientPersonExtended) => {
		if (!uniqueTenantGroupAdmins.some(user => user.homeAreaTenantId === groupAdmin.homeAreaTenantId)) {
			uniqueTenantGroupAdmins.push(groupAdmin);
		}
	});

	return (
		<>
			<FormControl variant="standard">
				<FormLabel>
					Mandant
					<ClickInfoTooltipView>
						<Box width={250} sx={{ color: 'white' }}>
							<Box>
								<Typography variant="body2">Die Gruppe wird dem ausgewählten Mandanten zugeordnet.</Typography>
							</Box>
							<Box pt={1}>
								<Typography variant="body2">
									In der Standardeinstellung bestimmt der zuerst hinzugefügte Administrator den Mandanten. Dieser muss
									üblicherweise nicht angepasst werden.
								</Typography>
							</Box>
						</Box>
					</ClickInfoTooltipView>
				</FormLabel>
			</FormControl>
			<Box mb={4}>
				<Grid container>
					<Grid item xs={12} md={3}>
						{uniqueTenantGroupAdmins.length > 0 && (
							<Select
								variant="standard"
								name="mandant"
								value={selectedTenantId || uniqueTenantGroupAdmins[0].homeAreaTenantId}
								onChange={(event: SelectChangeEvent<string>): void => {
									if (event.target.value) {
										handleGroupTenantSelectionChange(`${event.target.value}`);
									}
								}}
								input={<Input id="mandant" />}
								placeholder="Mandant"
								required
								fullWidth
							>
								{uniqueTenantGroupAdmins.map((user: ClientPersonExtended) => {
									return (
										<MenuItem key={user.id} value={user.homeAreaTenantId}>
											{user.homeAreaTenantName}
										</MenuItem>
									);
								})}
							</Select>
						)}
					</Grid>
				</Grid>
			</Box>
		</>
	);
};

export default GroupTenantsView;
