import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	ClientExternalPostDeleteConfirmation,
	ClientNewsSource,
	PagedModelClientNewsSource,
	PagedModelClientPost,
	GetAllExternalPostsByNewsSourcePostTypesEnum as PostTypeEnum
} from '@DigitaleDoerfer/digitale-doerfer-api';
import {
	showSnackbar,
	showSnackbarError,
	SnackbarType
} from '../../shared/views/SnackbarNotification/store/SnackbarNotification.actions';
import { grapevineAdminuiNewssourceApi, grapevineAdminuiPostEventApi, grapevinePostApi } from '../../ServiceFactory';
import ExternalPostsView from './ExternalPosts.view';
import Pagination from '@mui/material/Pagination';
import { PlatformAdminUIThunkType } from '../../store/store';
import { ShowSnackbar } from '../../shared/views/SnackbarNotification/store/SnackbarNotificationActionTypes';

interface ExternalPostType {
	name: string;
	postType?: PostTypeEnum;
}

interface Pagination {
	page: number;
	rowsPerPage: number;
	totalElements: number;
	totalPages: number;
}

function ExternalPostsContainer(): JSX.Element {
	const dispatch = useDispatch<PlatformAdminUIThunkType<ShowSnackbar>>();
	const [externalPostsType, setExternalPostsType] = useState<ExternalPostType[]>([]);

	const initialPaginationParams = {
		page: 0,
		rowsPerPage: 9,
		totalElements: 0,
		totalPages: 0
	};

	const [pagination, setPagination] = useState<Pagination>(initialPaginationParams);
	const [pageIndex, setPageIndex] = useState<number>(0);
	const [searchString, setSearchString] = useState<string | undefined>('Initial Search');
	const [pageOfClientNewsSource, setPageOfClientNewsSource] = useState<ClientNewsSource[]>([]);
	const [IndividualclientNews, setIndividualclientNews] = useState<PagedModelClientPost>();
	const [selectedPostTypes, setSelectedPostTypes] = useState<string | undefined>('Alles');
	const [clientExternalPostDeleteConfirmation, setclientExternalPostDeleteConfirmation] =
		useState<ClientExternalPostDeleteConfirmation>();
	const [loading, setLoading] = useState(true);

	const getProcessedPostType = (ep: string | undefined): PostTypeEnum | undefined => {
		if (ep === 'Alles') {
			return undefined;
		}
		if (ep === 'NEWS_ITEM') {
			return PostTypeEnum.NewsItem;
		}
		if (ep === 'HAPPENING') {
			return PostTypeEnum.Happening;
		}
		if (ep === 'GOSSIP') {
			return PostTypeEnum.Gossip;
		}
		if (ep === 'OFFER') {
			return PostTypeEnum.Offer;
		}
		if (ep === 'SEEKING') {
			return PostTypeEnum.Seeking;
		}
		if (ep === 'SPECIAL_POST') {
			return PostTypeEnum.Seeking;
		}
		if (ep === 'SUGGESTION') {
			return PostTypeEnum.Suggestion;
		}
	};

	const handleChangePostType = (newPostType: string): void => {
		setPageIndex(initialPaginationParams.page);
		if (!newPostType) {
			return;
		}
		setSelectedPostTypes(newPostType);
	};

	const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number): void => {
		// Check if new page is the first or last page
		if (event) {
			event.preventDefault();
		}
		if (newPage === -1) {
			return;
		}
		// Scroll to the top of the page
		window.scrollTo(0, 0);
		setPageIndex(newPage - 1);
	};

	const handleSearch = async (searchString: string): Promise<void> => {
		setSearchString(searchString);
		setPageIndex(initialPaginationParams.page);
	};

	const handleDelete = async (PostID: string | undefined): Promise<void> => {
		try {
			const clientExternalPostDeleteConfirmation: ClientExternalPostDeleteConfirmation =
				await grapevineAdminuiPostEventApi().externalPostDeleteRequest1({
					clientExternalPostDeleteByIdRequest: { postId: PostID }
				});
			setclientExternalPostDeleteConfirmation(clientExternalPostDeleteConfirmation);
			dispatch(
				showSnackbar({
					type: SnackbarType.SNACKBAR_SUCCESS,
					message: 'Der Beitrag wird gelöscht.'
				})
			);
		} catch (error) {
			dispatch(showSnackbarError(error));
		}
	};

	useEffect(() => {
		const externalPostList = [
			{ name: 'Alles', postType: 'Alles' },
			{ name: 'Neuigkeiten', postType: 'NEWS_ITEM' },
			{ name: 'Veranstaltungen', postType: 'HAPPENING' }
		];
		const getProccesedExternalPosts = (): ExternalPostType[] => {
			return externalPostList.map(ep => {
				switch (ep.postType) {
					case 'Alles':
						return { name: 'Alles', postType: undefined };
					case PostTypeEnum.Happening:
						return { name: ep.name, postType: PostTypeEnum.Happening };
					case PostTypeEnum.NewsItem:
						return { name: ep.name, postType: PostTypeEnum.NewsItem };
					// Next cases are for future updates
					case PostTypeEnum.Suggestion:
						return { name: ep.name, postType: PostTypeEnum.Suggestion };
					case PostTypeEnum.Gossip:
						return { name: ep.name, postType: PostTypeEnum.Gossip };
					case PostTypeEnum.Offer:
						return { name: ep.name, postType: PostTypeEnum.Offer };
					case PostTypeEnum.Seeking:
						return { name: ep.name, postType: PostTypeEnum.Seeking };
					default:
						return { name: 'Nicht definiert', postType: undefined };
				}
			});
		};

		const extPosts = getProccesedExternalPosts();
		setExternalPostsType(extPosts);
		const getExternalPosts = async (): Promise<void> => {
			try {
				const pageOfClientNewsSourceTemp: PagedModelClientNewsSource =
					await grapevineAdminuiNewssourceApi().getNewsSources({});

				const pageOfClientNewsSource: PagedModelClientNewsSource = await grapevineAdminuiNewssourceApi().getNewsSources(
					{
						count: pageOfClientNewsSourceTemp.page?.totalElements
					}
				);

				if (pageOfClientNewsSource.content) {
					setPageOfClientNewsSource(pageOfClientNewsSource.content);
				}
			} catch (error) {
				// handle error
			}
			setLoading(false);
		};
		getExternalPosts();
	}, []);

	useEffect(() => {
		const initialPaginationParams = {
			page: 0,
			rowsPerPage: 9,
			totalElements: 0,
			totalPages: 0
		};
		const getIndividualclientNews = async (): Promise<void> => {
			const page = pageIndex ?? initialPaginationParams.page;
			const rowsPerPage = initialPaginationParams.rowsPerPage;
			const postType = getProcessedPostType(selectedPostTypes);
			if (searchString) {
				const IndividualclientNews: PagedModelClientPost = await grapevinePostApi().getAllExternalPostsByNewsSource({
					postTypes: postType ? [postType] : undefined,
					page,
					newsSourceId: searchString,
					count: rowsPerPage
				});

				if (IndividualclientNews.page?.totalPages && IndividualclientNews.content?.length !== 0) {
					const paginationResponse: Pagination = {
						page: IndividualclientNews.page?.number ?? initialPaginationParams.page,
						rowsPerPage: initialPaginationParams.rowsPerPage,
						totalElements: IndividualclientNews.page?.totalElements ?? initialPaginationParams.totalElements,
						totalPages: IndividualclientNews.page?.totalPages ?? initialPaginationParams.totalPages
					};
					setPagination(paginationResponse);
				} else {
					setPagination(initialPaginationParams);
				}
				setIndividualclientNews(IndividualclientNews);
			}
		};
		getIndividualclientNews();
	}, [searchString, pageIndex, selectedPostTypes, clientExternalPostDeleteConfirmation]);

	return (
		<>
			<ExternalPostsView
				loading={loading}
				externalPosts={IndividualclientNews}
				externalPostsTypes={externalPostsType}
				pageOfClientNewsSource={pageOfClientNewsSource}
				selectedPostTypes={selectedPostTypes}
				handleChangePostType={handleChangePostType}
				pagination={pagination}
				handleChangePage={handleChangePage}
				handleSearch={handleSearch}
				handleDelete={handleDelete}
			/>
		</>
	);
}

export default ExternalPostsContainer;
