import {
	ClientPersonReferenceWithEmailStatusEnum,
	ClientPostTypeEnum,
	ListFlagsIncludedStatusEnum
} from '@DigitaleDoerfer/digitale-doerfer-api';
import { FlaggedType } from '../store/FlaggedContent.state';

export class FlaggedContentViewService {
	renderInfo(info?: string): string {
		if (info) {
			return info;
		}

		return 'Keine Angabe';
	}

	getPostTypeDisplayname(type: ClientPostTypeEnum): string {
		switch (type) {
			case ClientPostTypeEnum.Gossip:
				return 'Plausch';
			case ClientPostTypeEnum.Happening:
				return 'Veranstaltung';
			case ClientPostTypeEnum.NewsItem:
				return 'News';
			case ClientPostTypeEnum.Offer:
				return 'Biete';
			case ClientPostTypeEnum.Seeking:
				return 'Suche';
			case ClientPostTypeEnum.Suggestion:
				return "Sag's uns";
			case ClientPostTypeEnum.SpecialPost:
				return 'Hilf mit';
			default: {
				return `Sonstiges`;
			}
		}
	}

	getEntityTypeDisplayName(entityType: string): string {
		const lastWord = entityType.match(/(\w+)$/)?.[0].toUpperCase();

		switch (lastWord) {
			case 'CHAT': {
				return 'Chat';
			}
			case 'SEEKING': {
				return this.getPostTypeDisplayname(ClientPostTypeEnum.Seeking);
			}
			case 'GOSSIP': {
				return this.getPostTypeDisplayname(ClientPostTypeEnum.Gossip);
			}
			case 'EVENT': {
				return 'Event';
			}
			case 'NEWSITEM': {
				return this.getPostTypeDisplayname(ClientPostTypeEnum.NewsItem);
			}
			case 'HAPPENING': {
				return this.getPostTypeDisplayname(ClientPostTypeEnum.Happening);
			}
			case 'COMMENT': {
				return 'Kommentar';
			}
			case 'SUGGESTION': {
				return this.getPostTypeDisplayname(ClientPostTypeEnum.Suggestion);
			}
			case 'OFFER': {
				return this.getPostTypeDisplayname(ClientPostTypeEnum.Offer);
			}
			case 'GROUP': {
				return 'Gruppe';
			}
			case 'SPECIALPOST': {
				return this.getPostTypeDisplayname(ClientPostTypeEnum.SpecialPost);
			}
			default: {
				return 'Sonstiges';
			}
		}
	}

	getStatusDisplayName(status: ClientPersonReferenceWithEmailStatusEnum): string {
		switch (status) {
			case ClientPersonReferenceWithEmailStatusEnum.Registered: {
				return 'Registriert';
			}
			default: {
				return 'Sonstiges';
			}
		}
	}

	translateFlaggedTypeDE(entityType: string): string {
		switch (entityType) {
			case FlaggedType.CHAT:
				return 'Chat';
			case FlaggedType.COMMENT:
				return 'Kommentar';
			case FlaggedType.POST:
				return 'Beitrag';
			default:
				return 'Sonstiges';
		}
	}

	translateFlagStatusDE(flagStatus: string): string {
		switch (flagStatus) {
			case ListFlagsIncludedStatusEnum.Open:
				return 'Offen';
			case ListFlagsIncludedStatusEnum.InProgress:
				return 'In Bearbeitung';
			case ListFlagsIncludedStatusEnum.Accepted:
				return 'Akzeptiert';
			case ListFlagsIncludedStatusEnum.Rejected:
				return 'Abgelehnt';
			default:
				return '';
		}
	}

	getPrefixedTitleByPostType(flaggedType: FlaggedType): string {
		switch (flaggedType) {
			case FlaggedType.CHAT:
			case FlaggedType.COMMENT:
			case FlaggedType.POST:
			case FlaggedType.OTHER:
				return 'Gemeldeter';
			default:
				return '';
		}
	}
}
