import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../store/store';
import { FlagSummary } from './shared/FlaggedContentDetailsCommons';
import {
	ClientUserGeneratedContentFlagGroup,
	ClientUserGeneratedContentFlagGroupStatusEnum,
	ClientUserGeneratedContentFlagStatusRecord
} from '@DigitaleDoerfer/digitale-doerfer-api';
import FlaggedGroupView from './FlaggedGroup.view';
import { RouteComponentProps } from 'react-router-dom';
import FormHeaderView from '../../../shared/views/PersonDetail/FormHeader.view';
import { getGroupFlag } from '../store/FlaggedContent.actions';
import { FlaggedContentActionTypes } from '../store/FlaggedContentActionTypes';
import { Container, Paper } from '@mui/material';
import MainLoadingIndicator from '../../../shared/views/LoadingIndicators/MainLoadingIndicator.view';
import FlaggedContentDetailsFooterView from './shared/FlaggedContentDetailsFooter.view';
import FlaggedDetailsActionsMenuContainer from './shared/FlaggedDetailsActionsMenu.container';

interface Identifiable {
	id: string;
	entityType: string;
}

interface Props extends RouteComponentProps<Identifiable> {
	loading: boolean;
	flaggedEntity?: ClientUserGeneratedContentFlagGroup;
	statusRecords: ClientUserGeneratedContentFlagStatusRecord[];
	flagSummary: FlagSummary;
	status: ClientUserGeneratedContentFlagGroupStatusEnum | undefined;
	getFlaggedGroup: (flagId: string) => void;
}

interface State {
	contentRefreshed: boolean;
}

class FlaggedGroupContainer extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { contentRefreshed: false };
	}

	componentDidMount(): void {
		const { getFlaggedGroup, match } = this.props;
		const { id } = match.params;
		getFlaggedGroup(id);
		this.setState({ contentRefreshed: true });
	}

	render(): JSX.Element {
		const { loading, flaggedEntity, statusRecords, flagSummary, status } = this.props;
		const { contentRefreshed } = this.state;
		return (
			<Container fixed maxWidth="md">
				<MainLoadingIndicator loading={loading || !contentRefreshed} />
				{!loading && contentRefreshed && flaggedEntity && (
					<Paper>
						<FormHeaderView
							title={'Gemeldete Gruppe'}
							actionMenu={<FlaggedDetailsActionsMenuContainer flagStatus={status} />}
						/>
						<FlaggedGroupView flagSummary={flagSummary} statusRecords={statusRecords} flaggedEntity={flaggedEntity} />
						<FlaggedContentDetailsFooterView />
					</Paper>
				)}
			</Container>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		loading: state.flaggedContent.detail.loading,
		flaggedEntity: state.flaggedContent.detail.group,
		status: state.flaggedContent.detail.group?.status,
		statusRecords: state.flaggedContent.detail.statusRecords,
		flagSummary: state.flaggedContent.detail.flagSummary
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<FlaggedContentActionTypes>) => {
	return {
		getFlaggedGroup: (flagId: string): Promise<void> => dispatch(getGroupFlag(flagId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FlaggedGroupContainer);
