import React, { Component } from 'react';
import MapIcon from '@mui/icons-material/Map';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import DialogActionView from '../../../../shared/views/ActionMenu/DialogAction.view';
import { ClientGroupExtendedDetail } from '@DigitaleDoerfer/digitale-doerfer-api';
import GroupGeoAreasView from '../../../../shared/views/Group/GroupGeoAreas.view';
import GroupGeoAreasAutocompleteView from '../../../../shared/views/Group/GroupGeoAreasAutocomplete.view';
import { geoAreaService } from '../../../../ServiceFactory';
import { AnyGeoArea } from '../../../../shared/utils/AnyGeoArea';
import { GroupStateChangeHandlers } from '../../GroupStateChangeHandlers';
import { GeoAreaElement } from '../../../../shared/utils/GeoAreaElements';

interface Props extends ActionMenuItemProps {
	group: ClientGroupExtendedDetail;
	geoAreas: AnyGeoArea[];
	handleSubmit: (groupId: string, newGeoAreaIds: string[]) => void;
}

interface State {
	manualSearchedGeoAreas: GeoAreaElement[];
	isChangePresent: boolean;
}

class ChangeGroupGeoAreasView extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			manualSearchedGeoAreas: [],
			isChangePresent: false
		};
		this.handleOpenDialog = this.handleOpenDialog.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.isSubmitDisabled = this.isSubmitDisabled.bind(this);
		this.handleManualSearchedGeoAreasChange = this.handleManualSearchedGeoAreasChange.bind(this);
	}

	initDialogData(): void {
		const { group, geoAreas } = this.props;
		this.setState({
			manualSearchedGeoAreas: geoAreaService().makeGeoAreaElements(group, geoAreas),
			isChangePresent: false
		});
	}

	handleOpenDialog(): void {
		this.initDialogData();
	}

	handleSubmit(): void {
		const { manualSearchedGeoAreas } = this.state;
		const { handleSubmit, group } = this.props;
		const { id } = group;
		let geoAreasIds: string[] = [];
		manualSearchedGeoAreas.forEach(geoAreaElement => {
			const allGeoAreaChildrenSelected = geoAreaElement.geoAreaChildren.every(geoArea => geoArea.manualSelected);
			if (allGeoAreaChildrenSelected) {
				geoAreaElement?.geoAreaParent?.id && geoAreasIds.push(geoAreaElement.geoAreaParent.id);
			} else {
				geoAreaElement.geoAreaChildren.forEach(geoAreaChild => {
					if (geoAreaChild.manualSelected) {
						geoAreasIds.push(geoAreaChild.geoArea.id);
					}
				});
			}
		});

		// Filter out those repeated geoAreas ids.
		geoAreasIds = geoAreasIds.filter((geoAreaId, index) => geoAreasIds.indexOf(geoAreaId) === index);

		handleSubmit(id, geoAreasIds);
	}

	isSubmitDisabled(): boolean {
		const { isChangePresent, manualSearchedGeoAreas } = this.state;
		return !isChangePresent || manualSearchedGeoAreas.length === 0;
	}

	handleManualSearchedGeoAreasChange(newManualSearchedGeoAreas: GeoAreaElement[]): void {
		this.setState({ manualSearchedGeoAreas: newManualSearchedGeoAreas, isChangePresent: true });
	}

	render(): JSX.Element {
		const { renderMenuItem, geoAreas } = this.props;
		const { manualSearchedGeoAreas } = this.state;
		const groupStateChangeHandlers: GroupStateChangeHandlers = {
			handleManualSearchedGeoAreasChange: this.handleManualSearchedGeoAreasChange
		};
		const flattenedGeoAreas = geoAreaService().flatten(geoAreas);
		return (
			<DialogActionView
				itemProps={{
					renderMenuItem,
					icon: <MapIcon color="primary" />,
					text: 'Heimatgemeinden bearbeiten'
				}}
				dialogProps={{
					danger: false,
					titleText: 'Heimatgemeinden bearbeiten',
					saveButtonText: 'speichern',
					handleOpen: this.handleOpenDialog,
					handleSave: this.handleSubmit,
					handleIsSaveDisabled: this.isSubmitDisabled,
					wide: true,
					ignoreKeyDownKeys: ['Enter']
				}}
			>
				<GroupGeoAreasView
					manualSearchedGeoAreas={manualSearchedGeoAreas}
					groupStateChangeHandlers={groupStateChangeHandlers}
					useAdditionalGeoAreas={true}
					readOnly={false}
					hideHomeAreaOfAdministratorsTypography={true}
				>
					<GroupGeoAreasAutocompleteView
						geoAreas={geoAreas as AnyGeoArea[]}
						flattenedGeoAreas={flattenedGeoAreas}
						manualSearchedGeoAreas={manualSearchedGeoAreas}
						groupStateChangeHandlers={groupStateChangeHandlers}
					/>
				</GroupGeoAreasView>
			</DialogActionView>
		);
	}
}

export default ChangeGroupGeoAreasView;
