import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../store/store';
import { getUsersByRole, getUsersByText } from '../store/User.actions';
import UserListView from './UserList.view';
import { RouteComponentProps } from 'react-router-dom';
import { ClientPersonExtended, ClientRoleInformation } from '@DigitaleDoerfer/digitale-doerfer-api';
import { BaseError } from '../../../shared/errors/Errors';
import { UserActionTypes } from '../store/UserActionTypes';
import { RoleKey, RoleManagementPermission } from '../../../shared/services/Role.service';
import { getUsersAuthDetailsUrl, getUsersDetailsUrl, USERS_CREATE_URL } from '../UsersRouting.container';
import { ListSearchParams } from '../store/User.state';
import { roleService } from '../../../ServiceFactory';

interface Props extends RouteComponentProps {
	users: ClientPersonExtended[];
	loading: boolean;
	error: BaseError | null;
	searchParams: ListSearchParams;
	roleManagementPermission: RoleManagementPermission;
	assignableRoles: ClientRoleInformation[];
	allAvailableRoles: ClientRoleInformation[];
	getUsersByText: (params: ListSearchParams) => void;
	getUsersByRole: (params: ListSearchParams) => void;
}

class UsersContainer extends Component<Props> {
	createUserHandler = (): void => {
		this.props.history.push(USERS_CREATE_URL);
	};

	viewUserDetailsHandler = (userId: string): void => {
		this.props.history.push(getUsersDetailsUrl(userId));
	};

	viewAuthUserDetailsHandler = (email: string): void => {
		this.props.history.push(getUsersAuthDetailsUrl(email));
	};

	openNewUserDetailsTab = (userId: string): void => {
		window.open(getUsersDetailsUrl(userId));
	};

	shouldRenderCreateUserButton(): boolean {
		const { assignableRoles } = this.props;
		const validRoles: RoleKey[] = [RoleKey.SUPER_ADMIN, RoleKey.USER_ADMIN, RoleKey.GLOBAL_USER_ADMIN];
		return roleService().hasClientRoleInformationSomeValidRole(assignableRoles, validRoles);
	}

	render(): JSX.Element {
		const {
			users,
			loading,
			searchParams,
			allAvailableRoles,
			roleManagementPermission,
			getUsersByText,
			getUsersByRole
		} = this.props;
		return (
			<UserListView
				users={users}
				loading={loading}
				searchParams={searchParams}
				roleManagementPermission={roleManagementPermission}
				getUsersByText={getUsersByText}
				getUsersByRole={getUsersByRole}
				allAvailableRoles={allAvailableRoles}
				shouldRenderCreateUserButton={this.shouldRenderCreateUserButton()}
				createUserHandler={this.createUserHandler}
				viewUserDetailsHandler={this.viewUserDetailsHandler}
				viewAuthUserDetailsHandler={this.viewAuthUserDetailsHandler}
				openNewUserDetailsTab={this.openNewUserDetailsTab}
			/>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: PlatformAdminUIState) => {
	return {
		users: state.user.list.users,
		loading: state.user.list.loading,
		error: state.user.list.error,
		searchParams: state.user.list.searchParams,
		assignableRoles: state.global.assignableRoles,
		allAvailableRoles: state.global.allAvailableRoles,
		roleManagementPermission: state.global.roleManagementPermission
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: PlatformAdminUIThunkType<UserActionTypes>) => {
	return {
		getUsersByText: (params: ListSearchParams): Promise<void> => dispatch(getUsersByText(params)),
		getUsersByRole: (params: ListSearchParams): Promise<void> => dispatch(getUsersByRole(params))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer);
