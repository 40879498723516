import React, { Component } from 'react';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import LockIcon from '@mui/icons-material/Lock';
import { ActionMenuItemProps } from '../../../../shared/views/ActionMenu/ActionMenu';
import DialogActionView from '../../../../shared/views/ActionMenu/DialogAction.view';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { ClientGroupChangeVisibilityAndAccessibilityByAdminRequestGroupAccessibilityEnum } from '@DigitaleDoerfer/digitale-doerfer-api';
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => {
	return createStyles({
		checkField: {
			overflowY: 'visible',
			height: 100
		},
		emphasized: {
			fontWeight: 'bold'
		}
	});
};

interface Props extends ActionMenuItemProps, WithStyles<typeof styles> {
	fromPropsGroupAccessibility: ClientGroupChangeVisibilityAndAccessibilityByAdminRequestGroupAccessibilityEnum;

	handleAccessibilityChange: (
		groupAccessibility: ClientGroupChangeVisibilityAndAccessibilityByAdminRequestGroupAccessibilityEnum
	) => void;
}

interface State {
	stateGroupAccessibility: ClientGroupChangeVisibilityAndAccessibilityByAdminRequestGroupAccessibilityEnum;
}

const initialState = {
	stateGroupAccessibility: ClientGroupChangeVisibilityAndAccessibilityByAdminRequestGroupAccessibilityEnum.Public
};

class ChangeGroupAccessibilityActionView extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = initialState;
		this.handleOpenDialog = this.handleOpenDialog.bind(this);
		this.handleRadioButtonChange = this.handleRadioButtonChange.bind(this);
		this.isSubmitDisabled = this.isSubmitDisabled.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleOpenDialog(): void {
		const { fromPropsGroupAccessibility } = this.props;
		this.setState({
			stateGroupAccessibility: fromPropsGroupAccessibility
		});
	}

	handleSubmit(): void {
		const { stateGroupAccessibility } = this.state;
		this.props.handleAccessibilityChange(stateGroupAccessibility);
	}

	handleRadioButtonChange(event: React.ChangeEvent<{ name?: string; value: unknown }>): void {
		const value = `${event.target.value || ''}`;
		const { Public, ApprovalRequired } =
			ClientGroupChangeVisibilityAndAccessibilityByAdminRequestGroupAccessibilityEnum;

		if (value === Public || value === ApprovalRequired) {
			this.setState(state => ({ ...state, stateGroupAccessibility: value }));
		}
	}

	isSubmitDisabled(): boolean {
		const { stateGroupAccessibility } = this.state;
		const { fromPropsGroupAccessibility } = this.props;
		if (stateGroupAccessibility === fromPropsGroupAccessibility) {
			return true;
		} else {
			return false;
		}
	}

	render(): JSX.Element {
		const { renderMenuItem } = this.props;
		return (
			<DialogActionView
				itemProps={{
					renderMenuItem,
					icon: <LockIcon color="primary" />,
					text: 'Zugänglichkeit bearbeiten'
				}}
				dialogProps={{
					danger: false,
					titleText: 'Zugänglichkeit  bearbeiten',
					saveButtonText: 'speichern',
					handleOpen: this.handleOpenDialog,
					handleSave: this.handleSubmit,
					handleIsSaveDisabled: this.isSubmitDisabled
				}}
			>
				<FormControl variant="standard">
					<RadioGroup id="groupAccessibilityType" name="groupAccessibilityType" row>
						<FormControlLabel
							value={ClientGroupChangeVisibilityAndAccessibilityByAdminRequestGroupAccessibilityEnum.Public}
							control={<Radio onChange={this.handleRadioButtonChange} />}
							checked={
								this.state.stateGroupAccessibility ===
								ClientGroupChangeVisibilityAndAccessibilityByAdminRequestGroupAccessibilityEnum.Public
							}
							label="Öffentlich"
						/>
						<FormControlLabel
							value={ClientGroupChangeVisibilityAndAccessibilityByAdminRequestGroupAccessibilityEnum.ApprovalRequired}
							checked={
								this.state.stateGroupAccessibility ===
								ClientGroupChangeVisibilityAndAccessibilityByAdminRequestGroupAccessibilityEnum.ApprovalRequired
							}
							control={<Radio onChange={this.handleRadioButtonChange} />}
							label="Geschlossen"
						/>
					</RadioGroup>
				</FormControl>
			</DialogActionView>
		);
	}
}

export default withStyles(styles)(ChangeGroupAccessibilityActionView);
