import { ThunkAction } from 'redux-thunk';
import history from '../../../history';
import {
	ACCEPT_FLAG_AND_DELETE_CONTENT_FAILURE,
	ACCEPT_FLAG_AND_DELETE_CONTENT_REQUEST,
	ACCEPT_FLAG_AND_DELETE_CONTENT_SUCCESS,
	AcceptFlagAndDeleteContentFailureAction,
	AcceptFlagAndDeleteContentRequestAction,
	AcceptFlagAndDeleteContentSuccessAction,
	CHANGE_FLAG_STATUS_FAILURE,
	CHANGE_FLAG_STATUS_REQUEST,
	CHANGE_FLAG_STATUS_SUCCESS,
	ChangeFlagStatusFailureAction,
	ChangeFlagStatusRequestAction,
	ChangeFlagStatusSuccessAction,
	FlaggedContentActionTypes,
	GET_CHAT_FLAG_FAILURE,
	GET_CHAT_FLAG_REQUEST,
	GET_CHAT_FLAG_SUCCESS,
	GET_COMMENT_FLAG_FAILURE,
	GET_COMMENT_FLAG_REQUEST,
	GET_COMMENT_FLAG_SUCCESS,
	GET_FLAGGED_CONTENT_FAILURE,
	GET_FLAGGED_CONTENT_REQUEST,
	GET_FLAGGED_CONTENT_SUCCESS,
	GET_FLAGGED_CONTENTS_FAILURE,
	GET_FLAGGED_CONTENTS_REQUEST,
	GET_FLAGGED_CONTENTS_SUCCESS,
	GET_GROUP_FLAG_FAILURE,
	GET_GROUP_FLAG_REQUEST,
	GET_GROUP_FLAG_SUCCESS,
	GET_POST_FLAG_FAILURE,
	GET_POST_FLAG_REQUEST,
	GET_POST_FLAG_SUCCESS,
	GetChatFlagFailureAction,
	GetChatFlagRequestAction,
	GetChatFlagSuccessAction,
	GetCommentFlagFailureAction,
	GetCommentFlagRequestAction,
	GetCommentFlagSuccessAction,
	GetFlaggedContentFailureAction,
	GetFlaggedContentRequestAction,
	GetFlaggedContentsFailureAction,
	GetFlaggedContentsRequestAction,
	GetFlaggedContentsSuccessAction,
	GetFlaggedContentSuccessAction,
	GetGroupFlagFailureAction,
	GetGroupFlagRequestAction,
	GetGroupFlagSuccessAction,
	GetPostFlagFailureAction,
	GetPostFlagRequestAction,
	GetPostFlagSuccessAction
} from './FlaggedContentActionTypes';
import { PlatformAdminUIState, PlatformAdminUIThunkType } from '../../../store/store';
import {
	ClientUserGeneratedContentFlag,
	ClientUserGeneratedContentFlagChat,
	ClientUserGeneratedContentFlagComment,
	ClientUserGeneratedContentFlagDeleteFlagEntityByAdminResponse,
	ClientUserGeneratedContentFlagDetail,
	ClientUserGeneratedContentFlagGroup,
	ClientUserGeneratedContentFlagPost,
	ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum,
	ClientUserGeneratedContentFlagStatusChangeResponse,
	ClientUserGeneratedContentFlagStatusRecord,
	ListFlagsSortColumnEnum,
	ListFlagsSortDirectionEnum
} from '@DigitaleDoerfer/digitale-doerfer-api';
import {
	communicationAdminuiFlagApi,
	flaggedContentService,
	grapevineAdminuiFlagApi,
	sharedAdminFlagUi,
	sharedAdminuiFlagEventsApi
} from '../../../ServiceFactory';
import { BaseError } from '../../../shared/errors/Errors';
import { wrapIntoErrorObject } from '../../../shared/errors/ErrorWrapper';
import { IncludedStatusMapping } from '../services/FlaggedContent.service';
import {
	showSnackbar,
	showSnackbarError,
	SnackbarType
} from '../../../shared/views/SnackbarNotification/store/SnackbarNotification.actions';
import { initialFlaggedContentState, ListSearchParams } from './FlaggedContent.state';
import { TableColumnSortingDirection, TableSorting } from '../../../shared/views/Table/TableSorting';
import { Pagination } from '../../../shared/services/InMemoryTable.service';
import { FlagSummary } from '../flagged-content-details/shared/FlaggedContentDetailsCommons';
import { FLAGGED_CONTENTS_URL } from '../FlaggedContentRouting.container';

export type ThunkResult<R> = ThunkAction<R, PlatformAdminUIState, undefined, FlaggedContentActionTypes>;

export const getFlaggedContentsRequest = (searchParams: ListSearchParams): GetFlaggedContentsRequestAction => {
	return {
		type: GET_FLAGGED_CONTENTS_REQUEST,
		searchParams
	};
};

export const getFlaggedContentsSuccess = (
	flaggedContents: ClientUserGeneratedContentFlag[],
	includedStatus: IncludedStatusMapping,
	sorting: TableSorting,
	pagination: Pagination
): GetFlaggedContentsSuccessAction => {
	return {
		type: GET_FLAGGED_CONTENTS_SUCCESS,
		flaggedContents,
		includedStatus,
		sorting,
		pagination
	};
};

export const getFlaggedContentsFailure = (error: BaseError): GetFlaggedContentsFailureAction => {
	return {
		type: GET_FLAGGED_CONTENTS_FAILURE,
		error
	};
};

/**
 * Returns the correct SortColumnEnum
 * @param columnId Not processed column id
 */
const toSortColumnListEnum = (columnId: string): ListFlagsSortColumnEnum | undefined => {
	switch (columnId) {
		case 'flaggedContent': {
			return ListFlagsSortColumnEnum.EntityType;
		}
		case 'flagCreator': {
			return ListFlagsSortColumnEnum.FlagCreatorLastName;
		}
		case 'reason': {
			return undefined;
		}
		case 'tenant': {
			return ListFlagsSortColumnEnum.TenantName;
		}
		case 'status': {
			return ListFlagsSortColumnEnum.Status;
		}
		case 'lastStatusUpdate': {
			return ListFlagsSortColumnEnum.LastStatusUpdate;
		}
	}

	return undefined;
};

const toSortColumnListDirection = (direction: TableColumnSortingDirection): ListFlagsSortDirectionEnum => {
	return direction === TableColumnSortingDirection.ASC
		? ListFlagsSortDirectionEnum.Asc
		: ListFlagsSortDirectionEnum.Desc;
};

export const getFlaggedContents = (searchParams: ListSearchParams): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<FlaggedContentActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		const { includedStatus, sorting, pagination } = searchParams;
		const { page, rowsPerPage } = pagination;

		try {
			dispatch(getFlaggedContentsRequest(searchParams));

			const response = await sharedAdminFlagUi().listFlags({
				page,
				count: rowsPerPage,
				sortColumn: toSortColumnListEnum(sorting.columnId),
				sortDirection: toSortColumnListDirection(sorting.direction),
				includedStatus: includedStatus.requestStatuses
			});

			// Prepate data to dispatch getFlaggedContentsSuccess
			const flaggedContents = response.content ? response.content : [];
			const pagination: Pagination = {
				page: response?.page?.number ?? 0,
				rowsPerPage: response.page?.size ?? initialFlaggedContentState.list.searchParams.pagination.rowsPerPage,
				total: response.page?.totalElements ?? 0
			};
			dispatch(getFlaggedContentsSuccess(flaggedContents, includedStatus, sorting, pagination));
		} catch (error) {
			dispatch(getFlaggedContentsFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const getFlaggedContentRequest = (flaggedContentId: string): GetFlaggedContentRequestAction => {
	return {
		type: GET_FLAGGED_CONTENT_REQUEST,
		flaggedContentId
	};
};

export const getFlaggedContentSuccess = (
	flaggedContent: ClientUserGeneratedContentFlagDetail,
	statusRecords: ClientUserGeneratedContentFlagStatusRecord[],
	flagSummary: FlagSummary
): GetFlaggedContentSuccessAction => {
	return {
		type: GET_FLAGGED_CONTENT_SUCCESS,
		flaggedContent,
		statusRecords,
		flagSummary
	};
};

export const getFlaggedContentFailure = (error: BaseError): GetFlaggedContentFailureAction => {
	return {
		type: GET_FLAGGED_CONTENT_FAILURE,
		error
	};
};

export const getFlaggedContent = (flaggedContentId: string): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<FlaggedContentActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(getFlaggedContentRequest(flaggedContentId));

			const flaggedContent: ClientUserGeneratedContentFlagDetail = await sharedAdminFlagUi().getFlagById({
				flagId: flaggedContentId
			});
			const statusRecords = flaggedContentService().getStatusRecordsFromFlagSortedByCreatedAsc(flaggedContent);
			const flagSummary = flaggedContentService().getFlagSummaryInformation(flaggedContent);

			dispatch(getFlaggedContentSuccess(flaggedContent, statusRecords, flagSummary));
		} catch (error) {
			history.push(FLAGGED_CONTENTS_URL);
			dispatch(showSnackbarError(error));
			dispatch(getFlaggedContentFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const getChatFlagRequest = (chatFlagId: string): GetChatFlagRequestAction => {
	return {
		type: GET_CHAT_FLAG_REQUEST,
		chatFlagId
	};
};

export const getChatFlagSuccess = (
	chatFlagContent: ClientUserGeneratedContentFlagChat,
	statusRecords: ClientUserGeneratedContentFlagStatusRecord[],
	flagSummary: FlagSummary
): GetChatFlagSuccessAction => {
	return {
		type: GET_CHAT_FLAG_SUCCESS,
		chatFlagContent,
		statusRecords,
		flagSummary
	};
};

export const getChatFlagFailure = (error: BaseError): GetChatFlagFailureAction => {
	return {
		type: GET_CHAT_FLAG_FAILURE,
		error
	};
};

export const getChatFlag = (flagId: string): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<FlaggedContentActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(getChatFlagRequest(flagId));

			const flaggedChatContent: ClientUserGeneratedContentFlagChat =
				await communicationAdminuiFlagApi().getChatFlagById({ flagId });
			const statusRecords = flaggedContentService().getStatusRecordsFromFlagSortedByCreatedAsc(flaggedChatContent);
			const flagSummary = flaggedContentService().getFlagSummaryInformation(flaggedChatContent);

			dispatch(getChatFlagSuccess(flaggedChatContent, statusRecords, flagSummary));
		} catch (error) {
			history.push(FLAGGED_CONTENTS_URL);
			dispatch(showSnackbarError(error));
			dispatch(getChatFlagFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const getCommentFlagRequest = (commentFlagId: string): GetCommentFlagRequestAction => {
	return {
		type: GET_COMMENT_FLAG_REQUEST,
		commentFlagId
	};
};

export const getCommentFlagSuccess = (
	commentFlagContent: ClientUserGeneratedContentFlagComment,
	statusRecords: ClientUserGeneratedContentFlagStatusRecord[],
	flagSummary: FlagSummary
): GetCommentFlagSuccessAction => {
	return {
		type: GET_COMMENT_FLAG_SUCCESS,
		commentFlagContent,
		statusRecords,
		flagSummary
	};
};

export const getCommentFlagFailure = (error: BaseError): GetCommentFlagFailureAction => {
	return {
		type: GET_COMMENT_FLAG_FAILURE,
		error
	};
};

export const getCommentFlag = (flagId: string): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<FlaggedContentActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(getCommentFlagRequest(flagId));

			const flaggedCommentContent: ClientUserGeneratedContentFlagComment =
				await grapevineAdminuiFlagApi().getCommentFlagById({ flagId });
			const statusRecords = flaggedContentService().getStatusRecordsFromFlagSortedByCreatedAsc(flaggedCommentContent);
			const flagSummary: FlagSummary = {
				...flaggedContentService().getFlagSummaryInformation(flaggedCommentContent),
				text: flaggedCommentContent.flagComment?.text ?? initialFlaggedContentState.detail.flagSummary.text,
				highlightedCommentId: flaggedCommentContent.flagComment?.id
			};

			dispatch(getCommentFlagSuccess(flaggedCommentContent, statusRecords, flagSummary));
		} catch (error) {
			history.push(FLAGGED_CONTENTS_URL);
			dispatch(showSnackbarError(error));
			dispatch(getCommentFlagFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const getPostFlagRequest = (postFlagId: string): GetPostFlagRequestAction => {
	return {
		type: GET_POST_FLAG_REQUEST,
		postFlagId
	};
};

export const getPostFlagSuccess = (
	postFlagContent: ClientUserGeneratedContentFlagPost,
	statusRecords: ClientUserGeneratedContentFlagStatusRecord[],
	flagSummary: FlagSummary
): GetPostFlagSuccessAction => {
	return {
		type: GET_POST_FLAG_SUCCESS,
		postFlagContent,
		statusRecords,
		flagSummary
	};
};

export const getPostFlagFailure = (error: BaseError): GetPostFlagFailureAction => {
	return {
		type: GET_POST_FLAG_FAILURE,
		error
	};
};

export const getPostFlag = (flagId: string): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<FlaggedContentActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(getPostFlagRequest(flagId));

			const flaggedPostContent: ClientUserGeneratedContentFlagPost = await grapevineAdminuiFlagApi().getPostFlagById({
				flagId
			});
			const statusRecords = flaggedContentService().getStatusRecordsFromFlagSortedByCreatedAsc(flaggedPostContent);
			const flagSummary: FlagSummary = {
				...flaggedContentService().getFlagSummaryInformation(flaggedPostContent),
				text:
					flaggedContentService().getTextFromPost(flaggedPostContent) ??
					initialFlaggedContentState.detail.flagSummary.text
			};

			dispatch(getPostFlagSuccess(flaggedPostContent, statusRecords, flagSummary));
		} catch (error) {
			history.push(FLAGGED_CONTENTS_URL);
			dispatch(showSnackbarError(error));
			dispatch(getPostFlagFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const getGroupFlagRequest = (flaggedContentId: string): GetGroupFlagRequestAction => {
	return {
		type: GET_GROUP_FLAG_REQUEST,
		flaggedContentId
	};
};

export const getGroupFlagSuccess = (
	flaggedContent: ClientUserGeneratedContentFlagGroup,
	statusRecords: ClientUserGeneratedContentFlagStatusRecord[],
	flagSummary: FlagSummary
): GetGroupFlagSuccessAction => {
	return {
		type: GET_GROUP_FLAG_SUCCESS,
		flaggedContent,
		statusRecords,
		flagSummary
	};
};

export const getGroupFlagFailure = (error: BaseError): GetGroupFlagFailureAction => {
	return {
		type: GET_GROUP_FLAG_FAILURE,
		error
	};
};

export const getGroupFlag = (flaggedContentId: string): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<FlaggedContentActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			dispatch(getGroupFlagRequest(flaggedContentId));

			const flaggedGroupContent: ClientUserGeneratedContentFlagGroup = await grapevineAdminuiFlagApi().getGroupFlagById(
				{ flagId: flaggedContentId }
			);
			const statusRecords = flaggedContentService().getStatusRecordsFromFlagSortedByCreatedAsc(flaggedGroupContent);
			const flagSummary = flaggedContentService().getFlagSummaryInformation(flaggedGroupContent);

			dispatch(getGroupFlagSuccess(flaggedGroupContent, statusRecords, flagSummary));
		} catch (error) {
			history.push(FLAGGED_CONTENTS_URL);
			dispatch(showSnackbarError(error));
			dispatch(getGroupFlagFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const acceptFlagAndDeleteContentRequest = (
	flagId: string,
	comment: string
): AcceptFlagAndDeleteContentRequestAction => {
	return {
		type: ACCEPT_FLAG_AND_DELETE_CONTENT_REQUEST,
		flagId,
		comment
	};
};

export const acceptFlagAndDeleteContentSuccess = (
	flagId: string,
	clientUserGeneratedContentFlagDetail: ClientUserGeneratedContentFlagDetail,
	flagSummary: FlagSummary
): AcceptFlagAndDeleteContentSuccessAction => {
	return {
		type: ACCEPT_FLAG_AND_DELETE_CONTENT_SUCCESS,
		flagId,
		clientUserGeneratedContentFlagDetail,
		flagSummary
	};
};

export const acceptFlagAndDeleteContentFailure = (error: BaseError): AcceptFlagAndDeleteContentFailureAction => {
	return {
		type: ACCEPT_FLAG_AND_DELETE_CONTENT_FAILURE,
		error
	};
};

export const acceptFlagAndDeleteContent = (flagId: string, comment: string): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<FlaggedContentActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			const { flaggedContent } = getState();

			dispatch(acceptFlagAndDeleteContentRequest(flagId, comment));

			const clientUserGeneratedContentFlagDeleteFlagEntityByAdminResponse: ClientUserGeneratedContentFlagDeleteFlagEntityByAdminResponse =
				await sharedAdminuiFlagEventsApi().onDeleteFlagEntityRequest({
					clientUserGeneratedContentFlagDeleteFlagEntityByAdminRequest: { flagId, comment }
				});
			const clientUserGeneratedContentFlagDetail =
				clientUserGeneratedContentFlagDeleteFlagEntityByAdminResponse.changedFlag;
			const flagSummary: FlagSummary = {
				...flaggedContentService().getFlagSummaryInformation(clientUserGeneratedContentFlagDetail),
				text: flaggedContent?.detail?.flagSummary?.text // Since we dont have the text and this is not changed, we get from the state
			};
			const statusRecords = flaggedContentService().getStatusRecordsFromFlagSortedByCreatedAsc(
				clientUserGeneratedContentFlagDetail
			);
			clientUserGeneratedContentFlagDetail.statusRecords = statusRecords;
			dispatch(acceptFlagAndDeleteContentSuccess(flagId, clientUserGeneratedContentFlagDetail, flagSummary));
			// go back to table since this flag is done
			history.push(FLAGGED_CONTENTS_URL);
			dispatch(
				showSnackbar({
					type: SnackbarType.SNACKBAR_SUCCESS,
					message: 'Fall abgeschlossen. Inhalt wurde entfernt und Status auf "Akzeptiert" gesetzt.'
				})
			);
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(acceptFlagAndDeleteContentFailure(wrapIntoErrorObject(error)));
		}
	};
};

export const changeFlagStatusRequest = (
	flagId: string,
	newStatus: ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum,
	comment: string
): ChangeFlagStatusRequestAction => {
	return {
		type: CHANGE_FLAG_STATUS_REQUEST,
		flagId,
		newStatus,
		comment
	};
};

export const changeFlagStatusSuccess = (
	flagId: string,
	clientUserGeneratedContentFlagDetail: ClientUserGeneratedContentFlagDetail,
	flagSummary: FlagSummary
): ChangeFlagStatusSuccessAction => {
	return {
		type: CHANGE_FLAG_STATUS_SUCCESS,
		flagId,
		clientUserGeneratedContentFlagDetail,
		flagSummary
	};
};

export const changeFlagStatusFailure = (error: BaseError): ChangeFlagStatusFailureAction => {
	return {
		type: CHANGE_FLAG_STATUS_FAILURE,
		error
	};
};

export const changeFlagStatus = (
	flagId: string,
	newStatus: ClientUserGeneratedContentFlagStatusChangeRequestNewStatusEnum,
	comment: string,
	snackbarMessage?: string,
	returnToTable?: boolean
): ThunkResult<Promise<void>> => {
	return async (
		dispatch: PlatformAdminUIThunkType<FlaggedContentActionTypes>,
		getState: () => PlatformAdminUIState
	): Promise<void> => {
		try {
			const { flaggedContent } = getState();

			dispatch(changeFlagStatusRequest(flagId, newStatus, comment));

			const clientUserGeneratedContentFlagStatusChangeResponse: ClientUserGeneratedContentFlagStatusChangeResponse =
				await sharedAdminuiFlagEventsApi().onFlagStatusChangeRequest({
					clientUserGeneratedContentFlagStatusChangeRequest: {
						flagId,
						comment,
						newStatus
					}
				});
			const clientUserGeneratedContentFlagDetail = clientUserGeneratedContentFlagStatusChangeResponse.changedFlag;
			const flagSummary: FlagSummary = {
				...flaggedContentService().getFlagSummaryInformation(clientUserGeneratedContentFlagDetail),
				text: flaggedContent?.detail?.flagSummary?.text // Since we dont have the text and this is not changed, we get from the state
			};
			const statusRecords = flaggedContentService().getStatusRecordsFromFlagSortedByCreatedAsc(
				clientUserGeneratedContentFlagDetail
			);
			clientUserGeneratedContentFlagDetail.statusRecords = statusRecords;
			if (returnToTable) {
				history.push(FLAGGED_CONTENTS_URL);
			}
			if (snackbarMessage) {
				dispatch(
					showSnackbar({
						type: SnackbarType.SNACKBAR_SUCCESS,
						message: snackbarMessage
					})
				);
			}
			dispatch(changeFlagStatusSuccess(flagId, clientUserGeneratedContentFlagDetail, flagSummary));
		} catch (error) {
			dispatch(showSnackbarError(error));
			dispatch(changeFlagStatusFailure(wrapIntoErrorObject(error)));
		}
	};
};
